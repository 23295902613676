import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import isEmpty from 'lodash/isEmpty';
import { fetchProducts, deleteProduct } from 'state/ducks/residential/products';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiTable from 'components/shared/MuiTable';

const ProductsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projId } = useParams();

  const products = useSelector(state => state.rd.products);

  useEffect(() => {
    dispatch(fetchProducts(projId));
  }, [projId, dispatch]);

  const handleDelete = values => {
    if (window.confirm('Are you sure you want to delete the product?'))
      dispatch(deleteProduct({ id: values.id }));
  };

  const showEditForm = rowData =>
    history.push(`${history.location.pathname}/edit/${rowData.id}`);

  const showAddForm = () => history.push(`${history.location.pathname}/create`);

  const handleAddToModel = rowData =>
    history.push(`${history.location.pathname}/add-to-model/${rowData.id}`);

  if (isEmpty(products)) {
    return null;
  }

  const tableData = products.allIds.map(id => {
    const productData = products.byId[id];
    return {
      name: productData.name,
      craft: productData.craft,
      id: productData.id,
      exclgrp: productData.exclgrp,
      prodtype: productData.prodtype,
      project: productData.project.id,
      category: productData.category,
    };
  });

  return (
    <Box p={2}>
      {products.isLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!products.isLoading && (
        <MuiTable
          title="Products"
          rowName="Product"
          columns={[
            {
              title: '',
              field: '',
              // eslint-disable-next-line
              render: rowData => {
                return (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => handleAddToModel(rowData)}
                  >
                    Add to Model
                  </Button>
                );
              },
            },
            {
              title: 'Name',
              field: 'name',
            },
            {
              title: 'Craft name',
              field: 'craft.name',
            },
            {
              title: 'Product Type',
              field: 'prodtype',
            },
            {
              title: 'Exclusivity Group',
              field: 'exclgrp',
            },
            {
              title: 'Room or Model Wide',
              field: 'category',
            },
          ]}
          data={tableData}
          onEdit={showEditForm}
          onDelete={handleDelete}
          onAdd={showAddForm}
        />
      )}
    </Box>
  );
};

ProductsTable.propTypes = {};

export default ProductsTable;
