import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageContainer from 'views/layouts/PageContainer';
import axios from 'axios';
import PropTypes from 'prop-types';
import useInterval from 'hooks/useInterval';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from 'state/ducks/residential/products';
import { Alert } from '@material-ui/lab';

const DisplayProducts = ({ toggleCheckProduct }) => {
  const dispatch = useDispatch();
  const { projId } = useParams();
  const products = useSelector(state => state.rd.products);
  useEffect(() => {
    if (products.allIds.length === 0)
      dispatch(
        fetchProducts(projId, {
          exclude_subprod: true,
          limit: 1000,
        })
      );
  }, [products.allIds, projId]);

  if (products.allIds.length === 0) {
    return <CircularProgress style={{ marginLeft: '50%' }} />;
  }
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <FormControlLabel
          value="all"
          onChange={() =>
            toggleCheckProduct({
              id: -1,
              type: 'all',
              name: 'All Products',
            })
          }
          control={<Checkbox color="primary" />}
          label="All Products"
          labelPlacement="end"
        />
      </Grid>
      {products.allIds.map(id => {
        const product = products.byId[id];
        return (
          <Grid item key={product.id} xs={12} md={6}>
            <FormControlLabel
              value={product.id}
              onChange={() =>
                toggleCheckProduct({
                  id: product.id,
                  type: 'single',
                  name: product.name,
                })
              }
              control={<Checkbox color="primary" />}
              label={product.name}
              labelPlacement="end"
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

DisplayProducts.propTypes = {
  toggleCheckProduct: PropTypes.func.isRequired,
};

const RenderTask = ({ handleClick, task, floor }) => {
  let secondaryText = '';
  if (task && task.started_on) {
    const d = new Date(task.started_on);
    // eslint-disable-next-line
    const date = format(d, "LLL io, uuuu 'at' h:mm aaaa");
    secondaryText = `Last updated on ${date}`;
  }

  return (
    <ListItem key={floor}>
      <ListItemText primary={`Floor ${floor}`} secondary={secondaryText} />

      <ListItemSecondaryAction>
        <Box display="flex">
          {task && task.errors && (
            <Alert severity="error" style={{ marginRight: 10, marginLeft: 5 }}>
              Error! Please try again
            </Alert>
          )}
          <Button
            onClick={() => handleClick(floor)}
            disabled={task && task.status === 'pending'}
            color="primary"
            variant="contained"
          >
            {!task || task.status === 'completed' ? (
              'Generate'
            ) : (
              <>
                <span style={{ marginRight: 10 }}>Pending</span>
                <CircularProgress
                  style={{
                    width: 20,
                    height: 20,
                  }}
                />
              </>
            )}
          </Button>
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

RenderTask.propTypes = {
  handleClick: PropTypes.func,
  task: PropTypes.object,
  floor: PropTypes.number,
};

const SupplyOrder = () => {
  const { projId } = useParams();
  const [floors, setFloors] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [hasPendingTasks, setHasPendingTasks] = useState(true);
  const [driveFolderId, setDriveFolderId] = useState('');
  const [saveIdForLater, setSaveIdForLater] = useState(false);
  const [checkedProducts, setCheckedProducts] = useState({});
  // check local storage for drive folder id
  useEffect(() => {
    const item = localStorage.getItem('google_drive_folder_id');
    if (item) {
      setDriveFolderId(item);
      setSaveIdForLater(true);
    }
  }, []);

  // handle saving drive folder id in local storage
  useEffect(() => {
    if (saveIdForLater) {
      localStorage.setItem('google_drive_folder_id', driveFolderId);
    } else {
      localStorage.removeItem('google_drive_folder_id', driveFolderId);
    }
  }, [driveFolderId, saveIdForLater]);

  useEffect(() => {
    fetchFloors(projId);
  }, [projId]);

  // Simulates polling. When tasks are "pending" (in progress) we want to periodically check if they're done.
  // see: https://blog.bitsrc.io/polling-in-react-using-the-useinterval-custom-hook-e2bcefda4197?gi=f4e2114d8252
  useInterval(async () => {
    if (floors.length !== 0 && hasPendingTasks === true) {
      fetchSupplyOrderTasks(floors);
    }
  }, 3000);

  const fetchSupplyOrderTasks = async floors => {
    try {
      let floorQuery = '';
      for (let i = 0; i < floors.length; i++) {
        floorQuery += `floor-${floors[i]},`;
      }
      const res = await axios.get(
        `/api/v2/supply-order-report?project=${projId}&tags[]=${floorQuery}`
      );
      const tmp = res.data.tasks;
      setTasks(tmp);
      let pending = false;
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].status === 'pending') {
          pending = true;
        }
      }

      if (!pending) {
        setHasPendingTasks(false);
      }
    } catch (error) {
      alert('Something went wrong');
    }
  };

  const fetchFloors = async projId => {
    try {
      const res = await axios.get(`/api/v2/floors?project_id=${projId}`);
      setFloors(res.data.floors);
    } catch (error) {
      alert('Something went wrong');
    }
  };

  const getIdFromUrl = () => {
    const url = driveFolderId.split(/\/|\?/);
    for (let i = 0; i < url.length; i++) {
      if (url[i] == 'folders') {
        return url[i + 1];
      }
    }
    return undefined;
  };

  const handleClickGenerate = async floor => {
    if (!driveFolderId) {
      alert('Please enter a google folder id to generate a report');
      return;
    }
    const id = getIdFromUrl(driveFolderId);
    if (!id) {
      alert('The URL you entered is not valid');
      return;
    }

    const numKeys = Object.keys(checkedProducts);
    if (numKeys.length === 0) {
      alert('Please select a product');
      return;
    }

    const reports = [];
    for (const key in checkedProducts) {
      const product = checkedProducts[key];
      if (product) {
        const data = {
          prefix: product.name,
          type: product.type,
        };
        if (product.id !== -1) {
          data.product_id = product.id;
        }
        reports.push(data);
        if (reports.length === 0) {
          alert('Select one or more products to generate a report');
          return;
        }
        try {
          const res = await axios.post('/api/v2/supply-order-report', {
            reports: reports,
            floor: floor.toString(),
            project: projId,
            drive_folder_id: id,
          });

          const newTask = res.data.task;
          // If we are re-generating a task then replace the old one with the new task
          let tmpTasks = tasks.filter(
            task => task.tag.title !== newTask.tag.title
          );
          tmpTasks = [...tmpTasks, newTask];
          setTasks(tmpTasks);
          setHasPendingTasks(true);
        } catch (error) {
          alert('Something went wrong');
        }
      }
    }
  };

  const toggleCheckProduct = product => {
    if (checkedProducts[product.id]) {
      const tmp = {
        ...checkedProducts,
      };
      delete tmp[product.id];
      setCheckedProducts(tmp);
    } else {
      setCheckedProducts(value => ({
        ...value,
        [product.id]: product,
      }));
    }
  };

  return (
    <div>
      <Typography variant="h4">Supply Order Reports</Typography>
      <Box style={{ marginTop: 20 }} display="flex" flexDirection="column">
        <div>
          <TextField
            id="outlined-helperText"
            label="Google Drive Folder Id"
            variant="outlined"
            size="small"
            value={driveFolderId}
            fullWidth
            onChange={e => setDriveFolderId(e.target.value)}
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={saveIdForLater}
              onChange={() => setSaveIdForLater(val => !val)}
              name="checkedB"
              color="primary"
            />
          }
          label="Remember Id"
        />
        <Divider component="span" />
      </Box>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <DisplayProducts toggleCheckProduct={toggleCheckProduct} />
      </div>

      <List>
        {floors.length ? (
          floors.map((floor, idx) => {
            const task = tasks.find(task => {
              return task.tag.title == `floor-${floor}`;
            });
            return (
              <div key={floor}>
                <RenderTask
                  task={task}
                  floor={floor}
                  idx={idx}
                  handleClick={handleClickGenerate}
                />
                {idx < floors.length - 1 && <Divider component="li" />}
              </div>
            );
          })
        ) : (
          <CircularProgress style={{ marginLeft: '50%' }} />
        )}
      </List>
    </div>
  );
};
export default function Reports() {
  return (
    <PageContainer>
      <SupplyOrder />
    </PageContainer>
  );
}
