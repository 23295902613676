import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Delete from '@material-ui/icons/Delete';
import Visibility from '@material-ui/icons/Visibility';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setNotification } from 'state/ducks/globalNotification';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
  fetchSale,
  fetchSaleAddresses,
  saveSale,
} from 'state/ducks/residential/activeSale';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function PdiDocusign() {
  /**Renders a view that allows the user to generate a docusign envelope (document), view the genrated document and perform in person signing */
  const dispatch = useDispatch();
  const { projId, saleId } = useParams();
  const [loadingPdiPdf, setLoadingPdiPdf] = useState(false);
  const [pdiUrl, setPdiUrl] = useState(null);
  const classes = useStyles();
  const sale = useSelector(state => state.rd.activeSale.sale);

  useEffect(() => {
    fetchPdiContract();
  }, []);

  useEffect(() => {
    dispatch(fetchSaleAddresses(projId, saleId));
  }, []);

  const fetchPdiContract = async () => {
    try {
      setLoadingPdiPdf(true);
      const res = await axios.get(
        `/api/v2/contract?project_id=${projId}&sale_id=${saleId}&template_type=pdi`,
        {
          project: parseInt(projId),
          sale: parseInt(saleId),
        }
      );
      setPdiUrl(res.data.url);
    } catch (error) {
      // 404 error - do nothing
    } finally {
      setLoadingPdiPdf(false);
    }
  };

  const handleViewPdi = () => {
    window.open(pdiUrl);
  };

  const handleDeletePdi = async () => {
    try {
      if (!window.confirm('Are you sure?')) {
        return;
      }
      setLoadingPdiPdf(true);
      await axios.delete(
        `/api/v2/contract?project_id=${projId}&sale_id=${saleId}&template_type=pdi`
      );
      setPdiUrl(null);
    } catch (error) {
      dispatch(setNotification('error', 'Something went wrong'));
    } finally {
      setLoadingPdiPdf(false);
    }
  };

  const handleCreatePdi = async ({ designate = '' }) => {
    try {
      if (!sale.posdate) {
        alert('Please add a date of possesion.');
        return;
      }
      setLoadingPdiPdf(true);
      const res = await axios.post('/api/v2/warr-item-contract', {
        project: parseInt(projId),
        sale: parseInt(saleId),
        designate,
      });
      setPdiUrl(res.data.url);
      dispatch(setNotification('success', 'PDI Contract Generated!'));
    } catch (error) {
      dispatch(setNotification('error', 'Something went wrong'));
    } finally {
      setLoadingPdiPdf(false);
    }
  };

  return (
    <Box padding={2}>
      <Backdrop className={classes.backdrop} open={loadingPdiPdf}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Fade in={!loadingPdiPdf}>
            <div>
              <DateOfPossession />
              <Divider style={{ marginTop: 28 }} />

              <Grid
                container
                style={{ marginTop: 20 }}
                alignContent="center"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item container xs={12}>
                  <RenderPdi
                    onDelete={handleDeletePdi}
                    onCreate={handleCreatePdi}
                    onView={handleViewPdi}
                    isAddState={pdiUrl ? false : true}
                    sale={sale}
                  />
                  <Divider />
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Grid>
      </Grid>
    </Box>
  );
}

const dateOfPosValidationSchema = Yup.object().shape({
  dateOfPossession: Yup.date('Invalid type').required('Required field'),
});

const DateOfPossession = () => {
  const { projId, saleId } = useParams();
  const dispatch = useDispatch();
  const sale = useSelector(state => state.rd.activeSale.sale);
  useEffect(() => {
    dispatch(fetchSale(projId, saleId));
  }, []);

  const handleSubmit = async values => {
    try {
      dispatch(
        saveSale({
          id: saleId,
          posdate: values.dateOfPossession,
        })
      );
    } catch (error) {
      alert('Something went wrong');
    }
  };
  return (
    <Box>
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnChange={true}
        validationSchema={dateOfPosValidationSchema}
        initialValues={{
          dateOfPossession: sale && sale.id && sale.posdate ? sale.posdate : '',
        }}
      >
        {({ dirty, handleReset }) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <Field
              component={TextField}
              name="dateOfPossession"
              label="Date of Possession *"
              type="date"
              margin="dense"
              variant="outlined"
              style={{
                width: 200,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div style={{ marginTop: 5 }}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                type="submit"
                disabled={!dirty}
              >
                Save
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                color="secondary"
                variant="contained"
                onClick={handleReset}
                disabled={!dirty}
              >
                Reset
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const designateValidationSchema = Yup.object().shape({
  designate: Yup.string(),
});
const RenderPdi = ({ onDelete, onCreate, onView, isAddState, sale }) => {
  if (isAddState && !onCreate) {
    throw Error('onCreate is undefined');
  } else if (!onDelete || !onView) {
    throw Error('onDelete or onView is undefined');
  }

  const handleSubmit = values => onCreate({ designate: values.designate });
  const AddState = () => (
    <>
      <Alert severity="info" style={{ marginTop: 10 }}>
        Please create a PDI document
      </Alert>
      <Box mt={2}>
        <Formik
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={designateValidationSchema}
          initialValues={{
            designate: '',
          }}
        >
          {() => (
            <Form style={{ display: 'flex', flexDirection: 'column' }}>
              <Field
                component={TextField}
                name="designate"
                label="Designate"
                margin="dense"
                variant="outlined"
                style={{
                  width: 200,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div>
                <Button
                  style={{ marginTop: 15 }}
                  color="primary"
                  type="submit"
                  variant="contained"
                  size="small"
                >
                  Create PDI Contract
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );

  const EditActions = () => {
    return (
      <div style={{ float: 'right' }}>
        <IconButton
          color="primary"
          onClick={onView}
          aria-label="view"
          size="small"
        >
          <Visibility />
        </IconButton>
        <IconButton
          style={{ marginLeft: 10 }}
          aria-label="delete"
          size="small"
          onClick={onDelete}
          color="secondary"
        >
          <Delete />
        </IconButton>
      </div>
    );
  };

  return (
    <Box padding={2} width="100%" component={Paper}>
      <Typography variant="h5" display="inline">
        PDI for unit {sale.unit.unitno}
      </Typography>

      {!isAddState && <EditActions />}
      {isAddState ? <AddState /> : null}
    </Box>
  );
};

RenderPdi.propTypes = {
  onView: PropTypes.func,
  sale: PropTypes.object,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  isAddState: PropTypes.bool,
};
