import * as Yup from 'yup';
import { canadaPostalRegExp, phoneRegExp } from 'components/forms/validation';
export const addressTypes = [
  {
    value: 'BUY',
    label: 'Purchaser',
  },
  {
    value: 'LAW',
    label: 'Lawyer',
  },

  {
    value: 'BRK',
    label: 'Broker',
  },
  {
    value: 'OTH',
    label: 'Other',
  },
];

export const deliveryMethods = [
  {
    value: 'EMAIL',
    label: 'Email',
  },
  {
    value: 'MAIL',
    label: 'Mail',
  },

  {
    value: 'BOTH',
    label: 'Both',
  },
];
export const initialFormValues = {
  addrtype: '',
  given: '',
  surname: '',
  name: '',
  mobilephone: '',
  busphone: '',
  homephone: '',
  email: '',
  postal: '',
  street: '',
  suite: '',
  city: '',
  delmeth: '',
  person: '',
  primary: false,
};

export const getValidationSchema = ({ isPerson }) => {
  return Yup.object().shape({
    given: isPerson ? Yup.string().required('Given is required') : null,
    surname: isPerson ? Yup.string().required('Surname is required') : null,
    name: !isPerson ? Yup.string().required('Name is required') : null,
    city: Yup.string().required('City is required'),
    delmeth: Yup.string().required('Delivery Method is required'),
    person: Yup.boolean().required('Person is required'),
    addrtype: Yup.string().required('Address Type is required'),
    homephone: Yup.string().matches(phoneRegExp, 'Home phone is not valid'),
    mobilephone: Yup.string().matches(phoneRegExp, 'Mobile phone is not valid'),
    busphone: Yup.string().matches(phoneRegExp, 'Business phone is not valid'),
    street: Yup.string().required('Street is required'),
    postal: Yup.string()
      .required('Postal is required')
      .matches(canadaPostalRegExp, 'Postal is not valid'),
    primary: Yup.boolean(),
  });
};
