import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Tooltip from '@material-ui/core/Tooltip';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { setNotification } from 'state/ducks/globalNotification';
import { useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {
  callbackUrl,
  applicationId,
  apiVersion,
  currencyCode,
} from 'libs/square/open_pos';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { fetchSale } from 'state/ducks/residential/activeSale';

const PAYMENTS = ['upgrades'];
// const DOCUSIGN = ['selections', 'upgrades'];

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    marginTop: 40,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  btn: { width: 200, display: 'block', marginBottom: 20 },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flexGrow: 1 }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Documents = () => {
  const sale = useSelector(state => state.rd.activeSale.sale);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [btnDisable, setBtnDisable] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { projId, saleId } = useParams();
  const [value, setValue] = useState(0);
  const location = useLocation();
  const [driveSettings, setDriveSettings] = useState({});

  useEffect(() => {
    dispatch(fetchSale(projId, saleId));
  }, [projId, saleId, dispatch]);

  useEffect(() => {
    const getDriveSettings = async () => {
      try {
        const res = await axios.get('/api/Drivesettings');
        setDriveSettings(res.data);
      } catch (error) {
        dispatch(setNotification('error', 'Something went wrong!'));
      }
    };
    getDriveSettings();
  }, []);

  const getTemplatesFolderURL = () => {
    if (driveSettings.TEMPLATES)
      return driveSettings.FOLDER_URL + '/' + driveSettings.TEMPLATES.FOLDER_ID;
    return '';
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const generateContract = async templateType => {
    try {
      setBtnDisable(true);

      await axios.post(
        `/api/v2/project/${projId}/sale/${saleId}/${templateType}/contract`
      );

      dispatch(
        setNotification(
          'success',
          'Contract generated. Click See Contract to see it!'
        )
      );
    } catch (error) {
      const errorCode = get(error, 'response.data.error.error_code', null);
      let message = 'Something went wrong!';
      if (errorCode === 'MISSING_PURCHASER')
        message = 'The sale is missing a purchaser';
      else if (errorCode === 'MISSING_SELECTION')
        message = 'The sale is missing required selections';
      dispatch(setNotification('error', message));
    } finally {
      setBtnDisable(false);
    }
  };

  const handleViewContract = async templateType => {
    try {
      const res = await axios.get(
        `/api/v2/project/${projId}/sale/${saleId}/${templateType}/contract`
      );
      window.open(res.data.url);
    } catch (error) {
      dispatch(setNotification('error', 'Something went wrong!'));
    }
  };

  // Square Transaction Handling
  const handlePayment = async () => {
    let transactionTotal = sale.upgrades - sale.closecr;
    // Do not process negative payment amounts. Nagative amounts happen when closing credits > upgrades cost
    if (transactionTotal <= 0) {
      alert('Payment amount: $0.00. No payment required');
      return;
    }
    // See: https://developer.squareup.com/docs/pos-api/build-mobile-web
    // multiply by 100 to convert to dollars
    transactionTotal = transactionTotal * 100;

    // test for android, ugh
    const isAndroid = navigator.userAgent.includes('Android');

    if (isAndroid) {
      const sdkVersion = 'v2.0';
      // Configure the allowable tender types
      const tenderTypes = `com.squareup.pos.TENDER_CARD,
      com.squareup.pos.TENDER_CARD_ON_FILE, 
      com.squareup.pos.TENDER_CASH, 
      com.squareup.pos.TENDER_OTHER`;

      const posUrl =
        'intent:#Intent;' +
        'action=com.squareup.pos.action.CHARGE;' +
        'package=com.squareup;' +
        'S.com.squareup.pos.WEB_CALLBACK_URI=' +
        callbackUrl +
        ';' +
        'S.com.squareup.pos.CLIENT_ID=' +
        applicationId +
        ';' +
        'S.com.squareup.pos.API_VERSION=' +
        sdkVersion +
        ';' +
        'i.com.squareup.pos.TOTAL_AMOUNT=' +
        transactionTotal +
        ';' +
        'S.com.squareup.pos.CURRENCY_CODE=' +
        currencyCode +
        ';' +
        'S.com.squareup.pos.TENDER_TYPES=' +
        tenderTypes +
        ';' +
        'end';
      //alert(posUrl);
      window.open(posUrl);
    } else {
      // proces iOS payment
      const dataParameter = {
        amount_money: {
          amount: transactionTotal,
          currency_code: currencyCode,
        },
        callback_url: callbackUrl,
        state: JSON.stringify({
          sale_id: sale.id,
          proj_id: projId,
          amount: transactionTotal.toString(10),
        }),
        client_id: applicationId,
        version: apiVersion,
        options: {
          supported_tender_types: [
            'CREDIT_CARD',
            'CASH',
            'OTHER',
            'SQUARE_GIFT_CARD',
            'CARD_ON_FILE',
          ],
        },
      };

      window.open(
        'square-commerce-v1://payment/create?data=' +
          encodeURIComponent(JSON.stringify(dataParameter))
      );
    }
  };

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const tabItems = [
    {
      title: 'Worksheet',
      type: 'worksheet',
    },
    {
      title: 'Selections (Colours/Styles)',
      type: 'selections',
    },
    {
      title: 'Upgrades Contract',
      type: 'upgrades',
    },
    {
      title: 'Upgrades Addendum',
      type: 'addendum',
    },
    {
      title: 'Construction Schedule',
      type: 'construction',
    },
  ];

  if (!sale || !sale.unit) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Documents</Typography>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          aria-describedby={id}
          type="button"
          onClick={handleClick}
        >
          Tip
        </Button>

        <Popper placement="bottom-end" id={id} open={open} anchorEl={anchorEl}>
          <Paper style={{ maxWidth: 400, zIndex: 999 }}>
            <Card>
              <CardContent>
                To properly sign or email a docusign envelope please do the
                following:
                <p>
                  1. Click <b>Generate Document</b> under PDF
                </p>
                <p>
                  2. Click <b>Sign Envelope</b> or <b>Email Envelope</b> under
                  Docusign
                </p>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  type="button"
                  onClick={handleClick}
                >
                  Close
                </Button>
              </CardContent>
            </Card>
          </Paper>
        </Popper>
      </Box>

      <Alert severity="info" style={{ maxWidth: 1200, marginTop: 20 }}>
        Templates are synced with the app&apos;s google drive account. You can
        view them{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={getTemplatesFolderURL()}
        >
          here
        </a>
        .To request access please contact the administrator.
      </Alert>

      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          {tabItems.map(item => (
            <Tab key={item.type} label={item.title} {...a11yProps(0)} />
          ))}
        </Tabs>
        {tabItems.map((template, index) => (
          <TabPanel key={template.type} value={value} index={index}>
            <Box pl={3}>
              <Typography
                variant="h6"
                style={{ marginBottom: 20, textAlign: 'center' }}
              >
                {template.title}
              </Typography>
              <Box display="flex" justifyContent="space-evenly">
                <div>
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 20,
                      textAlign: 'center',
                    }}
                  >
                    PDF
                  </Typography>
                  <Tooltip
                    title="Generate a document using the template"
                    arrow
                    placement="right-start"
                  >
                    <Button
                      variant="contained"
                      className={classes.btn}
                      color="primary"
                      onClick={() => generateContract(template.type)}
                    >
                      Generate Document
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={`View document for Unit ${sale.unit.unitno}`}
                    arrow
                    placement="right-start"
                  >
                    <Button
                      variant="contained"
                      className={classes.btn}
                      color="primary"
                      onClick={() => handleViewContract(template.type)}
                    >
                      See Document
                    </Button>
                  </Tooltip>

                  {PAYMENTS.includes(template.type) && (
                    <>
                      <Divider
                        style={{
                          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                          width: 200,
                          display: 'block',
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      />
                      <Tooltip
                        title="Pay for upgrades via Square"
                        arrow
                        placement="right-start"
                      >
                        <Button
                          variant="contained"
                          className={classes.btn}
                          color="secondary"
                          onClick={() => handlePayment(template.type)}
                        >
                          Pay for upgrades
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </div>
                {/* {DOCUSIGN.includes(template.type) && (
                  <div>
                    <Typography
                      variant="body1"
                      style={{
                        marginBottom: 20,
                        textAlign: 'center',
                      }}
                    >
                      Docusign
                    </Typography>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      color="primary"
                      onClick={() => createEnvelope(projId, saleId, 'pdi')}
                    >
                      Create Envelope
                    </Button>
                   
                  </div>
                )} */}
              </Box>
            </Box>
          </TabPanel>
        ))}
      </div>
      <Backdrop open={btnDisable} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default Documents;
