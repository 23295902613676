import React from 'react';
import TextField from '@material-ui/core/TextField';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

// see: https://github.com/text-mask/text-mask/tree/master/addons/#createnumbermask
const numberMask = createNumberMask({
  prefix: '$ ',
  allowDecimal: true,
});

const PriceMask = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={numberMask}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

PriceMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const formatValue = (event, field, form, charsToStrip) => {
  const cleanValue = stripCharacters(event.target.value, charsToStrip);
  form.setFieldValue(field.name, cleanValue);
};

const stripCharacters = (string, characters) => {
  const re = new RegExp(`[${characters}]+`, 'g');
  return string.replace(re, '');
};

const PriceInput = ({ field, form, ...props }) => {
  return (
    <TextField
      {...field}
      {...props}
      InputProps={{
        inputComponent: PriceMask,
        onChange: e => formatValue(e, field, form, '$, '),
      }}
    />
  );
};

PriceInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};
export default PriceInput;
