import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import MaterialTable from 'components/shared/MuiReactTable';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { fetchUnit } from 'state/ducks/residential/units';
import { makeStyles } from '@material-ui/core/styles';

import {
  deleteWarrItem,
  fetchWarrItems,
} from 'state/ducks/residential/warrantyItems';
import { fetchSale } from 'state/ducks/residential/activeSale';

const useStyles = makeStyles(theme => ({
  room: {
    width: 150,
  },
  action: {
    width: 50,
  },
  description: {
    minWidth: 500,
    [theme.breakpoints.only('md')]: {
      width: 500,
    },
  },
}));

export default function PdiList() {
  const { projId, saleId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const sale = useSelector(state => state.rd.activeSale.sale);
  const match = useRouteMatch({
    path: '/rd/:projId/tarion/pdi/:saleId',
  });
  const warrItems = useSelector(state => state.rd.warrantyItems);

  const data = warrItems.allIds.map(id => {
    const tmp = warrItems.byId[id];
    return {
      ...tmp,
      room: tmp.room.name,
      craft: tmp.craft ? tmp.craft.name : '',
    };
  });

  useEffect(() => {
    dispatch(fetchSale(projId, saleId));
  }, []);

  useEffect(() => {
    if (sale && sale.unit && sale.unit.id)
      dispatch(fetchWarrItems(sale.unit.id));
  }, [sale.unit.id]);

  // fetch unit
  useEffect(() => {
    if (sale && sale.unit && sale.unit.id)
      dispatch(fetchUnit(projId, sale.unit.id));
  }, [projId, sale.unit.id]);

  const handleDelete = id => {
    if (window.confirm('Are you sure?')) dispatch(deleteWarrItem(id));
  };

  const handleEdit = id => {
    history.push(`${match.url}/unit/${sale.unit.id}/edit/${id}`);
  };

  const getActionHeader = (id, Icon, onClick) => {
    return {
      Header: () => null, // No header
      id: id, // needs an id because header is null
      className: classes.action,
      Cell: function renderEdit({ row }) {
        return (
          <IconButton
            size="small"
            onClick={() => onClick(row.original)}
            color="inherit"
          >
            <Icon />
          </IconButton>
        );
      },
    };
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => null,
        id: 'items',
        columns: [
          getActionHeader('edit', Edit, row => handleEdit(row.id)),
          getActionHeader('delete', DeleteOutline, row => handleDelete(row.id)),
          {
            Header: 'Room',
            accessor: 'room',
            className: classes.room,
          },
          {
            Header: 'Description',
            accessor: 'desc',
            className: classes.description,
          },

          {
            Header: 'Created on',
            accessor: 'created_on',
          },
          // {
          //   Header: 'Resolved on',
          //   accessor: 'resolved_on',
          // },
          {
            Header: 'Craft',
            accessor: 'craft',
          },
        ],
      },
    ],
    [sale]
  );

  if (!sale || !sale.unit || !sale.unit.id) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={2}>
      <MaterialTable
        onAdd={() => history.push(`${match.url}/unit/${sale.unit.id}/add`)}
        columns={columns}
        data={data}
        title={`PDI for Unit ${sale.unit.unitno}`}
        options={{
          autoResetSortBy: false,
          initialState: {
            sortBy: [{ id: 'room', desc: false }],
          },
        }}
        persist={true}
      />
    </Box>
  );
}

PdiList.propTypes = {};
