import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Notification from 'components/shared/Notification';
import './app.css';
import 'react-virtualized/styles.css'; // only needs to be imported once

import Controller from 'views/layouts/Controller';

// Setting the color theme for Material UI
// See: https://material-ui.com/customization/theming/#api
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#448aff',
    },
  },
});

const App = () => {
  // set up theme and global notification component

  return (
    <MuiThemeProvider theme={theme}>
      <Notification />
      <Controller />
    </MuiThemeProvider>
  );
};

export default App;
