import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import UpgradesForm from './UpgradesForm';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setNotification } from 'state/ducks/globalNotification';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryParam, StringParam } from 'use-query-params';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import { IconButton } from '@material-ui/core';
import { saveSale } from 'state/ducks/residential/activeSale';

const RenderLockSale = () => {
  const sale = useSelector(state => state.rd.activeSale.sale);
  const dispatch = useDispatch();
  const lockSale = locksel => {
    try {
      if (
        locksel === true ||
        (locksel === false &&
          window.confirm('Are you sure you want to unlock selections?'))
      )
        dispatch(saveSale({ id: sale.id, locksel }, false));
    } catch (error) {
      alert('Something went wrong');
    }
  };
  return (
    <IconButton
      onClick={() => lockSale(!sale.locksel)}
      style={{ marginLeft: 'auto' }}
    >
      {sale.locksel ? <Lock /> : <LockOpen />}
    </IconButton>
  );
};
// This component does the following:
// 1. Fetch rooms filtered by type="room" or type="model_wide"
// 2. Fetch exlcusion groups (exclgrp) for selected model and room
// 3. Fetch ModelProdRoom (Products) filtered by room, model and exclgrp
// 4. Fetch selections
// Documentation: use-query-params https://github.com/pbeshai/use-query-params#urlupdatetype

const UpgradesTable = ({
  sale,
  selectedRoom,
  isModelWide,
  fetchRequiredSelections,
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const { projId } = useParams();
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [selections, setSelections] = useState([]);
  const [exclGrps, setExclGrps] = useState([]);
  const [roomId, setRoomId] = useState(null);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isLoadingExclGrp, setIsLoadingExclGrp] = useState(true);
  const [tabQuery, setTabQuery] = useQueryParam('tab', StringParam);

  useEffect(() => {
    // reset state when model room changes
    setProducts([]);
    setSelections([]);
    setExclGrps([]);
    setRoomId(selectedRoom.room.id);
  }, [selectedRoom.room.id]);

  useEffect(() => {
    // Set the current active tab based on the query parameter `tab`.
    // Setting this value in the url allows us to prevent a tab change.
    // i.e. if a user selects a colour, doesn't save and clicks another tab
    // we want to present a prompt asking if the user is sure they want to leave the current tab since they have unsaved changes
    if (tabQuery !== undefined) {
      setActiveTab(tabQuery);
    } else {
      setActiveTab(null);
    }
  }, [tabQuery]);

  useEffect(() => {
    const fetchExclusionGroups = async () => {
      try {
        setIsLoadingExclGrp(true);
        const res = await axios.get(
          `/api/v2/mprexclgrps?project_id=${projId}&model_id=${sale.unit.model.id}&room_id=${roomId}`
        );

        // sort by alpha
        const temp = res.data.exclgrp
          .map(p => {
            return { exclgrp: p.exclgrp, grpname: p.grpname };
          })
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          });

        setExclGrps(temp);
        if (temp.length) {
          setTabQuery(temp[0].exclgrp, 'replaceIn');
        }
      } catch (error) {
        dispatch(
          setNotification('error', error.message || 'Something went wrong')
        );
      } finally {
        setIsLoadingExclGrp(false);
      }
    };

    if (roomId) {
      fetchExclusionGroups();
    }
  }, [sale.id, roomId, projId, dispatch, sale.unit.model.id]);

  // fetch prodStyles for selected room
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoadingProducts(true);
        setProducts([]);
        const modelId = sale.unit.model.id;
        const exclGrp = activeTab.toUpperCase();
        // fetch product and fetch all product styles
        const url = `/api/v2/mprstyles?project_id=${projId}&limit=1000&model_id=${modelId}&exclgrp=${exclGrp}&room_id=${roomId}&embed[]=product`;
        const res = await axios.get(url);

        // sort by product name alpha
        const sorted = res.data.modelprodroom.sort((a, b) => {
          if (a.product.name < b.product.name) {
            return -1;
          }
          if (a.product.name > b.product.name) {
            return 1;
          }
          return 0;
        });
        setProducts(sorted);
      } catch (error) {
        dispatch(
          setNotification('error', error.message || 'Something went wrong')
        );
      } finally {
        setIsLoadingProducts(false);
      }
    };

    if (activeTab !== null) {
      fetchProducts();
    }
  }, [sale.id, roomId, activeTab, sale.unit.model.id, projId, dispatch]);

  useEffect(() => {
    const fetchSelections = async () => {
      try {
        const modelId = sale.unit.model.id;
        const exclGrp = activeTab.toUpperCase();
        let url = `/api/v2/selections?project_id=${projId}&model_id=${modelId}&sale_id=${sale.id}&exclgrp=${exclGrp}`;

        if (!isModelWide) {
          url = `${url}&room_id[]=${roomId}&&room_id[]=1`;
        }

        const res = await axios.get(url);

        setSelections(res.data.selection);
      } catch (error) {
        dispatch(
          setNotification('error', error.message || 'Something front end')
        );
      }
    };

    if (roomId && activeTab !== null) {
      fetchSelections();
    }
    // fetchSelection anytime these values change
  }, [
    sale.id,
    roomId,
    isModelWide,
    activeTab,
    sale.id,
    sale.unit.model.id,
    projId,
    dispatch,
  ]);

  const handleTabChange = (e, tab) => {
    // if user clicks the tab they're on, do nothing
    if (tab !== tabQuery) {
      setTabQuery(tab);
    }
  };

  const getTitle = () => {
    if (!selectedRoom) {
      return '';
    }
    return isModelWide ? 'MODEL WIDE' : selectedRoom.room.name;
  };

  const title = getTitle();
  return (
    <>
      <Typography
        style={{ marginLeft: 10, marginTop: 50, marginBottom: 10 }}
        variant="h5"
      >
        {title}
      </Typography>
      {isLoadingExclGrp && (
        <Box display="flex" justifyContent="center" p={4}>
          <CircularProgress />
        </Box>
      )}
      {!isLoadingExclGrp && activeTab !== null && (
        <Paper>
          <Grid container>
            <Grid item xs={12}>
              <AppBar
                variant="outlined"
                position="static"
                color="default"
                style={{
                  zIndex: 'auto',
                  background: 'inherit',
                }}
              >
                <Box display="flex">
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {exclGrps.map(eg => {
                      const label = eg.grpname;
                      const key = eg.exclgrp;
                      const tablabel = key > '' ? key : 'General';
                      return (
                        <Tab
                          key={key}
                          label={label}
                          value={key}
                          title={label}
                        />
                      );
                    })}
                  </Tabs>
                  <RenderLockSale />
                </Box>
              </AppBar>
            </Grid>
            <Grid item xs={12}>
              {isLoadingProducts && (
                <Box display="flex" justifyContent="center" p={4}>
                  <CircularProgress />
                </Box>
              )}
              {!isLoadingProducts && (
                <UpgradesForm
                  upgrades={products}
                  selections={selections}
                  sale={sale}
                  activeTab={activeTab}
                  updateSelections={setSelections}
                  fetchRequiredSelections={fetchRequiredSelections}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

UpgradesTable.propTypes = {
  sale: PropTypes.object,
  selectedRoom: PropTypes.object,
  isModelWide: PropTypes.bool,
  fetchRequiredSelections: PropTypes.func.isRequired,
};

export default UpgradesTable;
