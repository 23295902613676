import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSession } from 'state/ducks/session';
import MainLayout from 'views/layouts/MainLayout';
import { REDIRECT_TO } from 'libs/constants';
import { useHistory } from 'react-router-dom';
const Controller = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // 1. Fetch user session
  // 2. render nothing if session is still being fetched
  const authentication = useSelector(state => state.authentication);
  useEffect(() => {
    dispatch(fetchSession());
  }, []);

  if (!authentication.fetched) {
    return null;
  }

  // if the user was redirected outside of the app and redirected back in then we will push the route that they were on
  const redirectTo = localStorage.getItem(REDIRECT_TO);
  if (redirectTo) {
    localStorage.removeItem(REDIRECT_TO);
    history.push(redirectTo);
  }

  return <MainLayout />;
};

export default Controller;
