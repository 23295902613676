import React, { useState } from 'react';
import { addUnit } from 'state/ducks/residential/units';
import { useDispatch } from 'react-redux';
import UnitForm from './UnitForm';
import Box from '@material-ui/core/Box';
import { Prompt, useHistory } from 'react-router-dom';

const AddUnitForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(true);

  const handleAdd = async values => {
    const success = await dispatch(addUnit(values));
    if (success) {
      setShowPrompt(false);
      let path = history.location.pathname.split('/');
      path = path.slice(0, path.length - 1).join('/');
      history.push(path);
    }
  };

  return (
    <Box p={4}>
      <Prompt when={showPrompt} message="Are you sure you want to leave?" />
      <UnitForm onSubmit={handleAdd} title="Add Unit" />
    </Box>
  );
};

export default AddUnitForm;
