import React from 'react';
import { addModel } from 'state/ducks/residential/models';
import { useDispatch } from 'react-redux';
import ModelForm from './ModelForm';
import Box from '@material-ui/core/Box';
import { useHistory, useParams } from 'react-router-dom';

const AddModelForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projId } = useParams();

  const handleAdd = async values => {
    const success = await dispatch(
      addModel({
        ...values,
        project: projId,
      })
    );
    if (success) {
      let path = history.location.pathname.split('/');
      path = path.slice(0, path.length - 1).join('/');
      history.push(path);
    }
  };

  return (
    <Box p={4}>
      <ModelForm onSubmit={handleAdd} title="Add Model" />
    </Box>
  );
};

export default AddModelForm;
