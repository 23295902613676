import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import MuiTable from 'components/shared/MuiTable';

const Tarion = () => {
  const history = useHistory();

  const showTarionInspection = rowData => {
    const url = `${history.location.pathname}/pdi/${rowData.id}/${rowData.unit_id}`;
    history.push(url);
  };

  return (
    <Box p={2}>
      <MuiTable
        title={
          <Typography variant="h6" style={{ width: 300 }}>
            Tarion Pre Delivery Inspection
          </Typography>
        }
        options={{
          filtering: true,
          search: false,
        }}
        columns={[
          {
            title: 'Unit Number',
            field: 'unitno',
          },
          {
            title: 'Model',
            field: 'model',
          },
          {
            title: 'Purchaser',
            field: 'purchaser',
          },

          {
            title: 'Sale status',
            field: 'salestatus',
          },
        ]}
        rowName="Inspection"
        data={query =>
          new Promise(resolve => {
            let url = '/api/Sales?';
            url += 'limit=' + query.pageSize;
            url += '&start=' + query.page * query.pageSize;

            const orderBy = query.orderBy && query.orderBy.field;
            const dir = query.orderDirection;
            if (!!orderBy && !!dir) {
              url += '&order=' + orderBy;
              url += '&dir=' + dir;
            }

            const filters = query.filters.reduce((result, filter) => {
              return (result += `&${filter.column.field}=${filter.value}`);
            }, '');

            url += filters;

            axios.get(url).then(response => {
              const data = response.data.sale.map(sale => {
                const primaryPurchaser = sale.saleaddr.find(
                  addr => addr.primary === true
                );
                return {
                  id: sale.id,
                  unit_id: sale.unit.id,
                  unitno: sale.unit.unitno,
                  model: sale.unit.model.model,
                  purchaser: primaryPurchaser
                    ? primaryPurchaser.address.name
                    : 'None',
                  salestatus: sale.salestatus,
                };
              });
              resolve({
                data: data,
                page: query.page,
                totalCount: response.data.total,
              });
            });
          })
        }
        onEdit={showTarionInspection}
      />
    </Box>
  );
};

Tarion.propTypes = {};

export default Tarion;
