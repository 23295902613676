import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import isEmpty from 'lodash/isEmpty';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory, Prompt } from 'react-router-dom';

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  form: {
    display: 'flex',
    width: 550,
    alignItems: 'center',
  },
  input: {
    width: 250,
  },
  formSelect: {
    width: '250px',
  },
  radio: {
    width: '100%',
    marginTop: 30,
  },
});

const UnitForm = ({
  initialValues = {},
  onSubmit,
  title,
  buttonText = 'Add',
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [formModel, setFormModel] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const defaultValues = {
      id: '',
      project: '',
      model: '',
      sale: '',
      modelname: '',
      area: '',
      unitno: '',
      baseprice: '',
      unitstatus: '',
      note: '',
      municipal: '',
    };

    if (initialValues && !isEmpty(initialValues)) {
      setFormModel({
        ...defaultValues,
        ...initialValues,
      });
    } else {
      setFormModel(defaultValues);
    }
  }, [initialValues]);

  const validateForm = values => {
    const errors = {};

    if (!values.unitno) {
      errors.unitno = 'Required';
    }
    if (!values.baseprice) {
      errors.baseprice = 'Required';
    }
    if (!values.unitstatus) {
      errors.unitstatus = 'Required';
    }

    return errors;
  };

  if (!formModel) {
    return null;
  }

  return (
    <Box p={4} display="flex" justifyContent="center" flexDirection="column">
      <Prompt when={showPrompt} message="Are you sure you want to leave?" />

      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <Formik
        initialValues={{
          ...formModel,
        }}
        validate={validateForm}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({ values, isSubmitting }) => (
          <Form className={classes.formContainer}>
            <FormControl className={classes.form}>
              {/* make dropdown selection of Models for add */}
              <Field
                component={TextField}
                label="Model"
                name="modelname"
                value={values.modelname}
                margin="dense"
                className={classes.input}
                variant="outlined"
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <Field
                component={TextField}
                label="Area (Square Feet)"
                name="area"
                value={values.area}
                margin="dense"
                className={classes.input}
                variant="outlined"
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <Field
                component={TextField}
                label="Unit Number"
                name="unitno"
                value={values.unitno}
                margin="dense"
                className={classes.input}
                type="number"
                variant="outlined"
                inputProps={{
                  maxLength: 8,
                  onFocus: () => setShowPrompt(true),
                }}
              />
              <Field
                component={TextField}
                label="Base Price"
                name="baseprice"
                value={values.baseprice}
                margin="dense"
                className={classes.input}
                type="number"
                variant="outlined"
                inputProps={{
                  onFocus: () => setShowPrompt(true),
                  step: 0.01,
                }}
              />
              <Field
                component={TextField}
                label="Note"
                name="note"
                value={values.note}
                margin="dense"
                className={classes.input}
                variant="outlined"
                inputProps={{
                  onFocus: () => setShowPrompt(true),
                }}
              />
              <Field
                component={TextField}
                label="Municipal"
                name="municipal"
                value={values.municipal}
                margin="dense"
                className={classes.input}
                type="number"
                variant="outlined"
                inputProps={{
                  onFocus: () => setShowPrompt(true),
                }}
              />
              <Field
                select
                component={TextField}
                label="Status"
                name="unitstatus"
                value={values.unitstatus}
                margin="dense"
                className={classes.formInput}
                variant="outlined"
                disabled={values.unitstatus === 'Contract'} // Cannot change status if there are associated sale(s)
                style={{
                  width: '100%',
                  maxWidth: '250px',
                }}
                inputProps={{
                  onFocus: () => setShowPrompt(true),
                }}
              >
                {values.unitstatus === 'Contract' && (
                  <MenuItem value={'Contract'}>Contract</MenuItem>
                )}
                <MenuItem value={'Available'}>Available</MenuItem>
                <MenuItem value={'Hold'}>Hold</MenuItem>
              </Field>
              <Box display="flex" justifyContent="center" mt={3} width="100%">
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: 10 }}
                  className={classes.submitButton}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {buttonText}
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ marginRight: 10 }}
                  className={classes.submitButton}
                  onClick={() => history.goBack()}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Box>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

UnitForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  hasSale: PropTypes.bool,
};

UnitForm.defaultProps = {
  initialValues: {},
  title: '',
};

export default UnitForm;
