import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import MaterialTable, { MTableToolbar } from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { fetchNotes, deleteNote } from 'state/ducks/residential/saleNotes';
import { useParams } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
const NotesTable = ({ match, history }) => {
  const { projId, saleId } = useParams();
  const dispatch = useDispatch();
  const saleNotes = useSelector(state => state.rd.saleNotes);

  useEffect(() => {
    dispatch(fetchNotes(projId, saleId));
  }, [projId, saleId]);

  const handleDelete = id => {
    if (window.confirm('Are you sure you want to delete the note?')) {
      dispatch(deleteNote(id));
    }
  };

  return (
    <Box>
      <MaterialTable
        title="Notes on this contract"
        components={{
          Toolbar: function renderToolbar(props) {
            return (
              <Box display="flex" flexDirection="row">
                <Box width="100%">
                  <MTableToolbar {...props} />
                </Box>
                <Box marginLeft="auto" display="flex" alignItems="center">
                  <Tooltip title="Add">
                    <IconButton
                      aria-label="add"
                      onClick={() => history.push(`${match.url}/add`)}
                    >
                      <AddBox />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            );
          },
        }}
        options={{
          search: false,
          paging: false,
        }}
        columns={[
          {
            title: 'Note',
            field: 'Note',
            render: rowData => rowData.note,
          },
          {
            title: 'Note Date',
            field: 'note_date',
            render: rowData => rowData.notedate,
          },
          {
            title: 'Tickler Date',
            field: 'tickler_date',
            render: rowData => rowData.ticklerdate,
          },
          {
            title: 'For',
            field: 'visibility',
            render: function renderVisibility(rowData) {
              if (!rowData.visibility) {
                return null;
              }
              return (
                <>
                  {rowData.visibility.split(',').map(val => (
                    <Chip key={val} label={val} style={{ marginRight: 2 }} />
                  ))}
                </>
              );
            },
          },
        ]}
        data={saleNotes.allIds.map(id => {
          const noteData = saleNotes.byId[id];
          return {
            note: noteData.note,
            id: noteData.id,
            notedate: noteData.notedate,
            ticklerdate: noteData.ticklerdate,
            visibility: noteData.visibility,
          };
        })}
        actions={[
          {
            icon: Edit,
            tooltip: 'Edit Note',
            onClick: (event, rowData) =>
              history.push(`${match.url}/edit/${rowData.id}`),
          },
          {
            icon: DeleteOutline,
            tooltip: 'Delete Note',
            onClick: (event, rowData) => handleDelete(rowData.id),
          },
        ]}
      />
    </Box>
  );
};

NotesTable.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default NotesTable;
