import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import isEmpty from 'lodash/isEmpty';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { Prompt } from 'react-router-dom';
import { fetchCrafts } from 'state/ducks/residential/crafts';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { RadioGroup } from 'formik-material-ui';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

const productSchema = Yup.object().shape({
  name: Yup.string().required(),
  craft: Yup.string().required(),
  prodtype: Yup.string().required(),
  exclgrp: Yup.string().max(12),
  category: Yup.string().required(),
});

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  input: {
    width: 250,
  },
  formSelect: {
    width: '250px',
  },
  radio: {
    width: '100%',
    marginTop: 30,
  },
});

const ProductForm = ({
  initialValues = {},
  onSubmit,
  title,
  projId,
  buttonText = 'Add',
  type = 'Add',
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [formModel, setFormModel] = useState(null);
  const crafts = useSelector(state => state.rd.crafts);

  const [showPrompt, setShowPrompt] = useState(false);
  const prodTypes = [
    {
      key: 'Base',
      value: 'Base',
    },
    {
      key: 'Upgrade',
      value: 'Upgrade',
    },
  ];

  useEffect(() => {
    const defaultValues = {
      craft: '',
      exclgrp: '',
      name: '',
      prodtype: '',
      category: 'model',
      project: projId,
    };

    if (initialValues && !isEmpty(initialValues)) {
      setFormModel({
        ...defaultValues,
        ...initialValues,
      });
    } else {
      setFormModel(defaultValues);
    }
  }, [initialValues, projId]);

  useEffect(() => {
    dispatch(fetchCrafts(projId));
  }, [dispatch, projId]);

  const sanitizeForm = values => {
    const temp = {
      ...values,
    };
    temp.exclgrp = temp.exclgrp.toUpperCase();
    return temp;
  };

  if (!formModel || crafts.allIds.length === 0) {
    return null;
  }
  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Prompt when={showPrompt} message="Are you sure you want to leave?" />
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <Formik
        initialValues={{
          ...formModel,
        }}
        enableReinitialize
        validationSchema={productSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          setShowPrompt(false);
          onSubmit(sanitizeForm(values));
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className={classes.formContainer}>
            <FormControl className={classes.form}>
              <Field
                component={TextField}
                label="Name"
                name="name"
                value={values.name}
                margin="dense"
                inputProps={{
                  onFocus: () => setShowPrompt(true),
                }}
                className={classes.input}
                variant="outlined"
              />
              <Field
                component={TextField}
                type="text"
                select
                label="Product Type"
                name="prodtype"
                inputProps={{
                  onFocus: () => setShowPrompt(true),
                }}
                value={values.prodtype}
                className={classes.input}
                margin="dense"
                variant="outlined"
              >
                {prodTypes.map(pt => (
                  <MenuItem key={pt.key} value={pt.key}>
                    {pt.value}
                  </MenuItem>
                ))}
              </Field>
              <Field
                component={TextField}
                type="text"
                select
                label="Craft"
                name="craft"
                inputProps={{
                  onFocus: () => setShowPrompt(true),
                }}
                value={values.craft}
                className={classes.input}
                margin="dense"
                variant="outlined"
              >
                {crafts.allIds.map(id => {
                  const c = crafts.byId[id];
                  return (
                    <MenuItem key={c.id} value={c.id}>
                      {c.name}
                    </MenuItem>
                  );
                })}
              </Field>
              {/* TODO: make this a select */}
              <Field
                component={TextField}
                label="Exclusivity Group"
                name="exclgrp"
                inputProps={{
                  onFocus: () => setShowPrompt(true),
                }}
                value={values.exclgrp}
                margin="dense"
                className={classes.input}
                variant="outlined"
              />
              {type === 'Add' && (
                <Box width="250px" mt={3}>
                  <FormLabel component="legend">
                    What is this product for?
                  </FormLabel>
                  <Field
                    name="category"
                    component={RadioGroup}
                    label="radio group"
                    value={values.category}
                    type="radio"
                  >
                    <FormControlLabel
                      value="Model"
                      control={<Radio />}
                      label="Model wide"
                    />
                    <FormControlLabel
                      value="Room"
                      control={<Radio />}
                      label="By Room"
                    />
                    <FormControlLabel
                      value="Both"
                      control={<Radio />}
                      label="Both"
                    />
                  </Field>
                </Box>
              )}
              {type === 'Edit' && (
                <Box width="250px" mt={3}>
                  <FormLabel component="legend">This product is for:</FormLabel>
                  <Field
                    name="category"
                    component={RadioGroup}
                    label="radio group"
                    value={values.category}
                    type="radio"
                  >
                    <FormControlLabel
                      value="Model"
                      control={<Radio />}
                      disabled={values.category !== 'Model'}
                      label="Model wide"
                    />
                    <FormControlLabel
                      value="Room"
                      disabled={values.category !== 'Room'}
                      control={<Radio />}
                      label="By Room"
                    />
                    <FormControlLabel
                      disabled={values.category !== 'Both'}
                      value="Both"
                      control={<Radio />}
                      label="Both"
                    />
                  </Field>
                </Box>
              )}

              <Box display="flex" justifyContent="center" mt={3} width="100%">
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: 10 }}
                  className={classes.submitButton}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {buttonText}
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ marginRight: 10 }}
                  className={classes.submitButton}
                  onClick={() => history.goBack()}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Box>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

ProductForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  projId: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  isEdit: PropTypes.bool,
  type: PropTypes.string,
};

ProductForm.defaultProps = {
  initialValues: {},
  title: '',
};

export default ProductForm;
