import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModels } from 'state/ducks/residential/models';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Typography, Divider } from '@material-ui/core';
import { setNotification } from 'state/ducks/globalNotification';

import MprByRoomAccordion from './MprByRoomAccordion';

const MprByRoom = ({ product }) => {
  const models = useSelector(state => state.rd.models);
  const dispatch = useDispatch();
  const { projId } = useParams();
  const [prices, setPrices] = useState({});
  const [modelRooms, setModelRooms] = useState({});
  const [expanded, setExpanded] = useState(-1);

  useEffect(() => {
    if (models.allIds.length === 0) {
      dispatch(fetchModels(projId));
    }
  }, [models, dispatch, projId]);

  const handleAdd = async (modelRoom, values) => {
    const { model, room } = modelRoom;

    // TODO: refactor
    const payload = {
      product: product.id,
      room: room.id,
      model: model.id,
      price: values.price,
    };
    try {
      const res = await axios.post('/api/ModelProdRoom', [
        {
          root: 'modelprodroom',
          modelprodroom: payload,
        },
      ]);

      const mpr = res.data.modelprodroom[0].fields;

      const roomPrices = prices[model.id];
      setPrices({
        ...prices,
        [model.id]: {
          allIds: [...roomPrices.allIds, room.id],
          byId: {
            ...roomPrices.byId,
            [room.id]: mpr,
          },
        },
      });
      dispatch(setNotification('success', 'Added'));
    } catch (error) {
      dispatch(setNotification('error', 'Could not add price to model'));
      return null;
    }
  };

  const handleSave = async (modelRoom, values) => {
    const { model, room } = modelRoom;
    const payload = {
      product: product.id,
      room: room.id,
      model: model.id,
      price: values.price,
      id: prices[model.id].byId[room.id].id,
    };
    try {
      const res = await axios.put('/api/ModelProdRoom', [
        {
          root: 'modelprodroom',
          modelprodroom: payload,
        },
      ]);

      const mpr = res.data.modelprodroom[0].fields;
      const roomPrices = prices[model.id];

      setPrices({
        ...prices,
        [model.id]: {
          ...roomPrices,
          byId: {
            ...roomPrices.byId,
            [room.id]: mpr,
          },
        },
      });
      dispatch(setNotification('success', 'Saved'));
    } catch (error) {
      dispatch(setNotification('error', 'Could not save price'));
      return null;
    }
  };

  const handleDelete = async modelRoom => {
    const { model, room } = modelRoom;

    const payload = {
      id: prices[model.id].byId[room.id].id,
    };
    try {
      await axios.delete('/api/ModelProdRoom', {
        data: [
          {
            root: 'modelprodroom',
            modelprodroom: payload,
          },
        ],
      });

      const roomPrices = prices[model.id];

      setPrices({
        ...prices,
        [model.id]: {
          allIds: roomPrices.allIds.filter(id => id !== room.id),
          byId: {
            ...roomPrices.byId,
            [room.id]: undefined,
          },
        },
      });

      dispatch(setNotification('success', 'Deleted'));
    } catch (error) {
      dispatch(setNotification('error', 'Could not delete'));
      return null;
    }
  };

  const fetchPrices = async modelId => {
    try {
      if (prices[modelId]) {
        return;
      }
      const url = `/api/ModelProdRooms?project_id=${projId}&model_id=${modelId}&product_id=${product.id}`;
      const res = await axios.get(url);

      const data = res.data.modelprodroom.filter(mpr => mpr.room.id !== 1);
      const allIds = data.map(d => d.room.id);
      const byId = data.reduce((result, item) => {
        result[item.room.id] = item;
        return result;
      }, {});

      setPrices({
        ...prices,
        [modelId]: {
          allIds,
          byId,
        },
      });
    } catch (error) {
      dispatch(
        setNotification('error', error.message || 'Could get fetch prices.')
      );
    }
  };

  const fetchModelRooms = async modelId => {
    try {
      if (modelRooms[modelId]) {
        return;
      }
      const res = await axios.get(
        `/api/ModelRooms?limit=1000&project_id=${projId}&model_id=${modelId}`
      );
      const data = res.data.modelroom;
      const allIds = data.map(d => d.room.id);
      const byId = data.reduce((result, item) => {
        result[item.room.id] = item;
        return result;
      }, {});

      setModelRooms({
        ...modelRooms,
        [modelId]: {
          allIds,
          byId,
        },
      });
    } catch (error) {
      dispatch(
        setNotification(
          'error',
          error.message || 'Could get fetch model rooms.'
        )
      );
    }
  };
  const handleChange = (event, panel, isExpanded) => {
    if (expanded !== panel) {
      fetchModelRooms(panel);
      fetchPrices(panel);
    }

    setExpanded(isExpanded ? panel : -1);
  };

  if (!models) {
    return <CircularProgress />;
  }

  return (
    <>
      <Typography variant="h6">By Model</Typography>
      <Divider style={{ marginBottom: 10, marginTop: 10 }} />
      <Alert style={{ maxWidth: 800, marginBottom: 20 }} severity="info">
        To add the product to a room, click a model, enter a price and click the
        &apos;Add&apos; button
      </Alert>

      {models.allIds.map(key => {
        const model = models.byId[key];
        const roomPrices = prices[key];
        const modelRoomsForModel = modelRooms[key];
        const isBaseProduct = product.prodtype === 'Base';
        return (
          <MprByRoomAccordion
            key={model.id}
            modelRooms={modelRoomsForModel || {}}
            roomPrices={roomPrices || {}}
            model={model}
            onAdd={handleAdd}
            onSave={handleSave}
            onDelete={handleDelete}
            handleChange={handleChange}
            expanded={expanded}
            isBase={isBaseProduct}
          />
        );
      })}
    </>
  );
};

MprByRoom.propTypes = {
  product: PropTypes.object,
};

export default MprByRoom;
