import { combineReducers } from 'redux';

import modelRms from './modelroom';
import models from './models';
import rooms from './rooms';
import sales from './sales';
import units from './units';
import crafts from './crafts';
import products from './products';
import pmodels from './productModels';
import prooms from './productRooms';
import pstyles from './productStyles';
import projects from './projects';
import activeSale from './activeSale';
import saleDeposits from './saleDeposits';
import saleNotes from './saleNotes';
import warrantyItems from './warrantyItems';

const rootReducer = combineReducers({
  crafts,
  sales,
  modelRms,
  models,
  projects,
  activeSale,
  saleDeposits,
  saleNotes,
  products,
  pmodels,
  prooms,
  pstyles,
  rooms,
  units,
  warrantyItems,
});

export default rootReducer;
