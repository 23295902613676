import axios from 'axios';
import produce from 'immer';
import { getIds, getObject } from 'utils';

export const FETCH_WARRITEMS_REQUEST = 'FETCH_WARRITEMS_REQUEST';
export const FETCH_WARRITEMS_SUCCESS = 'FETCH_WARRITEMS_SUCCESS';
export const FETCH_WARRITEMS_FAILURE = 'FETCH_WARRITEMS_FAILURE';

export const FETCH_WARRITEM_REQUEST = 'FETCH_WARRITEM_REQUEST';
export const FETCH_WARRITEM_SUCCESS = 'FETCH_WARRITEM_SUCCESS';
export const FETCH_WARRITEM_FAILURE = 'FETCH_WARRITEM_FAILURE';

export const ADD_WARRITEM_REQUEST = 'ADD_WARRITEM_REQUEST';
export const ADD_WARRITEM_SUCCESS = 'ADD_WARRITEM_SUCCESS';
export const ADD_WARRITEM_FAILURE = 'ADD_WARRITEM_FAILURE';

export const EDIT_WARRITEM_REQUEST = 'EDIT_WARRITEM_REQUEST';
export const EDIT_WARRITEM_SUCCESS = 'EDIT_WARRITEM_SUCCESS';
export const EDIT_WARRITEM_FAILURE = 'EDIT_WARRITEM_FAILURE';

export const DELETE_WARRITEM_REQUEST = 'DELETE_WARRITEM_REQUEST';
export const DELETE_WARRITEM_SUCCESS = 'DELETE_WARRITEM_SUCCESS';
export const DELETE_WARRITEM_FAILURE = 'DELETE_WARRITEM_FAILURE';

export const RESET_WARR_ITEM = 'RESET_WARR_ITEM';

const initState = {
  allIds: [],
  byId: {},
  isLoading: false,
};

export default function(state = initState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case RESET_WARR_ITEM:
        draft.isLoading = false;
        draft.allIds = [];
        draft.byId = {};
        break;

      case FETCH_WARRITEMS_REQUEST:
        draft.isLoading = true;
        break;

      case FETCH_WARRITEM_SUCCESS: {
        draft.byId[action.warritem.id] = action.warritem;
        const id = draft.allIds.findIndex(id => id === action.warritem.id);
        if (id < 0) draft.allIds.push(action.warritem.id);
        break;
      }

      case FETCH_WARRITEM_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      case FETCH_WARRITEMS_SUCCESS:
        draft.byId = getObject(action.warritems, 'id');
        draft.allIds = getIds(action.warritems, 'id');
        break;

      case FETCH_WARRITEMS_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      case ADD_WARRITEM_REQUEST:
        draft.isLoading = true;
        break;

      case ADD_WARRITEM_SUCCESS:
        draft.allIds.push(action.warritem.id);
        draft.byId[action.warritem.id] = action.warritem;
        break;

      case ADD_WARRITEM_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      case EDIT_WARRITEM_REQUEST:
        draft.isLoading = true;
        break;

      case EDIT_WARRITEM_SUCCESS:
        draft.byId[action.warritem.id] = action.warritem;
        break;

      case EDIT_WARRITEM_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      case DELETE_WARRITEM_REQUEST:
        draft.isLoading = true;
        break;

      case DELETE_WARRITEM_SUCCESS:
        draft.allIds = draft.allIds.filter(id => id !== action.id);
        delete draft.byId[action.id];
        break;

      case DELETE_WARRITEM_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      default:
        return state;
    }
  });
}

export const resetWarrItems = () => async dispatch => {
  dispatch({
    type: RESET_WARR_ITEM,
  });
};

export const fetchWarrItem = (saleId, id) => async dispatch => {
  try {
    dispatch({ type: FETCH_WARRITEMS_REQUEST });
    const response = await axios.get(`/api/v2/warritem/${id}`);
    return dispatch({
      type: FETCH_WARRITEM_SUCCESS,
      warritem: response.data.warr_item,
    });
  } catch (err) {
    dispatch({
      type: FETCH_WARRITEM_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot fetch warritem. Please try again',
      },
    });
  }
};

export const fetchWarrItems = unitId => async dispatch => {
  try {
    dispatch({ type: FETCH_WARRITEMS_REQUEST });
    const response = await axios.get(
      `/api/v2/warritems?unit_id=${unitId}&limit=1000`
    );
    return dispatch({
      type: FETCH_WARRITEMS_SUCCESS,
      warritems: response.data.warr_item,
    });
  } catch (err) {
    dispatch({
      type: FETCH_WARRITEMS_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot fetch warritem. Please try again',
      },
    });

    return { error: true };
  }
};

export const addWarrItem = warrItem => async dispatch => {
  try {
    dispatch({ type: ADD_WARRITEM_REQUEST });
    const res = await axios.post('/api/v2/warritem', {
      ...warrItem,
    });
    return dispatch({
      type: ADD_WARRITEM_SUCCESS,
      warritem: res.data.warr_item,
      globalNotification: {
        variant: 'success',
        message: 'Added!',
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_WARRITEM_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot add PDI. Please try again',
      },
    });
  }
};

export const editWarrItem = (id, warrItem) => async dispatch => {
  try {
    dispatch({ type: EDIT_WARRITEM_REQUEST });
    const res = await axios.put(`/api/v2/warritem/${id}`, {
      ...warrItem,
    });
    dispatch({
      type: EDIT_WARRITEM_SUCCESS,
      warritem: res.data.warr_item,
      globalNotification: {
        variant: 'success',
        message: 'Saved',
      },
    });
    return { error: false };
  } catch (error) {
    dispatch({
      type: EDIT_WARRITEM_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot edit warritem. Please try again',
      },
    });
    return { error: true };
  }
};

export const deleteWarrItem = id => async dispatch => {
  try {
    dispatch({ type: DELETE_WARRITEM_REQUEST });
    await axios.delete(`/api/v2/warritem/${id}`);
    dispatch({
      type: DELETE_WARRITEM_SUCCESS,
      id: id,
      globalNotification: {
        variant: 'success',
        message: 'Deleted',
      },
    });
  } catch (error) {
    dispatch({
      type: DELETE_WARRITEM_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot delete warritem. Please try again',
      },
    });
  }
};
