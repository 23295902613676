import React, { useState, useEffect } from 'react';
import { editRoom, fetchRoom } from 'state/ducks/residential/rooms';
import { useDispatch, useSelector } from 'react-redux';
import RoomForm from './RoomForm';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';

const EditRoomForm = () => {
  const dispatch = useDispatch();
  const { projId, roomId } = useParams();
  const room = useSelector(state => state.rd.rooms.byId[roomId]);
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    if (room) {
      setFormValues({
        id: room.id,
        name: room.name,
        short: room.short,
        category: room.category,
        countsas: room.countsas,
      });
    } else {
      dispatch(fetchRoom(projId, roomId));
    }
  }, [room, projId]);

  const handleEdit = values => {
    dispatch(editRoom(values));
  };

  if (!formValues) {
    return null;
  }

  return (
    <Box p={4}>
      <RoomForm
        initialValues={formValues}
        onSubmit={handleEdit}
        title="Edit Room"
        buttonText="Save"
      />
    </Box>
  );
};

export default EditRoomForm;
