import React from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { MTableToolbar } from 'material-table';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

function MuiTable({
  style = {},
  onEdit,
  onDelete,
  onAdd,
  rowName,
  components = {},
  ...rest
}) {
  return (
    <MaterialTable
      {...rest}
      style={{
        borderRadius: 10,
        ...style,
      }}
      components={{
        // eslint-disable-next-line react/display-name
        Toolbar: props => (
          <Box display="flex" flexDirection="row">
            <Box width="100%">
              <MTableToolbar {...props} />
            </Box>
            {onAdd && (
              <Box marginLeft="auto" display="flex" alignItems="center" pr={2}>
                <Tooltip title={`Create ${rowName}`}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={onAdd}
                    startIcon={<AddBox />}
                  >
                    Create
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>
        ),
        ...components,
      }}
      actions={[
        onEdit
          ? {
              icon: Edit,
              tooltip: `Edit ${rowName}`,
              onClick: (event, rowData) => onEdit(rowData),
            }
          : null,
        onDelete
          ? {
              icon: DeleteOutline,
              tooltip: `Delete ${rowName}`,
              onClick: (event, rowData) => onDelete(rowData),
            }
          : null,
      ]}
    />
  );
}

MuiTable.propTypes = {
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  rowName: PropTypes.string,
  components: PropTypes.object,
  options: PropTypes.object,
  style: PropTypes.object,
};

export default MuiTable;
