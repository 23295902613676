import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import isEmpty from 'lodash/isEmpty';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchModels, deleteModel } from 'state/ducks/residential/models';
import MuiTable from 'components/shared/MuiTable';

const ModelsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projId, saleId } = useParams();

  const models = useSelector(state => state.rd.models);

  useEffect(() => {
    dispatch(fetchModels(projId, saleId));
  }, [projId, saleId, dispatch]);

  const handleDelete = values => {
    if (window.confirm('Are you sure you want to delete the model?'))
      dispatch(deleteModel({ id: values.id }));
  };

  const showEditForm = rowData =>
    history.push(`${history.location.pathname}/edit/${rowData.id}`);

  const showAddForm = () => history.push(`${history.location.pathname}/create`);

  if (isEmpty(models)) {
    return null;
  }

  const tableData = models.allIds.map(id => {
    const modelData = models.byId[id];
    return {
      name: modelData.model,
      baseprice: modelData.baseprice,
      id: modelData.id,
      bedrooms: modelData.bedrooms,
      bathrooms: modelData.bathrooms,
      area: modelData.area,
      project: modelData.project.id,
    };
  });

  return (
    <Box p={2}>
      {models.isLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!models.isLoading && (
        <MuiTable
          title="Models"
          rowName="Model"
          columns={[
            {
              title: 'Name',
              field: 'name',
            },
            {
              title: 'Base price',
              field: 'baseprice',
            },
            {
              title: 'Bedrooms',
              field: 'bedrooms',
            },
            {
              title: 'Bathrooms',
              field: 'bathrooms',
            },
            {
              title: 'Area',
              field: 'area',
            },
          ]}
          onEdit={showEditForm}
          onDelete={handleDelete}
          onAdd={showAddForm}
          data={tableData}
        />
      )}
    </Box>
  );
};

ModelsTable.propTypes = {};

export default ModelsTable;
