import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { RESET_ERROR_MESSAGE } from 'state/ducks/globalNotification';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const SnackbarContentStyles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  z: {
    zIndex: 9999,
  },
});

const StyledSnackbarContent = withStyles(SnackbarContentStyles)(props => {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={classNames(classes[variant], className, classes.z)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
});

StyledSnackbarContent.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

StyledSnackbarContent.defaultProps = {
  variant: 'info',
};

class Notification extends Component {
  state = {
    isOpen: false,
  };

  componentDidUpdate(prevProps) {
    const { notification } = this.props;
    if (isEmpty(prevProps.notification) && !isEmpty(notification)) {
      this.setState({ isOpen: true });
    }
  }
  onClose = () => {
    const { dispatch } = this.props;
    // clear message
    dispatch({ type: RESET_ERROR_MESSAGE });
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const { isOpen } = this.state;
    const {
      notification: { variant, message },
    } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isOpen}
        autoHideDuration={4000}
        onClose={this.onClose}
      >
        <StyledSnackbarContent
          onClose={this.onClose}
          variant={variant}
          message={message}
        />
      </Snackbar>
    );
  }
}

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
};

export default connect(state => ({
  notification: state.globalNotification,
}))(Notification);
