import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import ProjectSelect from 'components/ProjectSelect';
import DrawerList from 'components/shared/DrawerMenu';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { handleLogout } from 'state/ducks/session';
import withAuthentication from 'views/enhancers/withAuthentication';
import Dashboard from 'views/pages/Dashboard';
import Home from 'views/pages/Home';
import Login from 'views/pages/Login';
import Reports from 'views/pages/Reports';
import ReportsByCraft from 'views/pages/ReportsByCraft';
import WarrItemReports from 'views/pages/WarrItemReports';
import Sales from 'views/pages/Sales';
import SetupCrafts from 'views/pages/setup/Crafts';
import SetupModels from 'views/pages/setup/Models';
import SetupProducts from 'views/pages/setup/Products';
import SetupRooms from 'views/pages/setup/Rooms';
import SetupUnits from 'views/pages/setup/Units';
import Statistics from 'views/pages/Statistics';
import PdiUnitList from 'views/pages/PDIs';

const drawerWidth = 240;

const routes = [
  // TODO: *This is not a critical issue*  Uncomment this route when we decide to not set a active project by default
  // If this route is enabled then when the app first loads it will render this route
  // and then "ProjectSelect" will redirect the user to another route "/rd/:projId"
  // causing this component to be displayed for a split second
  // { path: '/', component: withAuthentication(Home), exact: true },
  { path: '/login', component: Login },
  {
    path: '/rd/:projId',
    exact: true,
    component: withAuthentication(Dashboard),
  },
  {
    path: '/rd/:projId/stats',
    component: withAuthentication(Statistics),
  },
  {
    path: '/rd/:projId/tarion',
    component: withAuthentication(PdiUnitList),
  },
  {
    path: '/rd/:projId/reports',
    component: withAuthentication(Reports),
  },
  {
    path: '/rd/:projId/reports-by-craft',
    component: withAuthentication(ReportsByCraft),
  },
  {
    path: '/rd/:projId/warranty-item-reports',
    component: withAuthentication(WarrItemReports),
  },
  {
    path: '/rd/:projId/setup-un',
    component: withAuthentication(SetupUnits),
  },
  {
    path: '/rd/:projId/setup-rooms',
    component: withAuthentication(SetupRooms),
  },
  {
    path: '/rd/:projId/setup-li',
    component: () => <div>TODO</div>,
  },
  {
    path: '/rd/:projId/setup-md',
    component: withAuthentication(SetupModels),
  },
  {
    path: '/rd/:projId/setup-crafts',
    component: withAuthentication(SetupCrafts),
  },
  {
    path: '/rd/:projId/setup-products',
    component: withAuthentication(SetupProducts),
  },

  {
    path: '/rd/:projId/sales',
    component: withAuthentication(Sales),
  },
];
const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#f5f5f5',
    height: '100%',
    overflow: 'auto',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('lg')]: {
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  appBarTitle: {
    [theme.breakpoints.up('lg')]: {
      width: 240,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    overflowX: 'hidden',
  },
});

/**
 * @class
 * @extends {Component}
 * Renders the header, drawer, and routes
 */
class MainLayout extends Component {
  state = {
    mobileOpen: false,
    anchorEl: null,
    fetched: false,
  };

  handleDrawerOpen = () => {
    this.setState({ mobileOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ mobileOpen: false });
  };

  renderErrorMessage = () => {
    return (
      <div>
        <Alert severity="error">There are no projects</Alert>
      </div>
    );
  };

  handleMenu = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleLogout = () => {
    const { handleLogout } = this.props;
    handleLogout();
  };

  renderAuthHeader = () => {
    const { anchorEl, mobileOpen } = this.state;
    const { authentication } = this.props;
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        width="100%"
        ml={5}
      >
        <ProjectSelect />
        <Box ml="auto">
          <Typography variant="h6" color="inherit" noWrap>
            Hello {authentication.user.userid}
          </Typography>
        </Box>
        <IconButton
          aria-owns={mobileOpen ? 'material-appbar' : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={anchorEl !== null}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    );
  };

  renderDrawer = () => {
    const { classes, activeProject } = this.props;
    const { mobileOpen } = this.state;
    if (activeProject === null) return null;

    return (
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={mobileOpen}
            onClose={this.handleDrawerClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div className={classes.toolbar} />
            <DrawerList />
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <div className={classes.toolbar} />
            <DrawerList />
          </Drawer>
        </Hidden>
      </nav>
    );
  };

  render() {
    const { classes, authentication, activeProject } = this.props;
    const { mobileOpen } = this.state;

    const hasProject = activeProject !== null;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames({
            [classes.appBar]: hasProject,
            [classes.appBarShift]: mobileOpen,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.appBarTitle} variant="h6">
              Crossroads
            </Typography>
            {authentication.isAuthenticated && this.renderAuthHeader()}
          </Toolbar>
        </AppBar>

        {authentication.isAuthenticated && this.renderDrawer()}

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/">
              {!authentication.isAuthenticated && <Redirect to="/login" />}
            </Route>

            {routes.map(route => {
              return (
                <Route
                  key={route.path}
                  exact={route.exact ? true : false}
                  path={route.path}
                  render={props => <route.component {...props} />}
                />
              );
            })}
          </Switch>
        </main>
      </div>
    );
  }
}

MainLayout.propTypes = {
  authentication: PropTypes.object,
  activeProject: PropTypes.object,
  handleLogout: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const matchStateToProps = state => ({
  authentication: state.authentication,
  activeProject: state.rd.projects.byId[state.rd.projects.selected],
});

// withStyles is a Higher Order Component from MaterialUi that is used to pass classes to the component it wraps
export default withStyles(styles, { withTheme: true })(
  connect(matchStateToProps, { handleLogout })(MainLayout)
);
