import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  MenuItem,
  TextField,
  Button,
  IconButton,
  Tooltip,
  List,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { setNotification } from 'state/ducks/globalNotification';
import { TextField as TextFieldFmk } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import isNil from 'lodash/isNil';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteButton from 'components/shared/DeleteButton';
import cn from 'classnames';
import lexSort from 'utils/lexSort';

const useStyles = makeStyles(theme => ({
  baseSelect: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginLeft: 100,
  },

  formInput: {
    width: '100%',
    maxWidth: 135,
    marginRight: 5,
  },
  numInput: {
    maxWidth: 100,
  },
}));

const UpgradeFormContainer = ({
  upgrades,
  selections,
  sale,
  activeTab,
  updateSelections,
  fetchRequiredSelections,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = async (modelprodroom, values) => {
    try {
      const selection = {
        sale: sale.id,
        modelprodroom: modelprodroom.id,
        price: values.customprice,
      };

      if (values.productstyle) {
        selection.prodstyle = values.productstyle;
      }

      const res = await axios.post('/api/v2/selection', {
        ...selection,
      });

      dispatch(setNotification('success', 'Added!'));
      // the general tab doesn't have any exclusivity groups so we can add multiple selections
      if (activeTab === '')
        updateSelections([...selections, res.data.selection]);
      // for other tabs, there can only be one selection so we update the selections array with only the newly added selection
      else updateSelections([res.data.selection]);

      // update the required selections
      fetchRequiredSelections();
    } catch (error) {
      dispatch(
        setNotification('error', error.message || 'Something went wrong')
      );
    }
  };

  const handleDelete = async values => {
    try {
      if (!window.confirm('Are you sure?')) {
        return;
      }

      await axios.delete(`/api/v2/selection/${values.id}`);
      dispatch(setNotification('success', 'Deleted!'));
      // If activeTab is an empty string it is the "GENERAL" tab
      // the general tab can have multiple selections so we just filter out the deleted one
      if (activeTab === '') {
        const filter = selections.filter(sel => sel.id !== values.id);
        updateSelections(filter);
        // other tabs can only have one selection, so we clear the selections array when a selection is deleted
      } else updateSelections([]);

      // update required selections
      fetchRequiredSelections();
    } catch (error) {
      dispatch(
        setNotification('error', error.message || 'Something went wrong')
      );
    }
  };

  const handleSave = async (modelprodroom, values) => {
    try {
      const selection = {
        sale: sale.id,
        modelprodroom: modelprodroom.id,
        price: values.customprice,
      };

      if (values.productstyle) {
        selection.prodstyle = values.productstyle;
      }

      const res = await axios.put(`/api/v2/selection/${values.id}`, {
        ...selection,
      });

      // There can be multiple selections for products without an exclusion group. So when
      // updating the selections array we pass the other selections
      // We replace the updated selection with the put request's response
      if (activeTab === '') {
        const temp = selections.filter(s => s.id !== selection.id);
        updateSelections([...temp, res.data.selection]);
        // There is only one selection for other exclusion groups, so we don't need to pass selections
      } else updateSelections([res.data.selection]);

      dispatch(setNotification('success', 'Saved!'));
    } catch (error) {
      dispatch(
        setNotification('error', error.message || 'Something went wrong')
      );
    }
  };

  // check for single base product with no styles
  return (
    <div>
      <List>
        {upgrades
          .sort((a, b) => {
            if (a.product.prodtype < b.product.prodtype) {
              return -1;
            }
            if (a.product.prodtype > b.product.prodtype) {
              return 1;
            }

            if (a.product.name < b.product.name) {
              return -1;
            }
            if (a.product.name > b.product.name) {
              return 1;
            }
            return 0;
          })
          .map(upg => {
            const selection = selections.find(s => upg.id === s.modelprodroom);
            return (
              <ListItem
                key={upg.id}
                disableGutters
                selected={!isNil(selection)}
              >
                <UpgradeForm
                  item={upg}
                  initialValues={{
                    productstyle: selection ? selection.prodstyle : '',
                    customprice: selection ? selection.price : upg.price,
                    id: selection ? selection.id : '',
                  }}
                  locked={sale.locksel}
                  onDelete={handleDelete}
                  prodStyles={upg.product.prodstyles}
                  onSubmit={isNil(selection) ? handleSubmit : handleSave}
                  isSelected={isNil(selection) ? false : true}
                  isExclusive={activeTab !== ''}
                  hasExistingSelection={selections.length !== 0}
                />
              </ListItem>
            );
          })}
      </List>
    </div>
  );
};

UpgradeFormContainer.propTypes = {
  upgrades: PropTypes.array.isRequired,
  selections: PropTypes.array.isRequired,
  sale: PropTypes.object.isRequired,
  updateSelections: PropTypes.func,
  activeTab: PropTypes.any,
  fetchRequiredSelections: PropTypes.func.isRequired,
};

const UpgradeForm = ({
  item,
  onSubmit,
  onDelete,
  initialValues,
  prodStyles,
  isSelected,
  isExclusive,
  hasExistingSelection,
  locked,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { projId } = useParams();
  const isBase = item.product.prodtype === 'Base';
  const isUpgrade = item.product.prodtype === 'Upgrade';
  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validate={values => {
          const errors = {};

          if (prodStyles.length && !values.productstyle) {
            errors.productstyle = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(item, values);
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({ dirty, values }) => (
          <Form style={{ width: '100%' }}>
            <Prompt
              when={dirty}
              message="You have unsaved changes. Are you sure you want to leave?"
            />

            <Grid container justify="center" alignItems="center" spacing={1}>
              <Grid item xs={3}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Tooltip arrow title="Edit product">
                    <IconButton
                      style={{ marginRight: 5 }}
                      aria-label="delete"
                      onClick={() =>
                        history.push(
                          `/rd/${projId}/setup-products/edit/${item.product.id}`
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>

                  <Typography>{item.product.name}</Typography>
                </div>
              </Grid>
              <Grid item xs={1}>
                {isBase && <Chip label="Base" />}
                {isUpgrade && (
                  <Chip
                    label="Upgrade"
                    style={{
                      backgroundColor: '#FFA500',
                      color: 'white',
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={5}>
                {prodStyles && prodStyles.length ? (
                  <Field
                    component={TextFieldFmk}
                    type="text"
                    select
                    label="Style/Colour"
                    name="productstyle"
                    className={classes.formInput}
                    margin="dense"
                    variant="outlined"
                    disabled={
                      locked ||
                      (isExclusive && hasExistingSelection && !isSelected)
                    }
                  >
                    {prodStyles
                      .sort((a, b) => lexSort(a.style, b.style))
                      .map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.style}
                        </MenuItem>
                      ))}
                  </Field>
                ) : (
                  <TextField
                    label="Style / Colour"
                    margin="dense"
                    variant="outlined"
                    className={classes.formInput}
                    value="Base"
                    disabled
                  />
                )}
                {!isBase && (
                  <>
                    <TextField
                      label="Price"
                      margin="dense"
                      variant="outlined"
                      className={cn(classes.formInput, classes.numInput)}
                      value={item.price}
                      disabled
                    />
                    <Field
                      disabled={
                        locked ||
                        (isExclusive && hasExistingSelection && !isSelected)
                      }
                      name="customprice"
                      placeholder="Custom price"
                      label="Custom Price"
                      type="number"
                      margin="dense"
                      inputProps={{
                        step: 0.01,
                      }}
                      variant="outlined"
                      className={cn(classes.formInput, classes.numInput)}
                      component={TextFieldFmk}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={3} md={3}>
                {!locked && !isSelected && (
                  <Button
                    disabled={isExclusive && hasExistingSelection}
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="small"
                  >
                    Add
                  </Button>
                )}
                {!locked && isSelected && (
                  <>
                    <Button
                      disabled={!dirty}
                      color="primary"
                      variant="contained"
                      style={{ marginRight: 5 }}
                      type="submit"
                      size="small"
                    >
                      Save Changes
                    </Button>
                    <DeleteButton
                      type="button"
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => onDelete(values)}
                    >
                      Delete
                    </DeleteButton>
                  </>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

UpgradeForm.propTypes = {
  item: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  prodStyles: PropTypes.array.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  hasExistingSelection: PropTypes.bool,
  isExclusive: PropTypes.bool,
  locked: PropTypes.bool.isRequired,
};

export default UpgradeFormContainer;
