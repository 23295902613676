import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const PrevAndNext = ({ prevAndNext, onGoToNext }) => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        marginBottom: 30,
        marginLeft: 15,
      }}
    >
      <Button
        onClick={() => onGoToNext(prevAndNext.prev_id)}
        variant="contained"
        style={{ marginRight: 20 }}
        disabled={!prevAndNext || !prevAndNext.prev_id}
      >
        Prev
      </Button>
      <Button
        disabled={!prevAndNext || !prevAndNext.next_id}
        onClick={() => onGoToNext(prevAndNext.next_id)}
        variant="contained"
      >
        Next
      </Button>
    </div>
  );
};

PrevAndNext.propTypes = {
  prevAndNext: PropTypes.object.isRequired,
  onGoToNext: PropTypes.func.isRequired,
};

export default PrevAndNext;
