import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';

const ProductStats = () => {
  const [selectionStats, setSelectionStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { projId } = useParams();
  useEffect(() => {
    const fetchSelectionStats = async () => {
      try {
        const res = await axios.get(`/api/v2/selections/stats/${projId}`);
        setSelectionStats(res.data.selections);
      } catch (error) {
        alert('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSelectionStats();
  }, []);
  return (
    <>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <MaterialTable
          title="Product Selections"
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'Count', field: 'count' },
          ]}
          options={{
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: 600,
            minBodyHeight: 500,
            pageSize: 10,
          }}
          data={selectionStats}
          detailPanel={[
            {
              tooltip: 'Show product styles',
              render: function renderRow(rowData) {
                return <ProductStylesStats productId={rowData.product_id} />;
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default ProductStats;

const ProductStylesStats = ({ productId }) => {
  const { projId } = useParams();
  const [styleSelectionStats, setStyleSelectionStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchStyleSelectionStats = async () => {
      try {
        const res = await axios.get(
          `/api/v2/selections/stats/${projId}/${productId}`
        );
        setStyleSelectionStats(res.data.prodstyles);
      } catch (error) {
        alert('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };

    fetchStyleSelectionStats();
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress size={30} />
      </Box>
    );
  }

  if (styleSelectionStats.length === 0) {
    return (
      <Typography variant="subtitle2" style={{ padding: 10, paddingLeft: 80 }}>
        No styles
      </Typography>
    );
  }

  return (
    <List dense>
      {styleSelectionStats.map(d => (
        <ListItem key={d.style}>
          <ListItemText>
            <Box display="flex" px={1}>
              <Typography variant="subtitle2" style={{ paddingLeft: 80 }}>
                {d.style}
              </Typography>
              <Typography variant="subtitle2" style={{ marginLeft: 'auto' }}>
                {d.count}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

ProductStylesStats.propTypes = {
  productId: PropTypes.number.isRequired,
};
