import React, { useState, useEffect } from 'react';
import { editModel, fetchModel } from 'state/ducks/residential/models';
import { useDispatch, useSelector } from 'react-redux';
import ModelForm from './ModelForm';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';

const EditModelForm = () => {
  const dispatch = useDispatch();

  const { projId, modelId } = useParams();
  const model = useSelector(state => state.rd.models.byId[modelId]);

  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    if (model) {
      setFormValues({
        model: model.model,
        id: model.id,
        area: model.area,
        bedrooms: model.bedrooms,
        bathrooms: model.bathrooms,
        baseprice: model.baseprice,
        name: model.name,
        elev: model.elev,
        project: projId,
      });
    } else {
      dispatch(fetchModel(projId, modelId));
    }
  }, [model, projId, dispatch, modelId]);

  const handleEdit = values => {
    dispatch(editModel(values));
  };

  if (!formValues) {
    return null;
  }

  return (
    <Box p={4}>
      <ModelForm
        initialValues={formValues}
        onSubmit={handleEdit}
        title="Edit Model"
        buttonText="Save"
      />
    </Box>
  );
};

export default EditModelForm;
