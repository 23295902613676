import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const DeleteButton = styled(Button)({
  backgroundColor: '#EA3C53',
  color: 'white',
  '&:hover': {
    background: '#8D021F',
  },
});

export default DeleteButton;
