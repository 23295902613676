import axios from 'axios';

import produce from 'immer';
export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAIL = 'FETCH_PROJECTS_FAIL';
export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';

import { getIds, getObject } from 'utils';
const initialState = {
  byId: {},
  allIds: [],
  isLoading: true,
  error: null,
  selected: null,
};

export default function(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_PROJECTS_REQUEST:
        draft.isLoading = true;
        break;

      case FETCH_PROJECTS_SUCCESS:
        draft.isLoading = false;
        draft.byId = getObject(action.projects, 'id');
        draft.allIds = getIds(action.projects, 'id');
        draft.total = action.total;
        break;

      case FETCH_PROJECTS_FAIL:
        draft.isLoading = false;
        draft.error = action.error;
        break;

      case SET_ACTIVE_PROJECT:
        draft.selected = action.id;
        break;

      default:
        return state;
    }
  });
}

export const fetchProjects = () => async dispatch => {
  try {
    dispatch({ type: FETCH_PROJECTS_REQUEST });
    const response = await axios.get('/api/Projects?active=true');

    dispatch({
      type: FETCH_PROJECTS_SUCCESS,
      projects: response.data.project,
    });
  } catch (err) {
    dispatch({ type: FETCH_PROJECTS_FAIL, error: err });
  }
};

export const setActiveProject = id => dispatch => {
  dispatch({
    type: SET_ACTIVE_PROJECT,
    id,
  });
};
