import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from 'components/shared/AppBar';
import Box from '@material-ui/core/Box';
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
} from 'react-router-dom';
import { fetchSale, resetActiveSale } from 'state/ducks/residential/activeSale';
import AddressTable from 'components/sales/AddressTable';
import AddEditAddress from 'components/sales/AddEditAddress';
import LookupForm from 'components/sales/LookupForm';

import DepositsTable from 'components/sales/DepositsTable';
import NotesTable from 'components/sales/NotesTable';
import AddEditNote from 'components/sales/AddEditNote';
import AddEditDeposit from 'components/sales/AddEditDeposit';
import SaleForm from 'components/sales/SaleForm';
import Upgrades from 'components/sales/Upgrades';
import Documents from 'components/sales/Documents';
import { getActiveSale } from 'state/selectors';
import axios from 'axios';
import PrevAndNext from 'components/shared/PrevAndNext';

const RenderPrevAndNext = () => {
  const [prevAndNext, setPrevAndNext] = useState(null);
  const { projId, saleId } = useParams();
  const history = useHistory();
  useEffect(() => {
    const fetchPrevNext = async () => {
      try {
        const res = await axios.get(`/api/v2/sale/${saleId}/prevnext`);
        setPrevAndNext(res.data);
      } catch (error) {
        alert('Something went wrong');
      }
    };

    fetchPrevNext();
  }, [saleId]);

  const goToNextSale = saleId =>
    history.push(`/rd/${projId}/sales/edit/${saleId}`);

  if (prevAndNext === null) return null;

  return (
    <PrevAndNext
      onGoToNext={goToNextSale}
      prevAndNext={{
        next_id: prevAndNext.next_sale_id,
        prev_id: prevAndNext.prev_sale_id,
      }}
    />
  );
};

class Sale extends Component {
  componentDidMount() {
    const { match, fetchSale } = this.props;
    const projId = match.params.projId;
    const saleId = match.params.saleId;
    fetchSale(projId, saleId);
  }

  componentDidUpdate(prevProps) {
    const { location, match } = this.props;
    if (
      (prevProps.location.pathname !== location.pathname &&
        location.pathname.includes('/summary')) ||
      prevProps.match.params.saleId !== match.params.saleId
    ) {
      const {
        match: {
          params: { projId, saleId },
        },
        fetchSale,
      } = this.props;
      fetchSale(projId, saleId);
    }
  }

  componentWillUnmount() {
    const { resetActiveSale } = this.props;
    resetActiveSale();
  }

  handleChange = (event, activeTabIndex) => {
    const { match, history } = this.props;
    const baseRoute = `${match.url}/${activeTabIndex}`;
    history.push(baseRoute);
  };

  getActiveIndex = pathname => {
    let activeTabIndex;
    if (pathname.includes('/addresses')) {
      activeTabIndex = 'addresses';
    } else if (pathname.includes('/deposits')) {
      activeTabIndex = 'deposits';
    } else if (pathname.includes('/summary')) {
      activeTabIndex = 'summary';
    } else if (pathname.includes('/notes')) {
      activeTabIndex = 'notes';
    } else if (pathname.includes('/documents')) {
      activeTabIndex = 'documents';
    } else {
      activeTabIndex = 'upgrades';
    }
    return activeTabIndex;
  };

  render() {
    const {
      unitno,
      location: { pathname },
      match: { path },
    } = this.props;

    const activeTabIndex = this.getActiveIndex(pathname);
    const tabs = [
      { label: 'Selections', value: 'upgrades' },
      { label: 'Documents', value: 'documents' },
      { label: 'Deposits / Payments', value: 'deposits' },
      { label: 'Purchasers and Legal', value: 'addresses' },
      { label: 'Summary', value: 'summary' },
      { label: 'Notes', value: 'notes' },
    ];
    return (
      <Box display="flex" flexDirection="column" height="100%">
        <AppBar
          onChange={this.handleChange}
          activeTabIndex={activeTabIndex}
          title={`Sale for Unit ${unitno}`}
          tabs={tabs}
        />

        <Box p={2}>
          <Switch>
            {/* Fixes redirecting backwards bug. Sometimes, when we go back (to the previous url), an extra slash is appended.
                This Redirect fixes that, by removing it
            */}
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <Route path={`${path}/documents`} component={Documents} />
            <Route path={`${path}/deposits/add`} component={AddEditDeposit} />
            <Route
              path={`${path}/deposits/edit/:id`}
              component={AddEditDeposit}
            />
            <Route path={`${path}/deposits`} component={DepositsTable} />
            <Route
              path={`${path}/addresses/edit/:saleAddrId`}
              component={AddEditAddress}
            />
            <Route path={`${path}/addresses/add`} component={AddEditAddress} />
            <Route path={`${path}/addresses/lookup`} component={LookupForm} />
            <Route path={`${path}/addresses`} component={AddressTable} />
            <Route path={`${path}/summary`} component={SaleForm} />
            <Route path={`${path}/notes/add`} component={AddEditNote} />
            <Route path={`${path}/notes/edit/:id`} component={AddEditNote} />
            <Route path={`${path}/notes`} component={NotesTable} />
            <Route path={path} component={Upgrades} />
          </Switch>
        </Box>
        <RenderPrevAndNext />
      </Box>
    );
  }
}

Sale.propTypes = {
  unitno: PropTypes.string.isRequired,
  fetchSale: PropTypes.func.isRequired,
  resetActiveSale: PropTypes.func.isRequired,
  match: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const sale = getActiveSale(state);
  return {
    unitno: sale.unit.id ? sale.unit.unitno : '',
  };
};

const mapDispatchToProps = {
  fetchSale,
  resetActiveSale,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sale);
