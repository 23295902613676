import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export const getCrumbsFromRoutes = (props, routes) => {
  return (
    routes
      // Get all routes that contain the current one.
      .filter(({ path }) => props.match.path.includes(path))
      // Swap out any dynamic routes with their param values.
      // E.g. "/pizza/:pizzaId" will become "/pizza/1"
      .map(({ path, ...rest }) => ({
        path: Object.keys(props.match.params).length
          ? Object.keys(props.match.params).reduce(
              (path, param) =>
                path.replace(`:${param}`, props.match.params[param]),
              path
            )
          : path,
        ...rest,
      }))
  );
};

export default function CustomSeparator({ crumbs }) {
  const classes = useStyles();
  const history = useHistory();
  const handleClick = path => {
    history.push(path);
  };
  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {crumbs.map(({ name, path }, key) =>
          key + 1 === crumbs.length ? (
            <Typography color="textPrimary" key={key}>
              {name}
            </Typography>
          ) : (
            <Link key={key} onClick={() => handleClick(path)}>
              {name}
            </Link>
          )
        )}
      </Breadcrumbs>
    </div>
  );
}

CustomSeparator.propTypes = {
  crumbs: PropTypes.array.isRequired,
};
