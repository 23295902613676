import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import condo_jpg from 'assets/toronto-condo-400.jpg';

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
}));

const Home = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.toolbar} />
      <Box display="flex" justifyContent="center">
        <div className={classes.toolbar} />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{ padding: 20, width: 600 }}
        >
          <h1 className="display-4">Residential Developer Software</h1>
          <p className="lead">
            A hosted web-based sales software to maximize revenue.
          </p>
          <Divider />
          <p>
            Smart tools for precise control, a streamlined sales process and an
            unequaled advantage: dynamic pricing.
          </p>
          <Button variant="outlined" color="primary" style={{ margin: 10 }}>
            Learn more
          </Button>
          <Divider />
          <div>
            <img
              src={condo_jpg}
              style={{ borderRadius: 5, marginTop: 20 }}
              alt=""
            />
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Home;
