// The URL where the Point of Sale app will send the transaction results.
//const callbackUrl = `${process.env.REACT_APP_HOST_NAME}/api/square/callback`;
const callbackUrl = process.env.REACT_APP_HOST_NAME + '/api/square/callback';

// Your application ID
// const applicationId = 'sq0idp-UMM_ePOlSdl0LPDxfGn4Xg';
// 'sq0idp-9Jxk64yKiQOStIcTZqSzFg';

// Production app id for Jim's square account
const applicationId = process.env.REACT_APP_SQUARE_APP_ID;

// The total and currency code should come from your transaction flow.
// For now, we are hardcoding them.
const currencyCode = 'CAD';

// The version of the Point of Sale API that you are using.
const apiVersion = '1.3';

export { callbackUrl, applicationId, currencyCode, apiVersion };
