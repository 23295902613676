import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Select from 'react-select';
import { fetchUnits, clearUnits } from 'state/ducks/residential/units';
import { addSale } from 'state/ducks/residential/sales';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  select: {
    width: 350,
    marginTop: 5,
  },
  modelInfo: {
    width: 350,
  },
});

const AddSale = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const units = useSelector(state => state.rd.units);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState('');
  const { projId } = useParams();
  const [unit, setUnit] = useState(null);

  useEffect(() => {
    dispatch(
      fetchUnits(projId, {
        unitstatus: 'Available',
      })
    );

    return () => {
      dispatch(clearUnits());
    };
  }, [dispatch, projId]);

  useEffect(() => {
    if (units.allIds.length) {
      const temp = units.allIds.map(id => {
        const unit = units.byId[id];
        return {
          label: unit.unitno,
          value: unit.id,
        };
      });

      setOptions(temp);
      if (temp.length > 0) {
        setSelected(temp[0]);
      }
    }
  }, [units.allIds, units.byId]);

  useEffect(() => {
    if (selected) {
      setUnit(units.byId[selected.value]);
    }
  }, [selected]);

  const handleUnitChange = async selected => {
    setSelected(selected);
  };

  const handleAddSale = async () => {
    const res = await dispatch(
      addSale({
        unit: unit.id,
      })
    );
    if (res) {
      history.goBack();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={4}
    >
      <div>
        <Typography>Available Units</Typography>
        <Select
          className={classes.select}
          onChange={handleUnitChange}
          options={options}
          value={selected}
          placeholder="Select a unit"
        />
      </div>

      {unit && (
        <Box mt={3} className={classes.modelInfo}>
          <Typography component="div">
            <Box fontWeight="fontWeightMedium" mt={2}>
              <label style={{ marginRight: 5 }}>Unit Num:</label>
              {unit.unitno}
            </Box>
            <Box fontWeight="fontWeightMedium" mt={2}>
              <label style={{ marginRight: 5 }}>Model:</label>
              {unit.model.model}
            </Box>
            <Box fontWeight="fontWeightMedium" mt={2}>
              <label style={{ marginRight: 5 }}>Status:</label>
              {unit.unitstatus}
            </Box>
            <Box fontWeight="fontWeightMedium" mt={2}>
              <label style={{ marginRight: 5 }}>Base price:</label>
              {unit.baseprice}
            </Box>
          </Typography>
        </Box>
      )}
      <Box mt={3}>
        <Button variant="contained" onClick={handleAddSale} color="primary">
          Create
        </Button>
        <Button
          style={{
            marginLeft: 30,
          }}
          variant="contained"
          onClick={() => history.goBack()}
          color="secondary"
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default AddSale;
