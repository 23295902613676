import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const BaseList = ({ className, data, showDelete, onDelete }) => {
  const classes = useStyles();

  return (
    <List className={classnames(classes.root, className)}>
      {data.map(item => {
        return (
          <ListItem key={item.id}>
            <ListItemText
              primary={item.text}
              secondary={item.secondaryText ? item.secondaryText : null}
            />
            {showDelete ? (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onDelete(item.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        );
      })}
    </List>
  );
};

BaseList.propTypes = {
  data: PropTypes.array.isRequired,
  showDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  className: PropTypes.object,
};

BaseList.defaultProps = {
  onDelete: () => ({}),
  showDelete: false,
};

export default BaseList;
