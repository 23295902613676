import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import UpgradesTable from './UpgradesTable';
import CurrentSelections from './CurrentSelections';
import ModelMap from './ModelMap';
import {
  useQueryParam,
  useQueryParams,
  NumberParam,
  StringParam,
} from 'use-query-params';

const useStyles = makeStyles(theme => ({
  btnSelGroup2: {
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
    },
  },
}));

const Upgrades = () => {
  /**
   * Calls ModelRooms api to get a list of rooms that have upgrades. The rooms (exlcuding the "model wide" room that has a room_id of 1) are displayed in a select dropdown.
   * Renders buttons to view "room" or "model wide" upgrades, a button to view the floor layout of the condo.
   */
  const dispatch = useDispatch();
  const sale = useSelector(state => state.rd.activeSale.sale);
  const { projId } = useParams();

  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isModelWide, setIsModelWide] = useState(false);
  const [modelRooms, setModelRooms] = useState([]);
  const [requiredSelections, setRequiredSelections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [modelroomQuery, setModelroomQuery] = useQueryParam(
    'modelroom',
    NumberParam
  );

  useEffect(() => {
    // when the model room query param is set, we will set the selected room in this component's state
    if (modelroomQuery && modelRooms.length) {
      const tmp = modelRooms.find(r => r.id === parseInt(modelroomQuery));
      if (tmp) {
        setSelectedRoom(tmp);
        if (tmp.room.id === 1) {
          setIsModelWide(true);
        } else setIsModelWide(false);
      }
    }
  }, [modelroomQuery, modelRooms]);

  useEffect(() => {
    // Model rooms (excluding model wide room) will be displayed in the select component
    const fetchModelRooms = async () => {
      try {
        setIsLoading(true);
        const url = `/api/ModelRooms?project_id=${projId}&model_id=${sale.unit.model.id}&limit=1000&rooms_with_products=true`;
        const res = await axios.get(url);

        const modelRoomsTmp = res.data.modelroom.sort((a, b) => {
          // Ensure the entire suite (model wide) room comes first
          if (a.room.id === 1) return -1;
          if (b.room.id === 1) return 1;

          // sort alphabetically
          if (a.room.name < b.room.name) {
            return -1;
          }
          if (a.room.name > b.room.name) {
            return 1;
          }
          return 0;
        });

        setModelRooms(modelRoomsTmp);

        if (modelRoomsTmp.length) {
          if (modelroomQuery) {
            const room = modelRoomsTmp.find(mr => mr.id === modelroomQuery);
            if (room) {
              setModelroomQuery(room.id, 'replaceIn');
            }
          } else setModelroomQuery(modelRoomsTmp[0].id, 'replaceIn');
        }
      } catch (error) {
        dispatch({
          type: '',
          globalNotification: {
            variant: 'error',
            message: error.message || 'Something went wrong',
          },
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (sale.unit.id) {
      fetchModelRooms();
    }
  }, [sale.id, sale.unit.id, projId, dispatch]);

  const handleFetchRequiredSelections = async () => {
    try {
      const res = await axios.get(`/api/v2/sale/${sale.id}/required-selection`);
      setRequiredSelections(res.data.required);
    } catch (error) {
      dispatch({
        type: '',
        globalNotification: {
          variant: 'error',
          message: error.message || 'Something went wrong',
        },
      });
    }
  };

  if (!sale.id) {
    return null;
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          {/* renders the buttons, room drop down and model layout image */}
          <SelectionButtonGroup
            sale={sale}
            rooms={modelRooms}
            requiredSelections={requiredSelections}
            onFetchRequiredSelections={handleFetchRequiredSelections}
            selectedRoom={selectedRoom}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {/* renders the upgrades table */}
        {selectedRoom && (
          <UpgradesTable
            sale={sale}
            isModelWide={isModelWide}
            selectedRoom={selectedRoom}
            fetchRequiredSelections={handleFetchRequiredSelections}
          />
        )}
        {!selectedRoom && (
          <Typography>
            {isLoading
              ? null
              : isModelWide
              ? 'There are no model wide upgrades for this model'
              : 'There are no room upgrades for this model'}
          </Typography>
        )}
        {isLoading && <CircularProgress />}
      </Grid>
    </Grid>
  );
};

Upgrades.propTypes = {};

export default Upgrades;

const RequiredSelections = ({ id, anchorEl, requiredSelections, open, closeMe }) => {
  return (
    <Popper
      placement="bottom"
      id={id}
      open={open}
      anchorEl={anchorEl}
      style={{ zIndex: 20 }}
      onClick={closeMe}
    >
      <Paper elevation={3}>
        {requiredSelections.length === 0 && (
          <div style={{ padding: 10 }}>
            <Typography>You have made all the required selections!</Typography>
          </div>
        )}
        {requiredSelections.length > 0 && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Room</TableCell>
                  <TableCell>Exclusivity Group</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requiredSelections.map(sel => {
                  return (
                    <TableRow key={sel.exclgrp + sel.room.id}>
                      <TableCell>
                        {sel.room.name === 'Entire Suite'
                          ? 'Model wide'
                          : sel.room.name}
                      </TableCell>
                      <TableCell>
                        {sel.exclgrp === '' ? 'General' : sel.grpname}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Popper>
  );
};

RequiredSelections.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.object,
  requiredSelections: PropTypes.array,
  open: PropTypes.bool.isRequired,
};

const SelectionButtonGroup = ({
  sale,
  rooms,
  requiredSelections,
  selectedRoom,
  onFetchRequiredSelections,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showLayout, setShowLayout] = useState(false);
  const [showSelections, setShowSelections] = useState(false);

  const [query, setQuery] = useQueryParams({
    modelroom: NumberParam,
    tab: StringParam,
  });
  const handleSelectChange = modelroom => {
    // don't update query if it's the same value
    if (modelroom !== query.modelroom) {
      setQuery({
        modelroom,
        tab: undefined,
      });
    }
  };

  const toggleLayout = () => {
    setShowLayout(!showLayout);
  };

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSeeReqClick = async event => {
    handleClick(event);
    onFetchRequiredSelections();
  };

  const handleMapClick = area => {
    if (area.roomId !== -1) {
      setShowLayout(false);
      handleSelectChange(area.roomId);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} md={12} lg={6}>
        {rooms.map(mr => {
          const label = mr.room.id === 1 ? 'Model Wide' : mr.room.name;
          return (
            <Chip
              style={{ marginRight: 5, marginTop: 5 }}
              color={
                selectedRoom && selectedRoom.id === mr.id
                  ? 'primary'
                  : 'default'
              }
              onClick={() => handleSelectChange(mr.id)}
              key={mr.id}
              size="medium"
              label={label}
            />
          );
        })}
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Box display="flex" alignItems="right" className={classes.btnSelGroup2}>
          <ModelMap
            isOpen={showLayout}
            handleClose={toggleLayout}
            handleMapClick={handleMapClick}
            modelName={sale.unit.model.model}
            rooms={rooms}
          />
          {showSelections && (
            <CurrentSelections
              isOpen={showSelections}
              onClose={() => setShowSelections(false)}
            />
          )}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={toggleLayout}
          >
            Show Layout
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 'auto' }}
            aria-describedby={id}
            onClick={() => setShowSelections(true)}
            size="small"
          >
            See Selections
          </Button>
          <Button
            variant="outlined"
            color="primary"
            aria-describedby={id}
            onClick={handleSeeReqClick}
            size="small"
            style={{
              marginLeft: 20,
            }}
          >
            See Required Selections
          </Button>
          <RequiredSelections
            id={id}
            anchorEl={anchorEl}
            open={open}
            requiredSelections={requiredSelections}
            closeMe={handleSeeReqClick}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

SelectionButtonGroup.propTypes = {
  sale: PropTypes.object.isRequired,
  rooms: PropTypes.array.isRequired,
  requiredSelections: PropTypes.array.isRequired,
  selectedRoom: PropTypes.object,
  onFetchRequiredSelections: PropTypes.func.isRequired,
};
