import React from 'react';
import { addCraft } from 'state/ducks/residential/crafts';
import { useDispatch } from 'react-redux';
import CraftForm from './CraftForm';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';

const AddCraftForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAdd = async values => {
    const success = await dispatch(addCraft(values));
    if (success) {
      let path = history.location.pathname.split('/');
      path = path.slice(0, path.length - 1).join('/');
      history.push(path);
    }
  };

  return (
    <Box p={4}>
      <CraftForm onSubmit={handleAdd} title="Add Craft" />
    </Box>
  );
};

export default AddCraftForm;
