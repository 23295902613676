import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddBox from '@material-ui/icons/AddBox';
import { useTable, usePagination, useSortBy } from 'react-table';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
}));

const getInitialState = (query, initialState = {}, persist) => {
  if (!persist) {
    return initialState;
  }

  const persistedState = {};
  // These values are not set when the page (table) renders for the first time.
  // On subsequent page reloads, these values will exist in the url and will be used as the initial state of the table.
  if (query.sortBy !== undefined)
    persistedState.sortBy = JSON.parse(query.sortBy);

  if (query.pageIndex !== undefined) persistedState.pageIndex = query.pageIndex;

  if (query.pageSize !== undefined) persistedState.pageSize = query.pageSize;

  return {
    ...initialState,
    ...persistedState,
  };
};

const getHeaderGroup = headerGroup => (
  // eslint-disable-next-line
  <TableRow {...headerGroup.getHeaderGroupProps()}>
    {headerGroup.headers.map(column => (
      // eslint-disable-next-line
      <TableCell
        {...column.getHeaderProps(column.getSortByToggleProps())}
        {...column.getHeaderProps([{ className: column.className }])}
        sortDirection={
          column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : false
        }
      >
        <TableSortLabel
          active={column.isSorted}
          direction={
            !column.isSorted ? 'asc' : column.isSortedDesc ? 'desc' : 'asc'
          }
        >
          {column.render('Header')}
        </TableSortLabel>
      </TableCell>
    ))}
  </TableRow>
);

const renderRow = (prepareRow, row) => {
  prepareRow(row);
  return (
    // eslint-disable-next-line
    <TableRow {...row.getRowProps()}>
      {row.cells.map(cell => {
        return (
          // eslint-disable-next-line
          <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
        );
      })}
    </TableRow>
  );
};

function Table({
  columns,
  data,
  onAdd = null,
  title,
  options = {
    autoResetSortBy: false,
    initialState: {},
  },
  persist = false,
}) {
  const [query, setQuery] = useQueryParams({
    pageIndex: NumberParam,
    pageSize: NumberParam,
    sortBy: StringParam,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      ...options,
      initialState: getInitialState(query, options.initialState, persist),
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (persist) {
      setQuery({
        pageIndex: pageIndex,
        pageSize: pageSize,
        sortBy: JSON.stringify(sortBy),
      });
    }
  }, [persist, pageIndex, pageSize, sortBy]);

  const classes = useStyles();
  return (
    <Paper>
      <TableContainer>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {onAdd ? (
            <Tooltip title={'Create item'}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={onAdd}
                startIcon={<AddBox />}
              >
                Create
              </Button>
            </Tooltip>
          ) : null}
        </Toolbar>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups
              // prevent rendering first header row because it's empty
              .filter((_, idx) => idx !== 0)
              .map(getHeaderGroup)}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map(row => renderRow(prepareRow, row))}
          </TableBody>
        </MaUTable>
        <TablePagination
          component="div"
          count={data.length}
          page={pageIndex}
          onChangePage={(e, page) => gotoPage(page)}
          rowsPerPage={pageSize}
          onChangeRowsPerPage={e => setPageSize(Number(e.target.value))}
        />
      </TableContainer>
    </Paper>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  onAdd: PropTypes.func,
  title: PropTypes.string.isRequired,
  options: PropTypes.object,
  persist: PropTypes.bool,
};

export default Table;
