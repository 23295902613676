import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import {
  updateSaleAddressV2,
  createSaleAddrWithNewAddress,
  fetchSaleAddress,
  getActiveSaleSaleAddress,
  createSaleAddrWithExistingAddress,
} from 'state/ducks/residential/activeSale';
import axios from 'axios';
import { setNotification } from 'state/ducks/globalNotification';
import CircularProgress from '@material-ui/core/CircularProgress';
import qs from 'query-string';
import {
  deliveryMethods,
  addressTypes,
  initialFormValues,
  getValidationSchema,
} from './AddEditAddressConstants';

const useFetchCities = () => {
  const [cities, setCities] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const res = await axios.get('/api/Cities?limit=1000');

        const temp = res.data.city.map(c => {
          return {
            value: c.id,
            label: c.name,
          };
        });
        setCities(temp);
      } catch (error) {
        dispatch(setNotification('error', 'Something went wrong'));
      }
    };

    fetchCities();
  }, []);

  return { cities };
};

// There are two cases to handle
// Case 1: Creating a new sale address
// Case 1.1: creating a sale address with a new address
// Case 1.2: creating a sale address with an existing address
// Case 2: Editing a sale address
const AddEditAddress = () => {
  const dispatch = useDispatch();
  const { projId, saleId, saleAddrId } = useParams();
  const saleAddress = useSelector(getActiveSaleSaleAddress(saleAddrId));
  const isAddMode = !saleAddrId;
  // CASE 2: Edit Mode
  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchSaleAddress(projId, saleId, saleAddrId));
    }
  }, [projId, saleId, saleAddrId, isAddMode]);

  // fetch cities
  if (!isAddMode && !saleAddress) {
    return <CircularProgress />;
  }

  if (isAddMode) return <RenderAddForm />;

  return <RenderEditForm />;
};

const RenderEditForm = () => {
  const dispatch = useDispatch();
  const [showPrompt, setShowPrompt] = useState(false);
  const [initialValues, setInitialValues] = useState(initialFormValues);
  const { projId, saleId, saleAddrId } = useParams();
  const saleAddress = useSelector(getActiveSaleSaleAddress(saleAddrId));
  const history = useHistory();
  const { cities } = useFetchCities();
  // CASE 2: Edit Mode
  useEffect(() => {
    dispatch(fetchSaleAddress(projId, saleId, saleAddrId));
  }, [projId, saleId, saleAddrId]);

  useEffect(() => {
    if (saleAddress) {
      const address = saleAddress.address;
      const city = address.city;
      setInitialValues({
        addrtype: saleAddress.addrtype,
        given: address.given,
        surname: address.surname,
        name: address.name,
        mobilephone: address.mobilephone || '',
        busphone: address.busphone || '',
        homephone: address.homephone || '',
        email: address.email,
        postal: address.postal,
        street: address.street,
        suite: address.suite || '',
        city: city.id,
        delmeth: saleAddress.delmeth || '',
        person: address.person,
        primary: saleAddress.primary || false,
      });
    }
  }, [saleAddress]);

  const handleSubmit = async (values, onFailureCb) => {
    setShowPrompt(false);
    const { addrtype, delmeth, primary, ...rest } = values;

    const payload = {
      addrtype,
      primary,
      delmeth,
      address: {
        ...rest,
      },
    };

    const success = await dispatch(
      updateSaleAddressV2(saleId, saleAddress.id, payload)
    );

    if (success) history.push('.');
    else {
      dispatch(setNotification('error', 'Something went wrong'));
      onFailureCb();
    }
  };

  const validationSchema = getValidationSchema({
    isPerson: saleAddress.address.person,
  });

  return (
    <RenderForm
      showPrompt={showPrompt}
      setShowPrompt={setShowPrompt}
      isAddMode={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      isPerson={saleAddress.address.person}
      cities={cities}
      isPrimary={saleAddress.primary}
    />
  );
};

const RenderAddForm = () => {
  const { projId, saleId } = useParams();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(initialFormValues);
  const location = useLocation();
  const search = qs.parse(location.search);
  const [showPrompt, setShowPrompt] = useState(false);
  const { cities } = useFetchCities();
  const history = useHistory();
  // CASE 1: Add mode
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const res = await axios.get(`/api/Addresses?id=${search.address_id}`);
        const address = res.data.address[0];
        setInitialValues(old => ({
          ...old,
          given: address.given,
          surname: address.surname,
          name: address.name,
          mobilephone: address.mobilephone || '',
          busphone: address.busphone || '',
          homephone: address.homephone || '',
          email: address.email,
          postal: address.postal,
          street: address.street,
          suite: address.suite || '',
          city: address.city.id,
          person: address.person || false,
        }));
      } catch (error) {
        dispatch(setNotification('error', 'Something went wrong'));
      }
    };

    // Case 1.1:
    if (!search.address_id && search.is_person) {
      setInitialValues(curr => ({
        ...curr,
        person: search.is_person === 'true',
      }));
      // Case 1.2
    } else if (search.address_id) {
      fetchAddress(projId, search.address_id);
    }
  }, [search.is_person, search.address_id]);

  const handleSubmit = async (values, onFailureCb) => {
    setShowPrompt(false);
    const { addrtype, delmeth, primary, ...rest } = values;

    const payload = {
      addrtype,
      primary,
      delmeth,
      address: rest,
    };

    // CASE 1: create a sale address with a new address
    let success;
    if (search.address_id === undefined)
      success = await dispatch(createSaleAddrWithNewAddress(saleId, payload));
    // CASE 2: create a sale address with existing address
    else {
      payload.address.id = search.address_id;
      success = await dispatch(
        createSaleAddrWithExistingAddress(saleId, payload)
      );
    }
    if (success) return history.push('.');
    dispatch(setNotification('error', 'Something went wrong'));
    onFailureCb();
  };

  const validationSchema = getValidationSchema({
    isPerson: search.is_person === 'true',
  });
  return (
    <RenderForm
      showPrompt={showPrompt}
      setShowPrompt={setShowPrompt}
      isAddMode={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      isPerson={search.is_person === 'true'}
      cities={cities}
      isPrimary={false}
    />
  );
};

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    width: 550,
  },
  input: {
    width: 250,
  },
  formSelect: {
    width: '250px',
  },
  radio: {
    width: '100%',
    marginTop: 30,
  },
});

const RenderForm = ({
  showPrompt,
  setShowPrompt,
  isAddMode,
  initialValues,
  validationSchema,
  handleSubmit,
  isPerson,
  isPrimary,
  cities,
}) => {
  const classes = useStyles();

  const history = useHistory();
  const sanitizeValues = values => {
    const temp = {
      ...values,
    };
    const numbers = ['mobilephone', 'busphone', 'homephone'];
    numbers.forEach(num => {
      if (temp[num]) {
        temp[num] = temp[num].replace(/[^\d.-]/g, '');
      }
    });

    return temp;
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Prompt when={showPrompt} message="Are you sure you want to leave?" />
      <Typography variant="h6" align="center">
        {isAddMode ? 'Add Address' : 'Edit Address'}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(sanitizeValues(values), () => setSubmitting(false));
        }}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={classes.formContainer}>
              <FormControl className={classes.form}>
                {isPerson ? (
                  <>
                    <Field
                      name="given"
                      placeholder="First Name"
                      label="Given"
                      margin="dense"
                      variant="outlined"
                      component={TextField}
                      className={classes.input}
                      inputProps={{
                        onFocus: () => setShowPrompt(true),
                      }}
                    />
                    <Field
                      component={TextField}
                      label="Last Name"
                      name="surname"
                      margin="dense"
                      variant="outlined"
                      className={classes.input}
                      inputProps={{
                        onFocus: () => setShowPrompt(true),
                      }}
                    />
                  </>
                ) : (
                  <Field
                    component={TextField}
                    label="Name"
                    name="name"
                    margin="dense"
                    variant="outlined"
                    className={classes.input}
                    inputProps={{
                      onFocus: () => setShowPrompt(true),
                    }}
                  />
                )}
                <Field
                  component={TextField}
                  type="text"
                  select
                  label="Address Type"
                  name="addrtype"
                  className={classes.formSelect}
                  margin="dense"
                  variant="outlined"
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                >
                  {addressTypes.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  component={TextField}
                  type="text"
                  select
                  label="Delivery Method"
                  name="delmeth"
                  className={classes.formSelect}
                  margin="dense"
                  variant="outlined"
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                >
                  {deliveryMethods.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  component={TextField}
                  label="Mobile Phone"
                  name="mobilephone"
                  margin="dense"
                  className={classes.input}
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                  variant="outlined"
                />
                <Field
                  component={TextField}
                  label="Business Phone"
                  name="busphone"
                  margin="dense"
                  className={classes.input}
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                  variant="outlined"
                />
                <Field
                  component={TextField}
                  label="Home Phone"
                  name="homephone"
                  margin="dense"
                  className={classes.input}
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                  variant="outlined"
                />
                <Field
                  component={TextField}
                  label="Email"
                  name="email"
                  margin="dense"
                  className={classes.input}
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                  variant="outlined"
                />
                <Field
                  component={TextField}
                  type="text"
                  select
                  label="City"
                  name="city"
                  className={classes.formSelect}
                  margin="dense"
                  variant="outlined"
                >
                  {cities.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  component={TextField}
                  label="Street"
                  name="street"
                  margin="dense"
                  className={classes.input}
                  variant="outlined"
                />
                <Field
                  component={TextField}
                  label="Suite"
                  name="suite"
                  margin="dense"
                  className={classes.input}
                  variant="outlined"
                />
                <Field
                  component={TextField}
                  label="Postal"
                  name="postal"
                  margin="dense"
                  className={classes.input}
                  variant="outlined"
                />
                {(isAddMode || !isPrimary) && (
                  <div>
                    <Field
                      component={CheckboxWithLabel}
                      Label={{ label: 'Primary Purchaser' }}
                      name="primary"
                      color="primary"
                    />
                  </div>
                )}
                <Box display="flex" justifyContent="center" mt={3} width="100%">
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 10 }}
                    className={classes.submitButton}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isAddMode ? 'Add' : 'Save'}
                  </Button>
                  <Button
                    style={{ marginRight: 10 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push(isAddMode ? '.' : '..')}
                  >
                    Cancel
                  </Button>
                </Box>
              </FormControl>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

RenderForm.propTypes = {
  showPrompt: PropTypes.bool.isRequired,
  setShowPrompt: PropTypes.func.isRequired,
  isAddMode: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  isPerson: PropTypes.bool.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  cities: PropTypes.array.isRequired,
};

export default AddEditAddress;
