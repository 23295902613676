import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';

const TaskOptions = ({ options, onStart, disabled }) => {
  const [value, setValue] = useState(null);

  const handleChange = selected => {
    const { value, label } = selected;
    setValue({
      value: value,
      label: label,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Select
          onChange={handleChange}
          options={options}
          value={value}
          placeholder="Select a floor"
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => onStart(value)}
          disabled={disabled}
        >
          Generate
        </Button>
      </Grid>
    </Grid>
  );
};

TaskOptions.propTypes = {
  options: PropTypes.array,
  onStart: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default TaskOptions;
