import axios from 'axios';
import produce from 'immer';
import { getIds, getObject } from 'utils';

export const FETCH_NOTES_REQUEST = 'FETCH_NOTES_REQUEST';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';
export const FETCH_NOTES_FAILURE = 'FETCH_NOTES_FAILURE';

export const FETCH_NOTE_REQUEST = 'FETCH_NOTE_REQUEST';
export const FETCH_NOTE_SUCCESS = 'FETCH_NOTE_SUCCESS';
export const FETCH_NOTE_FAILURE = 'FETCH_NOTE_FAILURE';

export const ADD_NOTE_REQUEST = 'ADD_NOTE_REQUEST';
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';
export const ADD_NOTE_FAILURE = 'ADD_NOTE_FAILURE';

export const EDIT_NOTE_REQUEST = 'EDIT_NOTE_REQUEST';
export const EDIT_NOTE_SUCCESS = 'EDIT_NOTE_SUCCESS';
export const EDIT_NOTE_FAILURE = 'EDIT_NOTE_FAILURE';

export const DELETE_NOTE_REQUEST = 'DELETE_NOTE_REQUEST';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_FAILURE = 'DELETE_NOTE_FAILURE';

const initState = {
  allIds: [],
  byId: {},
  isLoading: false,
};

export default function(state = initState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_NOTES_REQUEST:
        draft.isLoading = true;
        break;

      case FETCH_NOTE_SUCCESS: {
        draft.byId[action.note.id] = action.note;
        const id = draft.allIds.findIndex(id => id === action.note.id);
        if (id < 0) draft.allIds.push(action.note.id);
        break;
      }

      case FETCH_NOTE_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      case FETCH_NOTES_SUCCESS:
        draft.byId = getObject(action.notes, 'id');
        draft.allIds = getIds(action.notes, 'id');
        break;

      case FETCH_NOTES_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      case ADD_NOTE_REQUEST:
        draft.isLoading = true;
        break;

      case ADD_NOTE_SUCCESS:
        draft.allIds.push(action.note.id);
        draft.byId[action.note.id] = action.note;
        break;

      case ADD_NOTE_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      case EDIT_NOTE_REQUEST:
        draft.isLoading = true;
        break;

      case EDIT_NOTE_SUCCESS:
        draft.byId[action.note.id] = action.note;
        break;

      case EDIT_NOTE_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      case DELETE_NOTE_REQUEST:
        draft.isLoading = true;
        break;

      case DELETE_NOTE_SUCCESS:
        draft.allIds = draft.allIds.filter(id => id !== action.id);
        delete draft.byId[action.id];
        break;

      case DELETE_NOTE_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      default:
        return state;
    }
  });
}

export const fetchNote = (saleId, noteId) => async dispatch => {
  try {
    dispatch({ type: FETCH_NOTES_REQUEST });
    const response = await axios.get(
      `/api/SaleNotes?sale_id=${saleId}&id=${noteId}`
    );
    return dispatch({
      type: FETCH_NOTE_SUCCESS,
      note: response.data.salenote[0],
    });
  } catch (err) {
    dispatch({
      type: FETCH_NOTE_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot fetch notes. Please try again',
      },
    });
  }
};

export const fetchNotes = (projId, saleId) => async dispatch => {
  try {
    dispatch({ type: FETCH_NOTES_REQUEST });
    const response = await axios.get(`/api/SaleNotes?sale_id=${saleId}`);

    return dispatch({
      type: FETCH_NOTES_SUCCESS,
      notes: response.data.salenote,
    });
  } catch (err) {
    dispatch({
      type: FETCH_NOTES_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot fetch notes. Please try again',
      },
    });

    return { error: true };
  }
};

export const addNote = salenote => async dispatch => {
  try {
    dispatch({ type: ADD_NOTE_REQUEST });

    const res = await axios.post('/api/SaleNote', [
      {
        root: 'sale',
        salenote,
      },
    ]);
    return dispatch({
      type: ADD_NOTE_SUCCESS,
      note: res.data.salenote[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Added',
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_NOTE_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot add note to sale. Please try again',
      },
    });
  }
};

export const editNote = (id, salenote) => async dispatch => {
  try {
    dispatch({ type: EDIT_NOTE_REQUEST });

    const res = await axios.put('/api/SaleNote', [
      {
        root: 'sale',
        salenote: {
          id,
          ...salenote,
        },
      },
    ]);

    dispatch({
      type: EDIT_NOTE_SUCCESS,
      note: res.data.salenote[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Saved',
      },
    });
    return { error: false };
  } catch (error) {
    dispatch({
      type: EDIT_NOTE_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot edit note. Please try again',
      },
    });
    return { error: true };
  }
};

export const deleteNote = id => async dispatch => {
  try {
    dispatch({ type: DELETE_NOTE_REQUEST });
    await axios.delete('/api/SaleNote', {
      data: [
        {
          root: 'saledep',
          salenote: {
            id,
          },
        },
      ],
    });

    dispatch({
      type: DELETE_NOTE_SUCCESS,
      id: id,
      globalNotification: {
        variant: 'success',
        message: 'Deleted',
      },
    });
  } catch (error) {
    dispatch({
      type: DELETE_NOTE_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot delete note. Please try again',
      },
    });
  }
};
