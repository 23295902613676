import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField as TextFieldFmk } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import DeleteButton from 'components/shared/DeleteButton';

const MprForm = ({
  item,
  onAdd,
  onDelete,
  onSave,
  initialValues,
  hasPrice,
  isBase,
  onModelNameClick,
}) => {
  const [action, setAction] = useState('add');

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <Paper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validate={values => {
              const errors = {};
              if (values.price === '') {
                errors.price = 'Required';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (action === 'delete') {
                onDelete(item, values);
              } else if (action === 'save') {
                onSave(item, values);
              } else {
                onAdd(item, values);
              }
              setSubmitting(false);
            }}
          >
            {({ submitForm, dirty }) => (
              <Form>
                <List>
                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Fab
                          style={{ boxShadow: 'none', marginRight: 10 }}
                          size="small"
                          onClick={onModelNameClick}
                          variant="extended"
                        >
                          {item.itemName}
                        </Fab>
                      </Grid>
                      <Grid item xs={4}>
                        <Field
                          name="price"
                          placeholder="Price"
                          label="Price"
                          disabled={isBase}
                          type="number"
                          inputProps={{
                            step: 0.01,
                          }}
                          margin="dense"
                          size="small"
                          variant="outlined"
                          component={TextFieldFmk}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {!hasPrice && (
                          <Button
                            style={{ marginLeft: 20 }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              setAction('add');
                              submitForm();
                            }}
                          >
                            Add
                          </Button>
                        )}
                        {hasPrice && (
                          <>
                            <Button
                              style={{ marginLeft: 20, marginRight: 20 }}
                              disabled={!dirty}
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => {
                                setAction('save');
                                submitForm();
                              }}
                            >
                              Save
                            </Button>
                            <DeleteButton
                              variant="contained"
                              size="small"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    'Are you sure you want to delete the price?'
                                  )
                                ) {
                                  setAction('delete');
                                  submitForm();
                                }
                              }}
                            >
                              Delete
                            </DeleteButton>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </List>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

MprForm.propTypes = {
  item: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  hasPrice: PropTypes.bool,
  isBase: PropTypes.bool,
  onModelNameClick: PropTypes.func,
};

export default MprForm;
