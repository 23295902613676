import React, { useEffect, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  fetchProjects,
  setActiveProject,
} from 'state/ducks/residential/projects';

/**
 * @class
 * Renders a drop down select menu for projects using a third party select library
 * @see https://react-select.com/home
 */
const ProjectSelect = () => {
  // set it selected as string to display 'Placeholder' text
  // see: https://github.com/JedWatson/react-select/issues/1043
  const [value, setValue] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const projects = useSelector(state => state.rd.projects);
  const match = matchPath(location.pathname, { path: '/rd/:projId' });

  const projId = match?.params?.projId || null;

  useEffect(() => {
    dispatch(fetchProjects());
  }, []);

  // when projects load and there is a project id in the url, set that project as selected
  useEffect(() => {
    if (projId && projects.allIds.length > 0) {
      const projectId = projects.allIds.find(id => id === parseInt(projId));
      if (projectId) {
        updateSelectedProject(projectId);
      }
    } else if (projects.allIds.length > 0) {
      // TEMPORARY: set a default selected project id since there is only one project for our client at the moment
      onProjectChange({ value: projects.allIds[0] });
    }
  }, [projects.allIds, projId]);

  const updateSelectedProject = projId => {
    setValue({
      value: projId,
      label: projects.byId[projId].address.name,
    });
    dispatch(setActiveProject(projId));
  };

  // 1. handles a change in the project select
  // 2. redirects user to "/rd/{projectId}"
  const onProjectChange = selected => {
    // do nothing if user selects the active project
    if (selected.value === projId) return;

    updateSelectedProject(selected.value);

    const newPath = `/rd/${selected.value}`;
    history.push({
      pathname: newPath,
      search: location.search,
    });
  };

  const style = {
    control: base => ({
      ...base,
      cursor: 'pointer',
    }),
    option: styles => {
      return {
        ...styles,
        color: 'black',
      };
    },
    menu: base => ({
      ...base,
    }),
  };

  const options = projects.allIds.map(id => {
    const item = projects.byId[id];
    return {
      label: item.address.name,
      value: item.id,
    };
  });

  return (
    <div style={{ width: 250 }}>
      <Select
        onChange={onProjectChange}
        options={options}
        value={value}
        styles={style}
        placeholder="Select a project"
        isDisabled={projects.isLoading}
      />
    </div>
  );
};

export default ProjectSelect;
