import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';
import portrait from 'assets/portrait.jpeg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getActiveProject } from 'state/selectors';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  table: {
    marginTop: 20,
    maxWidth: 600,
  },
  [theme.breakpoints.up('md')]: {
    card: {
      maxWidth: 600,
    },
  },
  [theme.breakpoints.up('lg')]: {
    table: {
      marginTop: 0,
    },
  },
  media: {
    // ⚠️ object-fit is not supported by IE11.
    objectFit: 'cover',
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const project = useSelector(getActiveProject);
  if (isEmpty(project)) {
    return <div>Please select a project</div>;
  }

  const data = {
    // eslint-disable-next-line
    location: '99 Lousia St, Fenelon Falls ON K0M 1N0',
    developer: 'The Kawartha Group',
    image: portrait,
    storeys: 3,
    numSuites: 57,
    suiteSizeMin: 815,
    suiteSizeMax: 1675,
    measurement: 'sq ft',
    parkingPrice: '$CALL',
    lockerPrice: '$CALL',
    percentSold: 90,
  };

  return (
    <div>
      <Grid
        container
        style={{ paddingTop: 50, paddingLeft: 10, paddingRight: 10 }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          lg={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                className={classes.media}
                height="400"
                image={portrait}
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                  {project.address.name}
                </Typography>
                <Typography component="p">
                  Location: {project.address.street},{' '}
                  {project.address.city.name} {project.address.postal}
                </Typography>
                <Typography component="p">
                  Developers: {data.developer}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                <a
                  target="_"
                  href="https://www.mooringsoncameron.com/"
                  style={{ textDecoration: 'none' }}
                >
                  Learn More
                </a>
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <TableContainer component={Paper} className={classes.table}>
            <Typography
              variant="h6"
              style={{ paddingTop: 10, paddingLeft: 10, paddingBottom: 15 }}
            >
              More Information
            </Typography>
            <Table size="small" aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Number of Storeys
                  </TableCell>
                  <TableCell>{data.storeys}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Number of Suites</TableCell>
                  <TableCell>
                    <label>{data.numSuites}</label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Floor Plans</TableCell>
                  <TableCell>
                    <label>{data.floorPlans}</label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Suite Size Range</TableCell>
                  <TableCell>
                    <label>
                      {data.suiteSizeMin + ' - ' + data.suiteSizeMax + 'sq ft'}
                    </label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ceiling Height</TableCell>
                  <TableCell>
                    <label>9-10</label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Price / sq ft from</TableCell>
                  <TableCell>
                    <label>$560</label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Parking Price</TableCell>
                  <TableCell>
                    <label>{data.parkingPrice}</label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Locker Price</TableCell>
                  <TableCell>
                    <label>{data.lockerPrice}</label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>% Sold</TableCell>
                  <TableCell>
                    <label>{data.percentSold}</label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Architects</TableCell>
                  <TableCell>
                    <label>Turner Fleischer Architects</label>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
