import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import isEmpty from 'lodash/isEmpty';
import { updateSale } from 'state/ducks/residential/sales';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import MuiTable from 'components/shared/MuiTable';

const Sales = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const sales = useSelector(state => state.rd.sales);

  const showEditForm = rowData => {
    const url = `${history.location.pathname}/edit/${rowData.id}`;
    history.push(url);
  };

  const showAddForm = () => history.push(`${history.location.pathname}/create`);

  const handleDelete = rowData => {
    if (window.confirm('Are you sure?')) {
      dispatch(
        updateSale({
          id: rowData.id,
          salestatus: 'Cancelled',
        })
      );
    }
  };

  if (isEmpty(sales)) {
    return null;
  }

  return (
    <Box p={2}>
      <MuiTable
        title={
          <Typography variant="h6" style={{ width: 55 }}>
            Sales
          </Typography>
        }
        rowName="Sale"
        options={{
          filtering: true,
          search: false,
        }}
        columns={[
          {
            title: 'Unit Number',
            field: 'unitno',
          },
          {
            title: 'Model',
            field: 'model',
          },
          {
            title: 'Purchaser',
            field: 'purchaser',
          },

          {
            title: 'Sale status',
            field: 'salestatus',
          },
        ]}
        data={query =>
          new Promise(resolve => {
            let url = '/api/Sales?';
            url += 'limit=' + query.pageSize;
            url += '&start=' + query.page * query.pageSize;

            const orderBy = query.orderBy && query.orderBy.field;
            const dir = query.orderDirection;
            if (!!orderBy && !!dir) {
              url += '&order=' + orderBy;
              url += '&dir=' + dir;
            }

            const filters = query.filters.reduce((result, filter) => {
              return (result += `&${filter.column.field}=${filter.value}`);
            }, '');

            url += filters;

            axios.get(url).then(response => {
              const data = response.data.sale.map(sale => {
                const primaryPurchaser = sale.saleaddr.find(
                  addr => addr.primary === true
                );
                return {
                  id: sale.id,
                  unitno: sale.unit.unitno,
                  model: sale.unit.model.model,
                  purchaser: primaryPurchaser
                    ? primaryPurchaser.address.name
                    : 'None',
                  salestatus: sale.salestatus,
                };
              });
              resolve({
                data: data,
                page: query.page,
                totalCount: response.data.total,
              });
            });
          })
        }
        onEdit={showEditForm}
        onDelete={handleDelete}
        onAdd={showAddForm}
      />
    </Box>
  );
};

export default Sales;
