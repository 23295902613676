import axios from 'axios';
import produce from 'immer';
import { getIds, getObject } from 'utils';
import qs from 'query-string';
export const RESET_ACTIVE_SALE = 'RESET_ACTIVE_SALE';

export const FETCH_SALE_REQUEST = 'FETCH_SALE_REQUEST';
export const FETCH_SALE_SUCCESS = 'FETCH_SALE_SUCCESS';
export const FETCH_SALE_FAILURE = 'FETCH_SALE_FAILURE';

export const SAVE_ACTIVE_SALE_REQUEST = 'SAVE_ACTIVE_SALE_REQUEST';
export const SAVE_ACTIVE_SALE_SUCCESS = 'SAVE_ACTIVE_SALE_SUCCESS';
export const SAVE_ACTIVE_SALE_FAILURE = 'SAVE_ACTIVE_SALE_FAILURE';

export const ADD_SALE_REQUEST = 'ADD_SALE_REQUEST';
export const ADD_SALE_SUCCESS = 'ADD_SALE_SUCCESS';
export const ADD_SALE_FAILURE = 'ADD_SALE_FAILURE';

export const DELETE_SALE_REQUEST = 'DELETE_SALE_REQUEST';
export const DELETE_SALE_SUCCESS = 'DELETE_SALE_SUCCESS';
export const DELETE_SALE_FAILURE = 'DELETE_SALE_FAILURE';

export const ADDRESS_LOOKUP_REQUEST = 'ADDRESS_LOOKUP_REQUEST';
export const ADDRESS_LOOKUP_SUCCESS = 'ADDRESS_LOOKUP_SUCCESS';
export const ADDRESS_LOOKUP_FAILURE = 'ADDRESS_LOOKUP_FAILURE';
export const CLEAR_LOOKUP = 'CLEAR_LOOKUP';

export const UPDATE_PERSON_REQUEST = 'UPDATE_PERSON_REQUEST';
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';
export const UPDATE_PERSON_FAILURE = 'UPDATE_PERSON_FAILURE';

export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

export const ADD_SALE_ADDRESS_REQUEST = 'ADD_SALE_ADDRESS_REQUEST';
export const ADD_SALE_ADDRESS_SUCCESS = 'ADD_SALE_ADDRESS_SUCCESS';
export const ADD_SALE_ADDRESS_FAILURE = 'ADD_SALE_ADDRESS_FAILURE';

export const DELETE_SALE_ADDRESS_REQUEST = 'DELETE_SALE_ADDRESS_REQUEST';
export const DELETE_SALE_ADDRESS_SUCCESS = 'DELETE_SALE_ADDRESS_SUCCESS';
export const DELETE_SALE_ADDRESS_FAILURE = 'DELETE_SALE_ADDRESS_FAILURE';

export const FETCH_SALE_ADDR_REQUEST = 'FETCH_SALE_ADDR_REQUEST';
export const FETCH_SALE_ADDR_SUCCESS = 'FETCH_SALE_ADDR_SUCCESS';
export const FETCH_SALE_ADDR_FAILURE = 'FETCH_SALE_ADDR_FAILURE';

export const SET_ACTIVE_SALE = 'SET_ACTIVE_SALE';

const initState = {
  sale: {
    id: null,
    unit: {
      id: null,
      model: {
        id: null,
      },
    },
  },
  saleAddresses: {
    allIds: [],
    byId: {},
    isLoading: false,
  },
  addressLookups: [],
  isLoading: false,
  fetchStatus: 'idle',
  updateStatus: 'idle',
};

export default function(state = initState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case RESET_ACTIVE_SALE:
        return initState;
      case FETCH_SALE_REQUEST:
        draft.isLoading = true;
        draft.fetchStatus = 'loading';
        draft.error = false;
        break;

      case FETCH_SALE_SUCCESS:
        draft.sale = action.sale;
        draft.isLoading = false;
        draft.fetchStatus = 'succeeded';

        break;

      case FETCH_SALE_FAILURE:
        draft.isLoading = false;
        draft.fetchStatus = 'failed';

        break;

      case SAVE_ACTIVE_SALE_REQUEST:
        draft.isLoading = false;
        draft.updateStatus = 'loading';

        break;

      case SAVE_ACTIVE_SALE_SUCCESS:
        draft.updateStatus = 'succeeded';

        draft.sale = {
          ...state.sale,
          ...action.sale,
        };
        break;

      case SAVE_ACTIVE_SALE_FAILURE:
        draft.isLoading = false;
        draft.updateStatus = 'failed';

        break;

      case UPDATE_ADDRESS_SUCCESS:
        draft.saleAddresses.byId[action.id] = action.saleAddress;
        break;

      case DELETE_SALE_ADDRESS_SUCCESS:
        draft.saleAddresses.allIds = draft.saleAddresses.allIds.filter(
          id => id !== action.id
        );
        delete draft.saleAddresses.byId[action.id];
        break;

      case ADD_SALE_ADDRESS_SUCCESS:
        draft.saleAddresses.allIds.push(action.id);
        draft.saleAddresses.byId[action.id] = action.saleAddress;
        break;

      case FETCH_SALE_ADDR_SUCCESS:
        draft.saleAddresses.allIds = getIds(action.saleAddresses, 'id');
        draft.saleAddresses.byId = getObject(action.saleAddresses, 'id');

        break;

      case ADDRESS_LOOKUP_SUCCESS:
        draft.addressLookups = action.addressLookups;
        break;

      case CLEAR_LOOKUP:
        draft.addressLookups = [];
        break;

      case SET_ACTIVE_SALE:
        draft.sale = action.sale;
        break;
      default:
        return state;
    }
  });
}

/**
 * Fetch a sale
 * @param {number} projId - the project id
 * @param {number} phase - the unit phase number
 */
export const fetchSale = (
  projId,
  saleId,
  queryParams = {}
) => async dispatch => {
  try {
    dispatch({ type: FETCH_SALE_REQUEST });
    const queryString = qs.stringify(queryParams);
    const response = await axios.get(
      `/api/v2/sale/${saleId}?project_id=${projId}${
        queryString ? `&${queryString}` : ''
      }`
    );

    return dispatch({
      type: FETCH_SALE_SUCCESS,
      sale: response.data.sale,
    });
  } catch (err) {
    return dispatch({
      type: FETCH_SALE_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Could not fetch sale. Please try again',
      },
    });
  }
};

export const resetActiveSale = () => {
  return { type: RESET_ACTIVE_SALE };
};

/**
 * Save a sale
 * @param {object} sale - the sale data
 * @param {number} projId - the project id
 */
export const saveSale = (
  sale,
  showNotif = true,
  queryParams = {}
) => async dispatch => {
  try {
    dispatch({ type: SAVE_ACTIVE_SALE_REQUEST });
    const { id, ...rest } = sale;
    const queryString = qs.stringify(queryParams);
    const res = await axios.put(`/api/v2/sale/${id}?${queryString}`, {
      ...rest,
    });

    return dispatch({
      type: SAVE_ACTIVE_SALE_SUCCESS,
      sale: res.data.sale,
      globalNotification: showNotif
        ? {
            variant: 'success',
            message: 'Saved!',
          }
        : undefined,
    });
  } catch (err) {
    dispatch({
      type: SAVE_ACTIVE_SALE_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Something went wrong',
      },
    });
  }
};

/**
 *
 * @param {Object} sale - the sale
 */
export const setActiveSale = sale => {
  return {
    type: SET_ACTIVE_SALE,
    sale,
  };
};

export const createSaleAddrWithNewAddress = (
  saleId,
  payload
) => async dispatch => {
  try {
    dispatch({
      type: ADD_SALE_ADDRESS_REQUEST,
    });

    const res = await axios.post(`/api/v2/sale/${saleId}/address`, payload);

    return dispatch({
      type: ADD_SALE_ADDRESS_SUCCESS,
      saleAddress: res.data.saleaddr,
      id: res.data.saleaddr.id,
      globalNotification: {
        variant: 'success',
        message: 'Added',
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_SALE_ADDRESS_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Something went wrong',
      },
    });
  }
};

export const updateSaleAddressV2 = (
  saleId,
  saleAddrId,
  payload
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_ADDRESS_REQUEST });
    const res = await axios.put(
      `/api/v2/sale/${saleId}/address/${saleAddrId}`,
      payload
    );

    return dispatch({
      type: UPDATE_ADDRESS_SUCCESS,
      saleAddress: res.data.saleaddr,
      id: saleAddrId,
      globalNotification: {
        variant: 'success',
        message: 'Saved',
      },
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ADDRESS_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Something went wrong',
      },
    });
  }
};

export const deleteSaleAddressV2 = (saleId, saleAddrId) => async dispatch => {
  try {
    dispatch({ type: DELETE_SALE_ADDRESS_REQUEST });

    await axios.delete(`/api/v2/sale/${saleId}/address/${saleAddrId}`);

    dispatch({
      type: DELETE_SALE_ADDRESS_SUCCESS,
      id: saleAddrId,
      globalNotification: {
        variant: 'success',
        message: 'Deleted',
      },
    });
  } catch (error) {
    dispatch({
      type: DELETE_SALE_ADDRESS_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Something went wrong',
      },
    });
  }
};

export const lookupAddress = ({
  email,
  homephone,
  postal,
  person,
  name,
}) => async dispatch => {
  try {
    dispatch({ type: ADDRESS_LOOKUP_REQUEST });

    let url = '/api/Addresses?';

    if (email) {
      url = `${url}email=${email}&`;
    }

    if (postal) {
      url = `${url}postal=${postal}&`;
    }

    if (homephone) {
      url = `${url}homephone=${homephone}&`;
    }

    if (person !== undefined) {
      url = `${url}person=${person}&`;
    }

    if (name) {
      url = `${url}name_like=${name}`;
    }

    const res = await axios.get(url);

    dispatch({
      type: ADDRESS_LOOKUP_SUCCESS,
      addressLookups: res.data.address,
    });
  } catch (error) {
    dispatch({
      type: ADDRESS_LOOKUP_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Something went wrong',
      },
    });
  }
};

export const clearLookupAddress = () => dispatch =>
  dispatch({ type: CLEAR_LOOKUP });

export const fetchSaleAddress = (
  projId,
  saleId,
  saleAddrId
) => async dispatch => {
  try {
    const res = await axios.get(
      `/api/SaleAddrs?sale_id=${saleId}&id=${saleAddrId}`
    );
    dispatch({
      type: FETCH_SALE_ADDR_SUCCESS,
      saleAddresses: res.data.saleaddr,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SALE_ADDR_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Something went wrong',
      },
    });
  }
};

export const fetchSaleAddresses = (projId, saleId) => async dispatch => {
  try {
    const res = await axios.get(`/api/SaleAddrs?sale_id=${saleId}`);

    dispatch({
      type: FETCH_SALE_ADDR_SUCCESS,
      saleAddresses: res.data.saleaddr,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SALE_ADDR_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Something went wrong',
      },
    });
  }
};

export const createSaleAddrWithExistingAddress = (
  saleId,
  payload
) => async dispatch => {
  try {
    const res = await axios.post(`/api/v2/sale/${saleId}/address`, payload);

    return dispatch({
      type: ADD_SALE_ADDRESS_SUCCESS,
      saleAddress: res.data.saleaddr,
      id: res.data.saleaddr.id,
      globalNotification: {
        variant: 'success',
        message: 'Added',
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_SALE_ADDRESS_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Something went wrong',
      },
    });
  }
};

export const getActiveSaleSaleAddress = saleAddrId => state => {
  if (saleAddrId === undefined) return null;
  return state.rd.activeSale.saleAddresses.byId[saleAddrId];
};
