import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { Formik, Field, Form } from 'formik';
import cn from 'classnames';
import {
  TextField as TextFieldFmk,
  CheckboxWithLabel,
} from 'formik-material-ui';
import isEmpty from 'lodash/isEmpty';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { saveSale } from 'state/ducks/residential/activeSale';
import * as Yup from 'yup';
import {
  Grid,
  Paper,
  Grow,
  FormControlLabel,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import PriceInput from 'components/forms/PriceInput';
import { Prompt } from 'react-router-dom';

const saleFormSchema = Yup.object().shape({
  totalprice: Yup.number(),
});

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    width: '100%',
    maxWidth: 300,
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      maxWidth: 200,
    },
  },
  checkbox: {
    height: 40,
    marginTop: 8,
    marginBottom: 4,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    padding: 20,
  },
  [theme.breakpoints.up('sm')]: {},
  [theme.breakpoints.up('md')]: {
    borderRight: {
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
}));

const SaleForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showPrompt, setShowPrompt] = useState(false);
  const sale = useSelector(state => state.rd.activeSale.sale);
  const updateStatus = useSelector(state => state.rd.activeSale.updateStatus);
  const [showCredits, setShowCredits] = useState(false);
  const [initValues, setInitValues] = useState(null);

  useEffect(() => {
    const initFormModel = () => {
      const itc = sale.totalprice ? sale.totalprice * 0.13 : 0;
      setInitValues({
        warrfee: sale.warrfee || 0,
        devchgincr: sale.devchgincr || 0,
        totalcr: sale.totalcr || 0,
        canceldate: sale.canceldate || '',
        offerdate: sale.offerdate || '',
        actualclosing: sale.actualclosing || '',
        targetclosing: sale.targetclosing || '',
        datefirm: sale.datefirm || '',
        lockers: sale.lockers || 0,
        lockerprice: sale.lockerprice || 0,
        codetect: sale.codetect || 0,
        parkingcr: sale.parkingcr || 0,
        deedxfer: sale.deedxfer || 0,
        freemtce: sale.freemtce || 0,
        closecr: sale.closecr || 0,
        devchgcap: sale.devchgcap || 0,
        edurecov: sale.edurecov || 0,
        warrregno: sale.warrregno || '',
        totalprice: sale.totalprice || 0,
        salestatus: sale.salestatus || '',
        mtgefee: sale.mtgefee || 0,
        extras: sale.extras || 0,
        parkingprice: sale.parkingprice || 0,
        furnpkg: sale.furnpkg || 0,
        parklevy: sale.parklevy || 0,
        freelock: sale.freelock || 0,
        waitparking: sale.waitparking || false,
        upgrades: sale.upgrades || 0,
        upgradesitc: sale.upgrades ? sale.upgrades * 0.13 : 0,
        statcert: sale.statcert || 0,
        sec37recov: sale.sec37recov || 0,
        baseprice: sale.baseprice || 0,
        energcap: sale.energcap || 0,
        netprice:
          sale.totalprice && sale.upgrades
            ? sale.totalprice + itc + sale.upgrades * 0.13
            : 0,
        parking: sale.parking || 0,
        itc: itc,
        id: sale.id || 0,
      });
    };
    if (sale && !isEmpty(sale)) {
      initFormModel();
    }
  }, [sale]);

  const sanitizeDates = values => {
    const temp = {
      ...values,
    };

    if (!temp.actualclosing) {
      delete temp.actualclosing;
    }
    if (!temp.offerdate) {
      delete temp.offerdate;
    }
    if (!temp.targetclosing) {
      delete temp.targetclosing;
    }

    if (!temp.datefirm) {
      delete temp.datefirm;
    }

    if (!temp.conddate) {
      delete temp.conddate;
    }

    if (!temp.canceldate) {
      delete temp.canceldate;
    }

    return temp;
  };

  const handleSubmit = values => {
    delete values.upgradesitc;
    setShowPrompt(false);
    dispatch(saveSale(sanitizeDates(values)));
  };

  const part1 = [
    // {
    //   label: 'Date Firm',
    //   name: 'datefirm',
    //   type: 'date',
    // },
    // {
    //   label: 'Offer Date',
    //   name: 'offerdate',
    //   type: 'date',
    // },
    // {
    //   label: 'Cancel Date',
    //   name: 'canceldate',
    //   type: 'date',
    // },
    // {
    //   label: 'Target Closing',
    //   name: 'targetclosing',
    //   type: 'date',
    // },
    {
      label: 'Closing Date',
      name: 'actualclosing',
      type: 'date',
    },
  ];

  const part2 = [
    {
      label: 'Upgrades',
      name: 'upgrades',
      type: 'price',
      disabled: true,
    },
    {
      label: 'Upgrades (HST/GST/QST)',
      name: 'upgradesitc',
      type: 'price',
      disabled: true,
    },
    {
      label: 'Lockers',
      name: 'lockers',
      type: 'number',
    },
    {
      label: 'Locker Price',
      name: 'lockerprice',
      type: 'price',
    },
    {
      label: 'Parking',
      name: 'parking',
      type: 'number',
    },

    {
      label: 'Parking Price',
      name: 'parkingprice',
      type: 'price',
    },
    {
      label: 'Wait Parking',
      name: 'waitparking',
      type: 'checkbox',
    },
    // {
    //   label: 'Total Price',
    //   name: 'totalprice',
    //   type: 'price',
    //   disabled: true,
    // },
    // {
    //   label: 'HST/GST/QST',
    //   name: 'itc',
    //   type: 'price',
    //   disabled: true,
    // },

    // {
    //   label: 'Net Price',
    //   name: 'netprice',
    //   type: 'price',
    //   disabled: true,
    // },
  ];

  const part3 = [
    {
      label: 'Close Credit',
      name: 'closecr',
      type: 'price',
    },
    {
      label: 'Free Locker',
      name: 'freelock',
      type: 'price',
    },
    {
      label: 'Parking Credit',
      name: 'parkingcr',
      type: 'price',
    },
    {
      label: 'Furniture Package',
      name: 'furnpkg',
      type: 'price',
    },
    {
      label: 'One Yr Free Maintainance',
      name: 'freemtce',
      type: 'price',
    },
  ];
  const part4 = [
    {
      label: 'CO Detector',
      name: 'codetect',
      type: 'price',
    },
    {
      label: 'DC Cap',
      name: 'devchgcap',
      type: 'number',
    },
    {
      label: 'DC Increase',
      name: 'devchgincr',
      type: 'number',
    },
    {
      label: 'Deed Transfer Fee',
      name: 'deedxfer',
      type: 'price',
    },

    {
      label: 'Education Recovery',
      name: 'edurecov',
      type: 'price',
    },

    {
      label: 'Energisation Cap',
      name: 'energcap',
      type: 'price',
    },
    {
      label: 'Mortgage Release Fee',
      name: 'mtgefee',
      type: 'price',
    },
    {
      label: 'Park Levy',
      name: 'parklevy',
      type: 'price',
    },
    {
      label: 'Section 37 Recovery',
      name: 'sec37recov',
      type: 'price',
    },
    {
      label: 'Status Certificate',
      name: 'statcert',
      type: 'price',
    },
    {
      label: 'Warrenty Fee',
      name: 'warrfee',
      type: 'price',
    },
  ];

  const renderField = ({ type, label, name, disabled = false }) => {
    if (type === 'price')
      return (
        <Field
          key={name}
          name={name}
          label={label}
          component={PriceInput}
          className={classes.input}
          margin="dense"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
          inputProps={{
            onFocus: () => setShowPrompt(true),
          }}
        />
      );
    else if (type === 'text' || type === 'number' || type === 'date')
      return (
        <Field
          key={name}
          component={TextFieldFmk}
          label={label}
          name={name}
          type={type}
          className={classes.input}
          margin="dense"
          variant="outlined"
          disabled={disabled}
          inputProps={{
            onFocus: () => setShowPrompt(true),
          }}
          InputLabelProps={{ shrink: true }}
        />
      );
    else if (type === 'checkbox')
      return (
        <div style={{ width: '100%' }} className={classes.input} key={name}>
          <Field
            component={CheckboxWithLabel}
            name={name}
            className={classes.checkbox}
            Label={{ label: label }}
            type={type}
            inputProps={{
              onFocus: () => setShowPrompt(true),
            }}
          />
        </div>
      );
  };

  if (!initValues || !sale.id) {
    return null;
  }

  return (
    <Box>
      <Prompt when={showPrompt} message="Are you sure you want to leave?" />
      <Formik
        initialValues={initValues}
        validationSchema={saleFormSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values }) => (
          <Form className={classes.formContainer}>
            <FormControl className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6">Summary</Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showCredits}
                          onChange={() => setShowCredits(prev => !prev)}
                        />
                      }
                      label="Show Costs"
                    />
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Paper className={classes.paper}>
                    <Grid container>
                      <Grid
                        item
                        xs={4}
                        className={cn(
                          classes.inputContainer,
                          classes.borderRight
                        )}
                      >
                        <Typography variant="h6" style={{ marginBottom: 10 }}>
                          Model
                        </Typography>
                        <TextField
                          label="Unit Number"
                          className={classes.input}
                          margin="dense"
                          variant="outlined"
                          value={sale.unit.unitno}
                          disabled
                        />

                        <TextField
                          label="Model"
                          className={classes.input}
                          margin="dense"
                          variant="outlined"
                          value={sale.unit.model.model}
                          disabled
                        />

                        <TextField
                          label="Unit Status"
                          className={classes.input}
                          margin="dense"
                          variant="outlined"
                          value={sale.unit.unitstatus}
                          disabled
                        />
                        <Field
                          name="baseprice"
                          component={PriceInput}
                          label="Base Price"
                          className={classes.input}
                          margin="dense"
                          variant="outlined"
                          // disabled
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          label="Warranty Registration"
                          className={classes.input}
                          margin="dense"
                          variant="outlined"
                          value={values.warrregno}
                          disabled
                        />
                        {part1.map(renderField)}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className={cn(
                          classes.inputContainer,
                          classes.borderRight
                        )}
                      >
                        <Typography variant="h6" style={{ marginBottom: 10 }}>
                          Upgrades
                        </Typography>

                        {part2.map(renderField)}
                      </Grid>
                      <Grid item xs={4} className={`${classes.inputContainer}`}>
                        <Typography variant="h6" style={{ marginBottom: 10 }}>
                          Credits on Closing
                        </Typography>
                        {part3.map(renderField)}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Grow in={showCredits}>
                    <Paper
                      className={cn(classes.paper, classes.inputContainer)}
                    >
                      <Typography variant="h6" style={{ marginBottom: 10 }}>
                        Closing Costs
                      </Typography>
                      {part4.map(renderField)}
                    </Paper>
                  </Grow>
                </Grid>
              </Grid>
              <Box
                display="flex"
                justifyContent="center"
                mt={3}
                width="100%"
                style={{
                  position: 'fixed',
                  left: 0,
                  bottom: 0,
                  marginBottom: 20,
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={updateStatus === 'loading'}
                  className={classes.submitButton}
                  type="submit"
                >
                  {updateStatus === 'loading' ? (
                    <CircularProgress
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </Box>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

SaleForm.propTypes = {};
export default SaleForm;
