import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import {
  fetchDeposit,
  editDeposit,
  addDeposit,
} from 'state/ducks/residential/saleDeposits';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Prompt, useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    width: 550,
  },
  input: {
    width: 250,
  },
  formSelect: {
    width: '250px',
  },
  radio: {
    width: '100%',
    marginTop: 30,
  },
});

const AddEditDeposit = ({ match }) => {
  const { saleId, id } = match.params;
  const isAddMode = !id;
  const title = isAddMode ? 'Add Deposit' : 'Edit Deposit';
  const buttonText = isAddMode ? 'Add' : 'Save';
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const saleDeposit = useSelector(state => state.rd.saleDeposits.byId[id]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [initialValues, setInitialValues] = useState({
    duedate: '',
    recdate: '',
    amount: 0,
    description: '',
    payment_type: '',
    sale: saleId,
  });

  const depositTypes = [
    {
      value: 'CC',
      label: 'Credit Card',
    },
    {
      value: 'CHQ',
      label: 'Cheque',
    },

    {
      value: 'ET',
      label: 'Email Transfer',
    },
  ];
  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchDeposit(saleId, id));
    }
  }, [isAddMode]);

  useEffect(() => {
    if (!isAddMode && saleDeposit) {
      setInitialValues({
        ...saleDeposit,
        recdate: saleDeposit.recdate || '',
      });
    }
  }, [isAddMode, saleDeposit]);

  const validationSchema = Yup.object().shape({
    duedate: Yup.date().required('Due date is required'),
    description: Yup.string().required('Description is required'),
    payment_type: Yup.string().required('Payment type is required'),
    amount: Yup.number().required('Amount is required'),
  });

  const handleSubmit = async values => {
    setShowPrompt(false);

    // Fields with null values need to be initialized to empty strings to prevent "uncontrolled to controlled" browser warning.
    // When submitting we need to convert the fields back to null. This is a known limitation with formik
    const cleanedVals = {
      ...values,
    };
    if (cleanedVals.recdate === '') cleanedVals.recdate = null;

    if (isAddMode) {
      await dispatch(addDeposit(cleanedVals));
      history.push('.');
    } else {
      await dispatch(editDeposit(id, cleanedVals));
      history.push('..');
    }
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Prompt when={showPrompt} message="Are you sure you want to leave?" />
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={classes.formContainer}>
              <FormControl className={classes.form}>
                <Field
                  component={TextField}
                  label="Description"
                  name="description"
                  multiline
                  margin="dense"
                  className={classes.input}
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                  variant="outlined"
                />

                <Field
                  component={TextField}
                  label="Amount"
                  name="amount"
                  type="number"
                  margin="dense"
                  className={classes.input}
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                    step: 0.01,
                  }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Field
                  component={TextField}
                  label="Due Date"
                  name="duedate"
                  type="date"
                  margin="dense"
                  className={classes.input}
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Field
                  component={TextField}
                  label="Receive Date"
                  name="recdate"
                  type="date"
                  margin="dense"
                  className={classes.input}
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Field
                  component={TextField}
                  type="text"
                  select
                  label="Payment Type"
                  name="payment_type"
                  className={classes.formSelect}
                  margin="dense"
                  variant="outlined"
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                >
                  {depositTypes.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Box display="flex" justifyContent="center" mt={3} width="100%">
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 10 }}
                    className={classes.submitButton}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </Button>
                  <Button
                    style={{ marginRight: 10 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push(isAddMode ? '.' : '..')}
                  >
                    Cancel
                  </Button>
                </Box>
              </FormControl>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

AddEditDeposit.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AddEditDeposit;
