import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import isEmpty from 'lodash/isEmpty';
import { fetchCrafts, deleteCraft } from 'state/ducks/residential/crafts';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiTable from 'components/shared/MuiTable';

const CraftsTable = () => {
  const { projId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const crafts = useSelector(state => state.rd.crafts);

  useEffect(() => {
    dispatch(fetchCrafts(projId));
  }, [projId, dispatch]);

  const showEditForm = rowData => {
    history.push(`${history.location.pathname}/edit/${rowData.id}`);
  };

  const showAddForm = () => {
    history.push(`${history.location.pathname}/create`);
  };

  const handleDelete = values => {
    if (window.confirm('Are you sure you want to delete the craft?'))
      dispatch(deleteCraft({ id: values.id }));
  };

  if (isEmpty(crafts)) {
    return null;
  }

  const tableData = crafts.allIds.map(id => {
    const craftData = crafts.byId[id];
    return {
      name: craftData.name,
      short: craftData.short,
      id: craftData.id,
    };
  });

  return (
    <Box p={2}>
      {crafts.isLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!crafts.isLoading && (
        <MuiTable
          title="Crafts"
          rowName="Craft"
          columns={[
            {
              title: 'Name',
              field: 'name',
            },
            {
              title: 'Short',
              field: 'short',
            },
          ]}
          data={tableData}
          onEdit={showEditForm}
          onDelete={handleDelete}
          onAdd={showAddForm}
        />
      )}
    </Box>
  );
};

export default CraftsTable;
