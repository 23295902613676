import React, { Component } from 'react';
import CraftsTable from 'components/setup/crafts/CraftsTable';
import { Switch, Route } from 'react-router-dom';
import AddCraftForm from 'components/setup/crafts/AddCraftForm';
import EditCraftForm from 'components/setup/crafts/EditCraftForm';
class Crafts extends Component {
  render() {
    return (
      <Switch>
        <Route
          path="/rd/:projId/setup-crafts/create"
          component={AddCraftForm}
        />
        <Route
          path="/rd/:projId/setup-crafts/edit/:craftId"
          component={EditCraftForm}
        />
        <Route component={CraftsTable} />
      </Switch>
    );
  }
}

Crafts.propTypes = {};

export default Crafts;
