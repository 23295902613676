import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { fetchNote } from 'state/ducks/residential/saleNotes';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Prompt, useHistory } from 'react-router-dom';
import { editNote, addNote } from 'state/ducks/residential/saleNotes';
import FormikAutocomplete from 'components/forms/FormikAutoComplete';

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    width: 550,
  },
  input: {
    width: 250,
  },
  formSelect: {
    width: '250px',
  },
  radio: {
    width: '100%',
    marginTop: 30,
  },
});

const AddEditNote = ({ match }) => {
  const { saleId, id } = match.params;
  const isAddMode = !id;
  const title = isAddMode ? 'Add Note' : 'Edit Note';
  const buttonText = isAddMode ? 'Add' : 'Save';
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const saleNote = useSelector(state => state.rd.saleNotes.byId[id]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [initialValues, setInitialValues] = useState({
    note: '',
    notedate: '',
    ticklerdate: '',
    visibility: [],
    sale: saleId,
  });
  const addressTypes = ['lawyer', 'broker', 'buyer', 'internal'];
  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchNote(saleId, id));
    }
  }, [isAddMode]);

  useEffect(() => {
    if (!isAddMode && saleNote) {
      setInitialValues({
        ...saleNote,
        ticklerdate: saleNote.ticklerdate || '',
        visibility: saleNote.visibility ? saleNote.visibility.split(',') : [],
      });
    }
  }, [isAddMode, saleNote]);

  const validationSchema = Yup.object().shape({
    note: Yup.string().required('Title is required'),
    notedate: Yup.date().required('Note date is required'),
  });

  const handleSubmit = async values => {
    setShowPrompt(false);

    // Fields with null values need to be initialized to empty strings to prevent "uncontrolled to controlled" browser warning.
    // When submitting we need to convert the fields back to null. This is a known limitation with formik
    const cleanedVals = {
      ...values,
    };
    if (cleanedVals.ticklerdate === '') cleanedVals.ticklerdate = null;
    if (cleanedVals.visibility === '') cleanedVals.visibility = null;
    else {
      cleanedVals.visibility = cleanedVals.visibility.join(',');
    }
    if (isAddMode) {
      await dispatch(addNote(cleanedVals));
      history.push('.');
    } else {
      await dispatch(editNote(id, cleanedVals));
      history.push('..');
    }
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Prompt when={showPrompt} message="Are you sure you want to leave?" />
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={classes.formContainer}>
              <FormControl className={classes.form}>
                <Field
                  component={TextField}
                  label="Note"
                  name="note"
                  multiline
                  margin="dense"
                  className={classes.input}
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                  variant="outlined"
                />
                <Field
                  component={TextField}
                  label="Note Date"
                  name="notedate"
                  type="date"
                  margin="dense"
                  className={classes.input}
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Field
                  component={TextField}
                  label="Tickler Date"
                  name="ticklerdate"
                  type="date"
                  margin="dense"
                  className={classes.input}
                  inputProps={{
                    onFocus: () => setShowPrompt(true),
                  }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Field
                  multiple
                  name="visibility"
                  component={FormikAutocomplete}
                  label="Visibility"
                  options={addressTypes}
                  style={{
                    width: 250,
                  }}
                  textFieldProps={{
                    margin: 'normal',
                    variant: 'outlined',
                    label: 'For',
                    onFocus: () => setShowPrompt(true),
                  }}
                />
                <Box display="flex" justifyContent="center" mt={3} width="100%">
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 10 }}
                    className={classes.submitButton}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </Button>
                  <Button
                    style={{ marginRight: 10 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push(isAddMode ? '.' : '..')}
                  >
                    Cancel
                  </Button>
                </Box>
              </FormControl>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

AddEditNote.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AddEditNote;
