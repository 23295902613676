import React, { useState, useEffect } from 'react';
import { editProduct, fetchProduct } from 'state/ducks/residential/products';
import { useDispatch, useSelector } from 'react-redux';
import ProductForm from './ProductForm';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import BaseList from 'components/shared/BaseList';
import { TextField } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { setNotification } from 'state/ducks/globalNotification';
import PropTypes from 'prop-types';
import {
  Typography,
  DialogTitle,
  Button,
  Dialog,
  DialogContent,
  Grid,
} from '@material-ui/core';

const formSchema = Yup.object().shape({
  text: Yup.string().required('Style cannot be empty'),
});

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
    maxWidth: 250,
    marginBottom: 20,
    marginTop: 20,
  },
  list: {
    width: 250,
    marginTop: 20,
    marginBottom: 20,
  },
}));

const ProductStyles = ({ product }) => {
  const dispatch = useDispatch();
  const { projId, prodId } = useParams();
  const [prodStyles, setProdStyles] = useState(null);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (product) {
      const fetchProdStyles = async () => {
        try {
          const res = await axios.get(
            `/api/ProdStyles?product_id=${product.id}`
          );

          setProdStyles(res.data.prodstyle);
        } catch (error) {
          dispatch({
            type: '',
            globalNotification: {
              variant: 'error',
              message: error.message || 'Something went wrong',
            },
          });
        }
      };

      fetchProdStyles();
    }
  }, [product, dispatch, prodId, projId]);

  const handleDeleteStyle = id => {
    const deleteProdStyle = async () => {
      try {
        await axios.delete('/api/ProdStyle', {
          data: [
            {
              root: 'prodstyle',
              prodstyle: {
                id: id,
              },
            },
          ],
        });

        const temp = prodStyles.filter(ps => ps.id !== id);
        setProdStyles(temp);

        dispatch(setNotification('success', 'Deleted'));
      } catch (error) {
        dispatch(
          setNotification('error', error.message || 'Something went wrong')
        );
      }
    };
    deleteProdStyle();
  };

  const handleAddStyle = ({ text }, resetForm) => {
    const addProdStyle = async () => {
      try {
        const res = await axios.post('/api/ProdStyle', [
          {
            root: 'prodstyle',
            prodstyle: {
              product: product.id,
              style: text,
            },
          },
        ]);

        const temp = prodStyles.concat(res.data.prodstyle[0].fields);
        setProdStyles(temp);

        dispatch(setNotification('success', 'Added'));

        resetForm();
      } catch (error) {
        let message;
        if (
          error.response.data.message &&
          error.response.data.message.toLowerCase().includes('unique key')
        ) {
          message = `Product style "${text}" already exists`;
        } else {
          message = 'Something went wrong';
        }
        dispatch(setNotification('error', message));
      }
    };

    addProdStyle();
  };

  if (!prodStyles) {
    return null;
  }

  const tableData = prodStyles.map(ps => ({
    id: ps.id,
    text: ps.style,
  }));

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {tableData.length > 0 ? (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h6">Product Styles</Typography>
          <BaseList
            className={classes.list}
            data={tableData}
            showDelete={true}
            onDelete={handleDeleteStyle}
          />
        </div>
      ) : null}
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setIsOpen(true)}
        >
          Add product style
        </Button>
      </div>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Add Product style</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              text: '',
            }}
            validationSchema={formSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleAddStyle(values, resetForm);
              setSubmitting(false);
            }}
          >
            {({ values }) => (
              <Form className={classes.formContainer}>
                <Field
                  component={TextField}
                  label="Add style"
                  name="text"
                  value={values.text}
                  className={classes.input}
                  variant="outlined"
                />
                <input
                  type="submit"
                  style={{
                    display: 'none',
                  }}
                />
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

ProductStyles.propTypes = {
  product: PropTypes.object,
};

const EditProductForm = () => {
  const dispatch = useDispatch();

  const { projId, prodId } = useParams();
  const product = useSelector(state => state.rd.products.byId[prodId]);
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    if (!product) {
      dispatch(fetchProduct(projId, prodId));
    } else {
      // eslint-disable-next-line
      const { prodstyles, ...rest } = product;
      setFormValues({
        ...rest,
        project: product.project.id,
        craft: product.craft.id,
      });
    }
  }, [product, dispatch, prodId, projId]);

  if (!product) {
    return null;
  }

  const handleEdit = async values => {
    const success = await dispatch(editProduct(values));

    if (success) {
      dispatch(setNotification('success', 'Saved'));
    }
  };

  return (
    <Grid container justify="center" style={{ marginTop: 20 }} spacing={5}>
      <Grid item xs={12} md={3}>
        <ProductForm
          initialValues={formValues}
          onSubmit={handleEdit}
          title="Product"
          projId={projId}
          buttonText="Save"
          type="Edit"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ProductStyles product={product} />
      </Grid>
    </Grid>
  );
};

export default EditProductForm;
