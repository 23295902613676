import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSales } from 'state/ducks/residential/sales';
import { createLoadingSelector } from 'state/ducks/loading/loading';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import {
  MultiGrid,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';
import styles from './saleStats.module.css';

import cn from 'classnames';
import { get } from 'lodash';

const STYLE = {
  border: '1px solid #ddd',
  backgroundColor: 'white',
};
const STYLE_BOTTOM_LEFT_GRID = {
  borderRight: '2px solid #aaa',
  backgroundColor: 'white',
};
const STYLE_TOP_LEFT_GRID = {
  borderBottom: '2px solid #aaa',
  borderRight: '2px solid #aaa',
  fontWeight: 'bold',
};
const STYLE_TOP_RIGHT_GRID = {
  borderBottom: '2px solid #aaa',
  fontWeight: 'bold',
};

function SaleStats() {
  const dispatch = useDispatch();
  const sales = useSelector(state => state.rd.sales);
  const isLoading = useSelector(createLoadingSelector(['FETCH_SALES']));
  const { projId } = useParams();

  useEffect(() => {
    dispatch(fetchSales(projId, { limit: 1000 }));
  }, [projId]);

  const _cache = new CellMeasurerCache({
    defaultHeight: 30,
    defaultWidth: 150,
    fixedHeight: true,
  });
  if (isLoading) {
    return <CircularProgress />;
  }
  const columnNames = [
    { name: 'Unit', key: 'unitno' },
    { name: 'Status', key: 'salestatus' },
    
    { name: 'P1 Name', key: 'pa_name' },
    { name: 'P2 Name', key: 'sa_name' },
    
    { name: 'P1 Street', key: 'pa_street' },
    { name: 'P1 City', key: 'pa_city_name' },
    { name: 'P1 Province', key: 'pa_state_name' },
    { name: 'P1 Postal', key: 'pa_postal' },
    { name: 'P1 Home Phone', key: 'pa_homephone' },
    { name: 'P1 Business Phone', key: 'pa_busphone' },
    { name: 'P1 Mobile', key: 'pa_mobilephone' },
    { name: 'P1 Email', key: 'pa_email' },
    { name: 'P1 Delivery', key: 'pri_delmeth' },
    
    { name: 'P2 Home Phone', key: 'sa_homephone' },
    { name: 'P2 Mobile', key: 'pa_mobilephone' },
    { name: 'P2 Email', key: 'sa_email' },
    { name: 'P2 Delivery', key: 'pri_delmeth' },
    
    { name: 'Offer Date', key: 'offerdate' },
    
    { name: 'Model', key: 'model' },
    { name: 'Area', key: 'area' },
    { name: 'Bedrooms', key: 'bedrooms' },
    { name: 'Washrooms', key: 'bathrooms' },
    { name: 'Parking', key: 'parking' },
    { name: 'Lockers', key: 'lockers' },
    { name: 'Total Price', key: 'totalprice' },
    { name: 'Net price', key: 'netpurchaseprice' },
    { name: 'Closing Credit ', key: 'closecr' },

    { name: 'Lawyer Name', key: 'la_name' },
    { name: 'Street', key: 'la_street' },
    { name: 'City', key: 'la_city_name' },
    { name: 'Province', key: 'la_state_name' },
    { name: 'Postal', key: 'la_postal' },
    { name: 'Business Phone', key: 'la_busphone' },
    { name: 'Mobile', key: 'la_mobilephone' },
    { name: 'Email', key: 'la_email' },
  ];

  function getContent({ key, datum }) {
    return get(datum, key, 'N/A');
  }
  function getClassName({ rowIndex }) {
    const rowClass = rowIndex % 2 === 0 ? styles.evenRow : styles.oddRow;
    return cn(rowClass, styles.cell);
  }

  const cellRenderer = ({ columnIndex, key, parent, rowIndex, style }) => {

    const classNames = getClassName({ columnIndex, rowIndex });

    let content = null;
    if (rowIndex === 0) {
      content = columnNames[columnIndex].name.toUpperCase();
    } else {
      const datum = sales.byId[sales.allIds[rowIndex - 1]];
      const datumKey = columnNames[columnIndex].key;
      content = getContent({ key: datumKey, datum, long: false });
    }

    return (
      <CellMeasurer
        cache={_cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div
          className={classNames}
          style={{
            ...style,
            height: 35,
            whiteSpace: 'nowrap',
          }}
        >
          {content}
        </div>
      </CellMeasurer>
    );
  };

  return (
    <Box className={styles.table}>
      <Box mb={1} mt={1} pl={2}>
        <Typography variant="h6">Sales</Typography>
      </Box>

      <AutoSizer>
        {({ width }) => (
          <MultiGrid
            className={styles.BodyGrid}
            columnCount={columnNames.length}
            columnWidth={_cache.columnWidth}
            deferredMeasurementCache={_cache}
            fixedColumnCount={1}
            fixedRowCount={1}
            cellRenderer={cellRenderer}
            height={450}
            overscanColumnCount={0}
            overscanRowCount={0}
            rowCount={sales.allIds.length}
            rowHeight={35}
            width={width}
            style={STYLE}
            styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
            styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
            styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
          />
        )}
      </AutoSizer>
    </Box>
  );
}

SaleStats.propTypes = {};

export default SaleStats;
