import React, { Component } from 'react';
import ProductsTable from 'components/setup/products/ProductsTable';
import AddProductForm from 'components/setup/products/AddProductForm';
import MprFormContainer from 'components/setup/products/MprFormContainer';
import EditProductForm from 'components/setup/products/EditProductForm';

import { Switch, Route } from 'react-router-dom';

class Products extends Component {
  render() {
    return (
      <Switch>
        <Route
          path="/rd/:projId/setup-products/add-to-model/:prodId"
          component={MprFormContainer}
        />
        <Route
          path="/rd/:projId/setup-products/create"
          component={AddProductForm}
        />
        <Route
          path="/rd/:projId/setup-products/edit/:prodId"
          component={EditProductForm}
        />
        <Route component={ProductsTable} />
      </Switch>
    );
  }
}

Products.propTypes = {};

export default Products;
