import React, { Component } from 'react';
import UnitsTable from 'components/setup/units/UnitsTable';
import { Switch, Route } from 'react-router-dom';
import AddUnitForm from 'components/setup/units/AddUnitForm';
import EditUnitForm from 'components/setup/units/EditUnitForm';

class Units extends Component {
  render() {
    return (
      <Switch>
        <Route path="/rd/:projId/setup-un/create" component={AddUnitForm} />
        <Route
          path="/rd/:projId/setup-un/edit/:unitId"
          component={EditUnitForm}
        />
        <Route component={UnitsTable} />
      </Switch>
    );
  }
}

Units.propTypes = {};

export default Units;
