import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';

let middlewares = [];
let composeEnhancers = compose;
if (process.env.NODE_ENV === 'development') {
  // const { logger } = require('redux-logger');
  // middlewares.push(logger);
  middlewares.push(require('redux-immutable-state-invariant').default());
  composeEnhancers = composeWithDevTools;
  // const {whyDidYouUpdate} = require('why-did-you-update');
  // whyDidYouUpdate(React, { groupByComponent: true, collapseComponentGroups: false });
}

export default preLoadedState => {
  middlewares.push(thunkMiddleware);
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeEnhancers(...enhancers);

  const store = createStore(rootReducer, preLoadedState, composedEnhancers);
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
};
