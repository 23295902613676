import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageContainer from 'views/layouts/PageContainer';
import axios from 'axios';
import PropTypes from 'prop-types';
import useInterval from 'hooks/useInterval';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { fetchCrafts } from 'state/ducks/residential/crafts';
import Select from 'react-select';
import { setNotification } from 'state/ducks/globalNotification';

const RenderCrafts = ({ crafts, onGenerate, hasPendingTasks }) => {
  const [value, setValue] = useState(null);

  const handleChange = selected => {
    const { value: craftId } = selected;
    setValue({
      value: craftId,
      label: crafts.byId[craftId].name,
    });
  };

  const options = crafts.allIds.map(id => {
    const item = crafts.byId[id];
    return {
      label: item.name,
      value: item.id,
    };
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Select
          onChange={handleChange}
          options={options}
          value={value}
          placeholder="Select a craft"
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => onGenerate(value)}
          disabled={hasPendingTasks}
        >
          Generate
        </Button>
      </Grid>
    </Grid>
  );
};

RenderCrafts.propTypes = {
  crafts: PropTypes.object,
  onGenerate: PropTypes.func.isRequired,
  hasPendingTasks: PropTypes.bool.isRequired,
};

const RenderTask = ({ task, craft }) => {
  let secondaryText = '';
  if (task && task.started_on) {
    const d = new Date(task.started_on);
    // eslint-disable-next-line
    const date = format(d, "LLL io, uuuu 'at' h:mm aaaa");
    secondaryText = `Last updated on ${date}`;
  }

  return (
    <ListItem key={craft.id}>
      <ListItemText primary={`${craft.name}`} secondary={secondaryText} />

      <ListItemSecondaryAction>
        <Box display="flex">
          {task && task.errors && (
            <Alert severity="error" style={{ marginRight: 10, marginLeft: 5 }}>
              Error! This task did not generate properly.
            </Alert>
          )}
          {!task || task.status === 'completed' ? null : (
            <>
              <span style={{ marginRight: 10 }}>Pending</span>
              <CircularProgress
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </>
          )}
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

RenderTask.propTypes = {
  craft: PropTypes.object,
  handleClick: PropTypes.func,
  task: PropTypes.object,
  floor: PropTypes.number,
};

const SupplyOrder = () => {
  const { projId } = useParams();
  const [tasks, setTasks] = useState(null);
  const [initialTasksCall, setInitialTasksCall] = useState(true);
  const [hasPendingTasks, setHasPendingTasks] = useState(false);
  const [driveFolderId, setDriveFolderId] = useState('');
  const [saveIdForLater, setSaveIdForLater] = useState(false);
  const dispatch = useDispatch();
  const crafts = useSelector(state => state.rd.crafts);
  useEffect(() => {
    if (crafts.allIds.length === 0) dispatch(fetchCrafts(projId));
  }, [crafts.allIds, projId]);

  // check local storage for drive folder id
  useEffect(() => {
    const item = localStorage.getItem('google_drive_folder_id');
    if (item) {
      setDriveFolderId(item);
      setSaveIdForLater(true);
    }
  }, []);

  useEffect(() => {
    // display notif if tasks are done
    if (initialTasksCall === false && hasPendingTasks === false)
      dispatch(setNotification('success', 'Your task is complete!'));
  }, [hasPendingTasks]);

  // handle saving drive folder id in local storage
  useEffect(() => {
    if (saveIdForLater) {
      localStorage.setItem('google_drive_folder_id', driveFolderId);
    } else {
      localStorage.removeItem('google_drive_folder_id', driveFolderId);
    }
  }, [driveFolderId, saveIdForLater]);

  // Simulates polling. When tasks are "pending" (in progress) we want to periodically check if they're done.
  // see: https://blog.bitsrc.io/polling-in-react-using-the-useinterval-custom-hook-e2bcefda4197?gi=f4e2114d8252
  useInterval(async () => {
    if (
      crafts.allIds.length !== 0 &&
      (hasPendingTasks === true || initialTasksCall === true)
    ) {
      fetchSupplyOrderTasks(crafts.byId);
    }
  }, 3000);

  const fetchSupplyOrderTasks = async crafts => {
    try {
      if (initialTasksCall) {
        setInitialTasksCall(false);
      }

      // filter the tasks by craft name
      let craftsQuery = '';
      for (let craftId in crafts) {
        craftsQuery += `${crafts[craftId].name},`;
      }
      const res = await axios.get(
        `/api/v2/supply-order-report?project=${projId}&tags[]=${craftsQuery}`
      );
      const tmp = res.data.tasks;
      setTasks(tmp);
      let pending = false;
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].status === 'pending') {
          pending = true;
        }
      }

      if (!pending) {
        setHasPendingTasks(false);
      }
    } catch (error) {
      alert('Something went wrong');
    }
  };

  const getIdFromUrl = () => {
    const url = driveFolderId.split(/\/|\?/);
    for (let i = 0; i < url.length; i++) {
      if (url[i] == 'folders') {
        return url[i + 1];
      }
    }
    return undefined;
  };

  const handleGenerate = async ({ value: craftId }) => {
    if (!driveFolderId) {
      alert('Please enter a google folder id to generate a report');
      return;
    }
    const id = getIdFromUrl(driveFolderId);
    if (!id) {
      alert('The URL you entered is not valid');
      return;
    }
    try {
      const res = await axios.post('/api/v2/installation-by-craft', {
        craft_id: craftId,
        project: projId,
        drive_folder_id: id,
      });
      const newTask = res.data.task;
      // If we are re-generating a task then replace the old one with the new task
      let tmpTasks = tasks.filter(task => task.tag.title !== newTask.tag.title);
      tmpTasks = [...tmpTasks, newTask];
      setTasks(tmpTasks);
      setHasPendingTasks(true);
      dispatch(setNotification('success', 'Your task is being generated'));
    } catch (error) {
      alert('Something went wrong');
    }
  };

  return (
    <div>
      <Typography variant="h4">Supply Order Reports</Typography>
      <Box style={{ marginTop: 20 }} display="flex" flexDirection="column">
        <div>
          <TextField
            id="outlined-helperText"
            label="Google Drive Folder Id"
            variant="outlined"
            size="small"
            value={driveFolderId}
            fullWidth
            onChange={e => setDriveFolderId(e.target.value)}
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={saveIdForLater}
              onChange={() => setSaveIdForLater(val => !val)}
              name="checkedB"
              color="primary"
            />
          }
          label="Remember Id"
        />
        <Divider component="span" />
      </Box>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <RenderCrafts
          crafts={crafts}
          onGenerate={handleGenerate}
          hasPendingTasks={hasPendingTasks}
        />
      </div>

      <List>
        {tasks !== null && crafts.allIds.length ? (
          crafts.allIds.map((id, idx) => {
            const craft = crafts.byId[id];
            const tag = craft.name;
            const task = tasks.find(task => {
              return task.tag.title == tag;
            });
            return (
              <div key={id}>
                <RenderTask task={task} idx={idx} craft={craft} />
                <Divider component="li" />
              </div>
            );
          })
        ) : (
          <CircularProgress style={{ marginLeft: '50%' }} />
        )}
      </List>
    </div>
  );
};
export default function Reports() {
  return (
    <PageContainer>
      <SupplyOrder />
    </PageContainer>
  );
}
