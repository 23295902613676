import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import MaterialTable, { MTableToolbar } from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import {
  fetchDeposits,
  deleteDeposit,
} from 'state/ducks/residential/saleDeposits';

const DepositsTable = ({ match, history }) => {
  const { projId, saleId } = useParams();
  const dispatch = useDispatch();
  const saleDeposits = useSelector(state => state.rd.saleDeposits);

  useEffect(() => {
    dispatch(fetchDeposits(projId, saleId));
  }, [projId, saleId]);

  const handleDelete = id => {
    if (window.confirm('Are you sure you want to delete the note?')) {
      dispatch(deleteDeposit(id));
    }
  };

  const tableData = saleDeposits.allIds.map(id => {
    const depositData = saleDeposits.byId[id];
    return {
      description: depositData.description,
      amount: depositData.amount,
      id: depositData.id,
      duedate: depositData.duedate,
      recdate: depositData.recdate,
      payment_type: depositData.payment_type,
      sale: depositData.sale,
    };
  });

  return (
    <Box>
      <MaterialTable
        title="Deposits on this contract"
        components={{
          Toolbar: function renderToolbar(props) {
            return (
              <Box display="flex" flexDirection="row">
                <Box width="100%">
                  <MTableToolbar {...props} />
                </Box>
                <Box marginLeft="auto" display="flex" alignItems="center">
                  <Tooltip title="Add">
                    <IconButton
                      aria-label="add"
                      onClick={() => history.push(`${match.url}/add`)}
                    >
                      <AddBox />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            );
          },
        }}
        options={{
          search: false,
          paging: false,
          headerStyle: {
            fontWeight: 600,
          },
        }}
        columns={[
          {
            title: 'Description',
            field: 'description',
          },
          {
            title: 'Amount (CAD)',
            field: 'amount',
          },
          {
            title: 'Due Date',
            field: 'duedate',
          },
          {
            title: 'Recieved',
            field: 'recdate',
            cellStyle: {
              textAlign: 'center',
            },
            headerStyle: {
              textAlign: 'center',
            },
            render: function renderRecieved(rowData) {
              return rowData.recdate !== null ? (
                <CheckCircle color="primary" />
              ) : (
                <Cancel color="error" />
              );
            },
          },
          {
            title: 'Receive Date',
            field: 'recdate',
          },
          {
            title: 'Payment Type',
            field: 'payment_type',
          },
        ]}
        data={tableData}
        actions={[
          {
            icon: Edit,
            tooltip: 'Edit Deposit',
            onClick: (event, rowData) =>
              history.push(`${match.url}/edit/${rowData.id}`),
          },
          {
            icon: DeleteOutline,
            tooltip: 'Delete Deposit',
            onClick: (event, rowData) => handleDelete(rowData),
          },
        ]}
      />
    </Box>
  );
};

DepositsTable.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default DepositsTable;
