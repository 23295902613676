import React, { useState, useEffect } from 'react';
import { editUnit, fetchUnit } from 'state/ducks/residential/units';
import { useDispatch, useSelector } from 'react-redux';
import UnitForm from './UnitForm';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';

const EditUnitForm = () => {
  const dispatch = useDispatch();
  const { projId, unitId } = useParams();
  const unit = useSelector(state => state.rd.units.byId[unitId]);
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    if (unit) {
      setFormValues({
        id: unit.id,
        project: unit.project,
        model: unit.model,
        modelname: unit.model.name,
        area: unit.model.area,
        unitno: unit.unitno,
        baseprice: unit.baseprice,
        unitstatus: unit.unitstatus,
        note: unit.note,
        municipal: unit.municipal,
      });
    } else {
      dispatch(fetchUnit(projId, unitId));
    }
  }, [unit, projId]);

  const handleEdit = values => {
    dispatch(editUnit(values));
  };

  if (!formValues) {
    return null;
  }

  return (
    <Box p={4}>
      <UnitForm
        initialValues={formValues}
        onSubmit={handleEdit}
        title="Edit Unit"
        buttonText="Save"
      />
    </Box>
  );
};

export default EditUnitForm;
