import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import MuiTable from 'components/shared/MuiTable';
import isEmpty from 'lodash/isEmpty';
import { fetchUnits, deleteUnit } from 'state/ducks/residential/units';
import CircularProgress from '@material-ui/core/CircularProgress';

const UnitsTable = () => {
  const { projId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const units = useSelector(state => state.rd.units);

  useEffect(() => {
    dispatch(fetchUnits(projId));
  }, [projId, dispatch]);

  const showEdit = rowData => {
    history.push(`${history.location.pathname}/edit/${rowData.id}`);
  };

  // eslint-disable-next-line
  const showAddform = () => {
    history.push(`${history.location.pathname}/create`);
  };
  // eslint-disable-next-line
  const handleDelete = values => {
    if (window.confirm('Are you sure you want to delete the unit?'))
      dispatch(deleteUnit({ id: values.id }));
  };

  if (isEmpty(units)) {
    return null;
  }

  const tableData = units.allIds.map(id => {
    const unitData = units.byId[id];
    return {
      id: unitData.id,
      project: unitData.project,
      model: unitData.model,
      sale: unitData.sale,
      modelname: unitData.model.name,
      area: unitData.model.area,
      unitno: unitData.unitno,
      baseprice: unitData.baseprice,
      unitstatus: unitData.unitstatus,
      note: unitData.note,
      municipal: unitData.municipal,
    };
  });

  return (
    <Box p={2}>
      {units.isLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!units.isLoading && (
        <MuiTable
          title="Units"
          rowName="Unit"
          columns={[
            {
              title: 'Unit Number',
              field: 'unitno',
            },
            {
              title: 'Model',
              field: 'modelname',
            },
            {
              title: 'Area (Square Feet)',
              field: 'area',
            },
            {
              title: 'Base Price',
              field: 'baseprice',
            },
            {
              title: 'Note',
              field: 'note',
            },
            {
              title: 'Municipal',
              field: 'municipal',
            },
            {
              title: 'Status',
              field: 'unitstatus',
            },
          ]}
          data={tableData}
          onEdit={showEdit}
        />
      )}
    </Box>
  );
};

export default UnitsTable;
