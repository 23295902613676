import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import MuiTable from 'components/shared/MuiTable';
import Alert from '@material-ui/lab/Alert';
import {
  deleteSaleAddressV2,
  fetchSaleAddresses,
} from 'state/ducks/residential/activeSale';
import Chip from '@material-ui/core/Chip';
import { useHistory, useParams } from 'react-router-dom';
import { setNotification } from 'state/ducks/globalNotification';

const AddressTable = ({ match }) => {
  const { projId, saleId } = useParams();
  const saleAddresses = useSelector(state => state.rd.activeSale.saleAddresses);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(fetchSaleAddresses(projId, saleId));
  }, []);

  const handleDelete = async values => {
    if (window.confirm('Are you sure you want to delete the address?')) {
      const res = await dispatch(
        deleteSaleAddressV2(saleId, values.saleAddressId)
      );
      if (res) {
        dispatch(setNotification('success', 'Deleted!'));
      }
    }
  };

  const showAddForm = () => {
    history.push(`${match.url}/lookup`);
  };
  const showEditForm = rowData => {
    history.push(`${match.url}/edit/${rowData.id}`);
  };

  const tableData = saleAddresses.allIds.map(id => {
    const saleAddress = saleAddresses.byId[id];
    const address = saleAddress.address;
    return {
      id: saleAddress.id,
      saleId: saleAddress.sale.id,
      saleAddressId: saleAddress.id,
      addrtype: saleAddress.addrtype,
      delmeth: saleAddress.delmeth,
      primary: saleAddress.primary,
      email: saleAddress.address.email,
      name: address.person
        ? `${address.given} ${address.surname}`
        : address.name,
      homephone: address.homephone,
      city: address.city.id,
    };
  });

  const hasPrimaryPurchaser = saleAddresses.allIds.find(id => {
    return saleAddresses.byId[id].primary === true;
  });

  return (
    <Box>
      {saleAddresses.allIds.length && !hasPrimaryPurchaser ? (
        <div style={{ padding: '10px 24px' }}>
          <Alert severity="warning">
            The contract does not have a primary purchaser
          </Alert>
        </div>
      ) : null}
      <MuiTable
        title="Addresses on this contract"
        rowName="Address"
        options={{
          search: false,
          paging: false,
        }}
        columns={[
          { title: 'Type', field: 'addrtype' },
          { title: 'Name', field: 'name' },
          { title: 'Email', field: 'email' },
          { title: 'Phone', field: 'homephone' },
          { title: 'Delivery', field: 'delmeth' },
          {
            title: '',
            field: 'primary',
            cellStyle: {
              textAlign: 'center',
            },
            render: function renderIsPrimary(rowData) {
              if (rowData.primary === true) {
                return <Chip label="Primary purchaser" color="primary" />;
              }
              return null;
            },
          },
        ]}
        data={tableData}
        onAdd={showAddForm}
        onEdit={showEditForm}
        onDelete={handleDelete}
      />
    </Box>
  );
};

AddressTable.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AddressTable;
