import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
/**
 * @description This component is used to persist form state in session storage for Formik
 * Example:
 *  <Form>
 *    ...
 *    <FormikPersist name="" />
 *  </Form>
 * @param { name: } param0
 */
const FormikPersist = ({ name }) => {
  // Grab values and setValues from context
  const { values, setValues, errors, setErrors } = useFormikContext();

  React.useEffect(() => {
    const cachedVals = sessionStorage.getItem(name);
    if (cachedVals) {
      const vals = JSON.parse(cachedVals);
      setValues(vals);
    }
  }, []);

  React.useEffect(() => {
    sessionStorage.setItem(name, JSON.stringify(values));
  }, [values, setValues]);

  React.useEffect(() => {
    setErrors(errors);
  }, [errors, setErrors]);

  return null;
};

FormikPersist.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikPersist;
