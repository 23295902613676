import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModels } from 'state/ducks/residential/models';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { setNotification } from 'state/ducks/globalNotification';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MprForm from './MprForm';

const MprByModel = ({ product }) => {
  const models = useSelector(state => state.rd.models);
  const dispatch = useDispatch();
  const { projId } = useParams();
  const [prices, setPrices] = useState(null);
  const [mprHeight, setMprHeight] = useState(0);
  const [ref, setRef] = useState();
  const { height } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();

  useEffect(() => {
    if (ref) setMprHeight(height - ref.offsetTop - 50);
  }, [ref, height]);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const res = await axios.get(
          `/api/ModelProdRooms?limit=1000&product_id=${product.id}&room_id=1`
        );

        const temp = res.data.modelprodroom.reduce((result, item) => {
          result[item.model.id] = item;
          return result;
        }, {});

        setPrices(temp);
      } catch (error) {
        dispatch(
          setNotification('error', error.message || 'Something went wrong')
        );
      }
    };

    if (product) fetchPrices();
  }, [product, dispatch]);

  useEffect(() => {
    if (models.allIds.length === 0) {
      dispatch(fetchModels(projId));
    }
  }, [models, dispatch, projId]);

  const handleModelNameClick = newPlacement => event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleAdd = async (model, values) => {
    const payload = {
      product: product.id,
      room: 1,
      model: model.id,
      price: values.price,
    };
    try {
      const res = await axios.post('/api/ModelProdRoom', [
        {
          root: 'modelprodroom',
          modelprodroom: payload,
        },
      ]);

      const mpr = res.data.modelprodroom[0].fields;

      setPrices({
        ...prices,
        [model.id]: mpr,
      });
      dispatch(setNotification('success', 'Added'));
    } catch (error) {
      dispatch(setNotification('error', 'Could not add price to model'));
      return null;
    }
  };

  const handleSave = async (model, values) => {
    const payload = {
      product: product.id,
      room: 1,
      model: model.id,
      price: values.price,
      id: prices[model.id].id,
    };
    try {
      const res = await axios.put('/api/ModelProdRoom', [
        {
          root: 'modelprodroom',
          modelprodroom: payload,
        },
      ]);

      const mpr = res.data.modelprodroom[0].fields;

      setPrices({
        ...prices,
        [model.id]: mpr,
      });
      dispatch(setNotification('success', 'Saved'));
    } catch (error) {
      dispatch(setNotification('error', 'Could not save price'));
      return null;
    }
  };

  const handleDelete = async model => {
    const payload = {
      id: prices[model.id].id,
    };
    try {
      await axios.delete('/api/ModelProdRoom', {
        data: [
          {
            root: 'modelprodroom',
            modelprodroom: payload,
          },
        ],
      });

      const tmp = {
        ...prices,
      };
      delete tmp[model.id];
      setPrices(tmp);
      dispatch(setNotification('success', 'Deleted'));
    } catch (error) {
      dispatch(setNotification('error', 'Could not delete'));
      return null;
    }
  };

  if (!prices) {
    return <CircularProgress style={{ marginTop: 100 }} />;
  }
  const refs = Object.keys(prices).reduce((acc, val) => {
    acc[val] = createRef();
    return acc;
  }, {});

  const handleLinkClick = key => {
    refs[key].scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">By Model</Typography>
        <Divider style={{ marginBottom: 10, marginTop: 10 }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle1">
          {product.name} is in these models:
        </Typography>
        {Object.keys(prices).map(key => (
          <Chip
            style={{ marginRight: 5, marginTop: 5 }}
            color="primary"
            onClick={() => handleLinkClick(key)}
            key={key}
            label={prices[key].model.model}
          />
        ))}
      </Grid>
      <Grid item xs={12} md={8} style={{ overflowY: 'scroll' }}>
        <Alert style={{ maxWidth: 800, marginBottom: 20 }} severity="info">
          To add the product to a model, enter a price and click the
          &apos;Add&apos; button
        </Alert>
        <div ref={newRef => setRef(newRef)}>
          <Box height={mprHeight}>
            <Popper
              disablePortal
              open={open}
              anchorEl={anchorEl}
              placement="right"
              transition
              // display on top of the MprForm
              style={{ zIndex: 10 }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper
                    style={{
                      padding: 10,
                      border: 'solid 2px #448aff',
                      marginLeft: 10,
                    }}
                    elevation={3}
                  >
                    <Typography>Units:</Typography>
                    <Typography variant="subtitle2">
                      {models.byId[placement].units
                        .sort((a, b) => {
                          if (a.unitno > b.unitno) {
                            return 1;
                          }
                          if (a.unitno < b.unitno) {
                            return -1;
                          }
                          return 0;
                        })
                        .map(unit => (
                          <span key={unit.id} style={{ marginRight: 10 }}>
                            {unit.unitno}
                          </span>
                        ))}
                    </Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
            {models.allIds.map(key => {
              const model = models.byId[key];
              const price = prices[key];
              const isBaseProduct = product.prodtype === 'Base';
              return (
                <div
                  key={key}
                  ref={el => {
                    if (price !== undefined) refs[key] = el;
                  }}
                >
                  <MprForm
                    onModelNameClick={handleModelNameClick(model.id)}
                    item={{
                      ...model,
                      itemName: model.model,
                    }}
                    initialValues={{
                      price: isBaseProduct ? 0 : price ? price.price : 0,
                    }}
                    hasPrice={price !== undefined}
                    isBase={isBaseProduct}
                    onAdd={handleAdd}
                    onSave={handleSave}
                    onDelete={handleDelete}
                  />
                </div>
              );
            })}
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

MprByModel.propTypes = {
  product: PropTypes.object,
};

export default MprByModel;
