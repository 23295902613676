import ImageMapper from 'react-image-mapper';
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  tooltip: {
    position: 'absolute',
    color: '#fff',
    padding: '10px',
    background: 'rgba(0,0,0,0.8)',
    transform: 'translate3d(-50%, -50%, 0)',
    borderRadius: '5px',
    pointerEvents: 'none',
    zIndex: 1000,
  },
  rawModelLayout: {
    width: 800,
  },
}));

const ModelMap = ({
  handleClose,
  isOpen,
  handleMapClick,
  modelName,
  rooms,
}) => {
  const { projId } = useParams();
  const [hoveredArea, setHoveredArea] = useState(null);
  const classes = useStyles();
  const [map, setMap] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);

  // fetch the image and the map json for image mapper
  useEffect(() => {
    const fetchMap = async () => {
      try {
        const res = await axios.get(
          `/api/assets/project/${projId}/models/${modelName}.json`
        );

        let areas = [];
        const tempMap = res.data.map;
        if (rooms.length) {
          const availRooms = rooms.reduce((result, item) => {
            result[item.room.short] = item.id;
            return result;
          }, {});

          areas = tempMap.areas.map(area => {
            if (availRooms[area.room_short] === undefined) {
              return {
                ...area,
                roomId: -1,
                preFillColor: '#808080',
              };
            }
            return {
              ...area,
              roomId: availRooms[area.room_short],
            };
          });
        } else {
          areas = tempMap.areas.map(area => {
            return {
              ...area,
              roomId: -1,
              preFillColor: '#808080',
            };
          });
        }
        setMap({
          ...tempMap,
          areas: areas,
        });
        // eslint-disable-next-line
      } catch (error) {}
    };

    if (modelName && rooms) {
      fetchMap(modelName);
    }
  }, [modelName, rooms]);

  // fetch the image and the map json for image mapper
  useEffect(() => {
    const fetchMapImg = async () => {
      try {
        const res = await axios.get(
          `/api/assets/project/${projId}/models/${modelName}.png`
        );
        setImgSrc(res.data.url);
        // eslint-disable-next-line
      } catch (error) {}
    };

    if (modelName && !imgSrc) {
      fetchMapImg(modelName);
    }
  }, [modelName, imgSrc]);

  const enterArea = area => {
    setHoveredArea(area);
  };

  const leaveArea = () => {
    setHoveredArea(null);
  };

  const getTipPosition = area => {
    return {
      top: `${area.center[1]}px`,
      left: `${area.center[0]}px`,
    };
  };

  if (!imgSrc || !map) {
    return null;
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      fullWidth={true}
      maxWidth={'md'}
    >
      <div style={{ position: 'relative' }}>
        <ImageMapper
          src={imgSrc}
          map={map}
          onMouseEnter={area => enterArea(area)}
          onMouseLeave={area => leaveArea(area)}
          onClick={area => handleMapClick(area)}
          width={900}
        />
        {hoveredArea && (
          <span
            className={classes.tooltip}
            style={{ ...getTipPosition(hoveredArea) }}
          >
            {hoveredArea && hoveredArea.name}
          </span>
        )}
      </div>
    </Dialog>
  );
};

ModelMap.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  handleMapClick: PropTypes.func.isRequired,
  modelName: PropTypes.string,
  rooms: PropTypes.array,
};

export default ModelMap;
