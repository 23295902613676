import React, { useEffect, useState } from 'react';
import { fetchProduct } from 'state/ducks/residential/products';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MprByModel from './MprByModel';
import MprByRoom from './MprByRoom';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  formControl: {
    minWidth: 150,
  },
  input: {
    width: 250,
  },
  formSelect: {
    width: '250px',
  },
  radio: {
    width: '100%',
    marginTop: 30,
  },
  fixedModelForm: {
    position: 'fixed',
    top: '64px',
    zIndex: 1099,
    backgroundColor: '#f5f5f5',
  },
});

const MPRFormContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { projId, prodId } = useParams();
  const product = useSelector(state => state.rd.products.byId[prodId]);
  const [modelOrRoom, setModelOrRoom] = useState(null);

  // fetch product
  useEffect(() => {
    if (!product) {
      dispatch(fetchProduct(projId, prodId));
    } else {
      setModelOrRoom(
        product.category === 'Model' || product.category === 'Both'
          ? 'model'
          : 'room'
      );
    }
  }, [product, projId, prodId, dispatch]);

  if (!product) {
    return null;
  }

  return (
    <Box padding={2}>
      <Grid
        container
        direction="row"
        className={classes.fixedModelForm}
        spacing={2}
      >
        <Grid item xs={12} style={{ marginTop: 20, marginBottom: 10 }}>
          <Typography variant="h5">
            Add price to products for model and rooms
          </Typography>
        </Grid>

        <Grid item xs md={3} lg={2}>
          <TextField
            variant="outlined"
            disabled
            size="small"
            label="Product Name"
            defaultValue={product.name}
          />
        </Grid>
        <Grid item xs md={2} lg={2}>
          <TextField
            variant="outlined"
            disabled
            size="small"
            label="Exclusion Group"
            defaultValue={product.exclgrp}
          />
        </Grid>
        <Grid item xs md={2} lg={2}>
          <TextField
            variant="outlined"
            disabled
            size="small"
            label="Product Type"
            defaultValue={product.prodtype}
          />
        </Grid>
        <Grid item xs md={2} lg={2}>
          <TextField
            variant="outlined"
            disabled
            size="small"
            label="Category"
            defaultValue={product.category}
          />
        </Grid>
        <Grid item xs md={3} lg={2}>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={modelOrRoom}
            onChange={() => {
              const val = modelOrRoom === 'model' ? 'room' : 'model';
              setModelOrRoom(val);
            }}
          >
            {(product.category === 'Model' || product.category === 'Both') && (
              <FormControlLabel
                value="model"
                control={<Radio />}
                label="By Model"
              />
            )}
            {(product.category === 'Room' || product.category === 'Both') && (
              <FormControlLabel
                value="room"
                control={<Radio />}
                label="By Room"
              />
            )}
          </RadioGroup>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2} style={{ marginTop: 130 }}>
        <Grid item xs={12}>
          <Box mt={1} mb={3}>
            {modelOrRoom === 'model' && <MprByModel product={product} />}
            {modelOrRoom === 'room' && <MprByRoom product={product} />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MPRFormContainer;
