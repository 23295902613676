import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import {
  lookupAddress,
  clearLookupAddress,
} from 'state/ducks/residential/activeSale';
import { useDispatch, useSelector } from 'react-redux';
import FormikPersist from 'components/shared/FormikPersist';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const lookupSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string().email('Invalid email'),
  postal: Yup.string(),
  homephone: Yup.string().matches(phoneRegExp, 'Home phone is not valid'),
});

const useStyles = makeStyles({
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 300,
    marginTop: 10,
  },
});

const LookupForm = ({ match }) => {
  const dispatch = useDispatch();
  const { projId, saleId } = match.params;
  const classes = useStyles();
  const history = useHistory();
  const addressLookups = useSelector(
    state => state.rd.activeSale.addressLookups
  );
  const [isPerson, setIsPerson] = useState(true);

  useEffect(() => {
    dispatch(clearLookupAddress());
  }, [isPerson, dispatch]);

  const handleAddressLookup = values => {
    const address = {
      ...values,
      person: isPerson,
    };
    dispatch(lookupAddress(address));
  };

  const redirectToForm = (address = null, isPerson = true) => {
    const addressId = address ? `address_id=${address.id}` : '';
    history.push(
      `/rd/${projId}/sales/edit/${saleId}/addresses/add?${addressId}&is_person=${isPerson}`
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      <div>
        <Button
          style={{ marginRight: 20 }}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => redirectToForm(null, true)}
        >
          Create person
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => redirectToForm(null, false)}
        >
          Create organization
        </Button>
      </div>
      <Divider style={{ marginTop: 20, marginBottom: 15 }} />
      <Box display="flex" flexDirection="row" mt={2}>
        <Box>
          <Typography variant="h6">Search for an address</Typography>
          <Box mt={1}>
            <Button
              variant="contained"
              color={isPerson ? 'primary' : 'default'}
              size="small"
              onClick={() => setIsPerson(true)}
              style={{ marginRight: 5 }}
            >
              Person
            </Button>
            <span style={{ marginRight: 10 }}>or</span>
            <Button
              color={!isPerson ? 'primary' : 'default'}
              variant="contained"
              size="small"
              onClick={() => setIsPerson(false)}
            >
              Organization
            </Button>
          </Box>
          <Formik
            initialValues={{
              postal: '',
              homephone: '',
              email: '',
              name: '',
            }}
            validationSchema={lookupSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleAddressLookup(values);
              setSubmitting(false);
            }}
          >
            {({ values, isSubmitting }) => (
              <Form className={classes.form}>
                <FormControl>
                  <Field
                    name="name"
                    placeholder="Name"
                    label="Name"
                    value={values.name}
                    margin="dense"
                    variant="outlined"
                    component={TextField}
                  />
                  <Field
                    name="postal"
                    placeholder="Postal Code"
                    label="Postal Code"
                    value={values.postal}
                    margin="dense"
                    variant="outlined"
                    component={TextField}
                  />
                  <Field
                    name="homephone"
                    placeholder="Homephone"
                    label="Homephone"
                    value={values.homephone}
                    margin="dense"
                    variant="outlined"
                    component={TextField}
                  />
                  <Field
                    name="email"
                    placeholder="Email"
                    label="Email"
                    value={values.email}
                    margin="dense"
                    variant="outlined"
                    component={TextField}
                  />
                </FormControl>
                <Button
                  style={{ marginTop: 10 }}
                  type="submit"
                  color="primary"
                  size="small"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Search
                </Button>
                <Button
                  style={{ marginTop: 10 }}
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={() => history.push('.')}
                >
                  Cancel
                </Button>
                <FormikPersist name="lookup-form" />
              </Form>
            )}
          </Formik>
        </Box>
        <Box width="100%" ml={3}>
          <MaterialTable
            title={isPerson ? 'People' : 'Organizations'}
            columns={[
              {
                title: 'Name',
                field: 'name',
                render: rowData => {
                  if (isPerson) return `${rowData.given} ${rowData.surname}`;
                  return rowData.name;
                },
              },
              { title: 'Email', field: 'email' },
              { title: 'Home phone', field: 'homephone' },
              { title: 'Postal Code', field: 'postal' },
              { title: 'Street', field: 'street' },
            ]}
            data={addressLookups.map(addr => ({ ...addr }))}
            onRowClick={(event, rowData) =>
              redirectToForm(rowData, rowData.person)
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

LookupForm.propTypes = {
  match: PropTypes.object.isRequired,
};

export default LookupForm;
