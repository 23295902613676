import React, { useMemo, useEffect, useState } from 'react';
import AppBar from 'components/shared/AppBar';
import Box from '@material-ui/core/Box';
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import WarrItemList from 'components/tarion/WarrItemList';
import PdiContract from 'components/tarion/PdiContract';
import PrevAndNext from 'components/shared/PrevAndNext';
import axios from 'axios';
import { resetActiveSale } from 'state/ducks/residential/activeSale';
import { useDispatch } from 'react-redux';
import { resetWarrItems } from 'state/ducks/residential/warrantyItems';

const RenderPrevAndNext = () => {
  const [prevAndNext, setPrevAndNext] = useState(null);
  const { projId, saleId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPrevNext = async () => {
      try {
        const res = await axios.get(`/api/v2/sale/${saleId}/prevnext`);
        setPrevAndNext(res.data);
      } catch (error) {
        alert('Something went wrong');
      }
    };

    fetchPrevNext();
  }, [saleId]);

  const goToNextSale = saleId => {
    dispatch(resetActiveSale());
    dispatch(resetWarrItems());
    history.push(`/rd/${projId}/tarion/pdi/${saleId}/`);
  };
  if (prevAndNext === null) return null;

  return (
    <PrevAndNext
      onGoToNext={goToNextSale}
      prevAndNext={{
        next_id: prevAndNext.next_sale_id,
        prev_id: prevAndNext.prev_sale_id,
      }}
    />
  );
};

export default function TarionPdi() {
  const history = useHistory();
  const location = useLocation();
  const match = matchPath(location.pathname, '/rd/:projId/tarion/pdi/:saleId');

  const activeTabIndex = useMemo(() => {
    let activeTabIndex;
    if (location.pathname.includes('/documents')) {
      activeTabIndex = 'documents';
    } else {
      activeTabIndex = 'pdi';
    }
    return activeTabIndex;
  }, [location.pathname]);

  const handleChange = (event, activeTabIndex) => {
    const baseRoute = `${match.url}/${activeTabIndex}`;
    history.push(baseRoute);
  };

  const tabs = [
    {
      label: 'Warranty Items',
      value: 'pdi',
    },
    {
      label: 'Documents',
      value: 'documents',
    },
  ];

  return (
    <Box marginTop={3}>
      <AppBar
        onChange={handleChange}
        tabs={tabs}
        activeTabIndex={activeTabIndex}
      />
      <Switch>
        {/* <Route path={`${match.path}/documents`} component={PdiDocusign} /> */}
        <Route path={`${match.path}/documents`} component={PdiContract} />
        <Route component={WarrItemList} exact />
      </Switch>
      <RenderPrevAndNext />
    </Box>
  );
}
