import axios from 'axios';
import produce from 'immer';
import { getIds, getObject } from 'utils';

export const FETCH_DEPOSITS_REQUEST = 'FETCH_DEPOSITS_REQUEST';
export const FETCH_DEPOSITS_SUCCESS = 'FETCH_DEPOSITS_SUCCESS';
export const FETCH_DEPOSITS_FAILURE = 'FETCH_DEPOSITS_FAILURE';

export const ADD_DEPOSIT_REQUEST = 'ADD_DEPOSIT_REQUEST';
export const ADD_DEPOSIT_SUCCESS = 'ADD_DEPOSIT_SUCCESS';
export const ADD_DEPOSIT_FAILURE = 'ADD_DEPOSIT_FAILURE';

export const EDIT_DEPOSIT_REQUEST = 'EDIT_DEPOSIT_REQUEST';
export const EDIT_DEPOSIT_SUCCESS = 'EDIT_DEPOSIT_SUCCESS';
export const EDIT_DEPOSIT_FAILURE = 'EDIT_DEPOSIT_FAILURE';

export const DELETE_DEPOSIT_REQUEST = 'DELETE_DEPOSIT_REQUEST';
export const DELETE_DEPOSIT_SUCCESS = 'DELETE_DEPOSIT_SUCCESS';
export const DELETE_DEPOSIT_FAILURE = 'DELETE_DEPOSIT_FAILURE';
export const FETCH_DEPOSIT_SUCCESS = 'FETCH_DEPOSIT_SUCCESS';

const initState = {
  allIds: [],
  byId: {},
  isLoading: false,
};

export default function(state = initState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_DEPOSITS_REQUEST:
        draft.isLoading = true;
        break;

      case FETCH_DEPOSIT_SUCCESS: {
        draft.byId[action.deposit.id] = action.deposit;
        const id = draft.allIds.findIndex(id => id === action.deposit.id);
        if (id < 0) draft.allIds.push(action.deposit.id);
        break;
      }

      case FETCH_DEPOSITS_SUCCESS:
        draft.byId = getObject(action.deposits, 'id');
        draft.allIds = getIds(action.deposits, 'id');
        break;

      case FETCH_DEPOSITS_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      case ADD_DEPOSIT_REQUEST:
        draft.isLoading = true;
        break;

      case ADD_DEPOSIT_SUCCESS:
        draft.byId[action.deposit.id] = action.deposit;
        draft.allIds.push(action.deposit.id);
        break;

      case ADD_DEPOSIT_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      case EDIT_DEPOSIT_REQUEST:
        draft.isLoading = true;
        break;

      case EDIT_DEPOSIT_SUCCESS:
        draft.byId[action.deposit.id] = action.deposit;
        break;

      case DELETE_DEPOSIT_SUCCESS:
        delete draft.byId[action.id];
        draft.allIds = draft.allIds.filter(id => id !== action.id);
        break;

      case EDIT_DEPOSIT_FAILURE:
        draft.isLoading = false;
        draft.error = true;
        break;

      default:
        return state;
    }
  });
}

export const fetchDeposit = (saleId, depositId) => async dispatch => {
  try {
    dispatch({ type: FETCH_DEPOSITS_REQUEST });
    const response = await axios.get(
      `/api/SaleDeps?sale_id=${saleId}&id=${depositId}`
    );
    return dispatch({
      type: FETCH_DEPOSIT_SUCCESS,
      deposit: response.data.saledep[0],
    });
  } catch (err) {
    dispatch({
      type: FETCH_DEPOSITS_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Something went wrong',
      },
    });
  }
};

export const fetchDeposits = (projId, saleId) => async dispatch => {
  try {
    dispatch({ type: FETCH_DEPOSITS_REQUEST });
    const response = await axios.get(`/api/SaleDeps?sale_id=${saleId}`);

    return dispatch({
      type: FETCH_DEPOSITS_SUCCESS,
      deposits: response.data.saledep,
    });
  } catch (err) {
    dispatch({
      type: FETCH_DEPOSITS_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot fetch deposits. Please try again',
      },
    });
  }
};

export const addDeposit = deposit => async dispatch => {
  try {
    const res = await axios.post('/api/SaleDep', [
      {
        root: 'saledep',
        saledep: deposit,
      },
    ]);

    return dispatch({
      type: ADD_DEPOSIT_SUCCESS,
      deposit: res.data.saledep[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Added',
      },
    });
  } catch (error) {
    dispatch({
      type: 'ADD_DEPOSIT_FAILURE',
      globalNotification: {
        variant: 'error',
        message: 'Cannot add deposit to sale. Please try again',
      },
    });
  }
};

export const editDeposit = (id, deposit) => async dispatch => {
  try {
    dispatch({ type: EDIT_DEPOSIT_REQUEST });
    const res = await axios.put('/api/SaleDep', [
      {
        root: 'saledep',
        saledep: {
          id,
          ...deposit,
        },
      },
    ]);

    return dispatch({
      type: EDIT_DEPOSIT_SUCCESS,
      deposit: res.data.saledep[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Saved',
      },
    });
  } catch (error) {
    dispatch({
      type: EDIT_DEPOSIT_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot edit deposit. Please try again',
      },
    });
  }
};

export const deleteDeposit = deposit => async dispatch => {
  try {
    dispatch({ type: DELETE_DEPOSIT_REQUEST });
    await axios.delete('/api/SaleDep', {
      data: [
        {
          root: 'saledep',
          saledep: deposit,
        },
      ],
    });

    dispatch({
      type: DELETE_DEPOSIT_SUCCESS,
      id: deposit.id,
      globalNotification: {
        variant: 'success',
        message: 'Deleted',
      },
    });
  } catch (error) {
    dispatch({
      type: DELETE_DEPOSIT_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot delete deposit. Please try again',
      },
    });
  }
};
