import React, { useState, useEffect } from 'react';

import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUnit } from 'state/ducks/residential/units';
import { setNotification } from 'state/ducks/globalNotification';
import { fetchCrafts } from 'state/ducks/residential/crafts';

const useStyles = makeStyles(theme => ({
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    width: 550,
  },
  input: {
    [theme.breakpoints.down('md')]: {
      width: 300,
    },

    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
}));

const AddEditWarrItem = () => {
  const { projId, unitId, itemId } = useParams();
  const isAddMode = !itemId;
  const title = isAddMode ? 'Add Item' : 'Edit Item';

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const unit = useSelector(state => state.rd.units.byId[unitId]);
  const crafts = useSelector(state => state.rd.crafts);
  const [showPrompt, setShowPrompt] = useState(false);
  const [warrItem, setWarrItem] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [initialValues, setInitialValues] = useState({
    resolved_on: '',
    desc: '',
    unit: unitId,
    room: '',
    craft: '',
  });

  // fetch unit if not in redux state
  useEffect(() => {
    if (!unit) {
      dispatch(fetchUnit(projId, unitId));
    }
  }, [projId, unitId]);

  useEffect(() => {
    dispatch(fetchCrafts(projId));
  }, [projId, dispatch]);

  // fetch rooms for the given unit/model
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const limit = 1000;
        const res = await axios.get(
          `/api/ModelRooms?model_id=${unit.model.id}&limit=${limit}`
        );
        const tempRooms = res.data.modelroom.map(modelRoom => {
          return {
            value: modelRoom.room.id,
            label: modelRoom.room.name,
          };
        });

        setRooms(tempRooms);
      } catch (error) {
        dispatch(setNotification('error', 'Something went wrong'));
      }
    };
    if (rooms.length === 0 && unit) {
      fetchRooms();
    }
  }, [rooms, unit]);

  useEffect(() => {
    // fetch warranty items for the given unit
    const fetchWarrItem = async () => {
      try {
        const res = await axios.get(`/api/v2/warritem/${itemId}`);
        setWarrItem(res.data.warr_item);
      } catch (error) {
        dispatch(setNotification('error', 'Something went wrong'));
      }
    };
    if (!isAddMode) {
      fetchWarrItem();
    }
  }, [isAddMode]);

  // initialize form if we are in "edit" mode
  useEffect(() => {
    if (!isAddMode && warrItem) {
      setInitialValues({
        resolved_on: warrItem.resolved_on || '',
        desc: warrItem.desc || '',
        id: warrItem.id,
        room: warrItem.room.id,
        unit: warrItem.unit,
        craft: warrItem.craft ? warrItem.craft.id : '',
      });
    }
  }, [isAddMode, warrItem]);

  const handleSubmit = async values => {
    setShowPrompt(false);

    // Fields with null values need to be initialized to empty strings to prevent "uncontrolled to controlled" browser warning.
    // When submitting we need to convert the fields back to null. This is a known limitation with formik
    const cleanedVals = {
      ...values,
    };
    if (cleanedVals.resolved_on === '') cleanedVals.resolved_on = null;
    if (isAddMode) {
      try {
        await axios.post('/api/v2/warritem', {
          ...cleanedVals,
        });
        history.goBack();
        dispatch(setNotification('success', 'Added!'));
      } catch (error) {
        dispatch(setNotification('error', 'Something went wrong'));
      }
    } else {
      try {
        await axios.put(`/api/v2/warritem/${itemId}`, {
          ...cleanedVals,
        });
        dispatch(setNotification('success', 'Updated!'));
        history.goBack();
      } catch (error) {
        dispatch(setNotification('error', 'Something went wrong'));
      }
    }
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" p={2}>
      <Prompt when={showPrompt} message="Are you sure you want to leave?" />
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <WarrItemForm
        crafts={crafts.allIds.map(id => {
          const craft = crafts.byId[id];
          return {
            value: craft.id,
            label: craft.name,
          };
        })}
        rooms={rooms}
        handleSubmit={handleSubmit}
        classes={classes}
        initialValues={initialValues}
        isAddMode={isAddMode}
        setShowPrompt={setShowPrompt}
      />
    </Box>
  );
};

AddEditWarrItem.propTypes = {};

const sortFormOptions = (a, b) => {
  if (a.label > b.label) {
    return 1;
  }
  if (a.label < b.label) {
    return -1;
  }
  return 0;
};

export const WarrItemForm = ({
  initialValues,
  handleSubmit,
  classes,
  rooms,
  crafts,
  isAddMode,
  setShowPrompt,
}) => {
  const buttonText = isAddMode ? 'Add' : 'Save';
  const history = useHistory();

  // form validation
  const validationSchema = Yup.object().shape({
    resolved_on: Yup.date(),
    desc: Yup.string().required('Description is required'),
    room: Yup.number().required('Room is required'),
    craft: Yup.number().required('Craft is required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => {
        return (
          <Form className={classes.formContainer}>
            <FormControl className={classes.form}>
              <Field
                component={TextField}
                label="Description"
                name="desc"
                multiline
                className={classes.input}
                helperText="The character limit is 75."
                inputProps={{
                  onFocus: () => setShowPrompt(true),
                  maxLength: 75,
                }}
                variant="outlined"
              />

              <Field
                component={TextField}
                type="text"
                select
                label="Room"
                name="room"
                className={classes.input}
                margin="dense"
                variant="outlined"
              >
                {rooms.sort(sortFormOptions).map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
              <Field
                component={TextField}
                type="text"
                select
                label="Craft"
                name="craft"
                className={classes.input}
                margin="dense"
                variant="outlined"
              >
                {crafts.sort(sortFormOptions).map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
              <Field
                component={TextField}
                label="Date resolved"
                name="resolved_on"
                type="date"
                margin="dense"
                className={classes.input}
                inputProps={{
                  onFocus: () => setShowPrompt(true),
                }}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box display="flex" justifyContent="center" mt={3} width="100%">
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: 10 }}
                  className={classes.submitButton}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {buttonText}
                </Button>
                <Button
                  style={{ marginRight: 10 }}
                  variant="contained"
                  color="secondary"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Box>
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};

WarrItemForm.propTypes = {
  rooms: PropTypes.array.isRequired,
  crafts: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setShowPrompt: PropTypes.func.isRequired,
  isAddMode: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default AddEditWarrItem;
