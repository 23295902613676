import React, { Component } from 'react';
import RoomsTable from 'components/setup/rooms/RoomsTable';
import { Switch, Route } from 'react-router-dom';
import AddRoomForm from 'components/setup/rooms/AddRoomForm';
import EditRoomForm from 'components/setup/rooms/EditRoomForm';

class Rooms extends Component {
  render() {
    return (
      <Switch>
        <Route path="/rd/:projId/setup-rooms/create" component={AddRoomForm} />
        <Route
          path="/rd/:projId/setup-rooms/edit/:roomId"
          component={EditRoomForm}
        />
        <Route component={RoomsTable} />
      </Switch>
    );
  }
}

Rooms.propTypes = {};

export default Rooms;
