export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';

export default function errorMessage(state = {}, action) {
  const { type, globalNotification } = action;

  if (type === RESET_ERROR_MESSAGE) {
    return {};
  } else if (globalNotification) {
    return globalNotification;
  }

  return state;
}

export const setNotification = (variant, message) => dispatch => {
  if (!variant || !message) {
    throw Error('Parameters "variant" and "message" are required.');
  }

  dispatch({
    type: '',
    globalNotification: {
      variant,
      message,
    },
  });
};
