import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageContainer from 'views/layouts/PageContainer';
import axios from 'axios';
import useInterval from 'hooks/useInterval';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { setNotification } from 'state/ducks/globalNotification';
import TaskOptions from 'components/shared/tasks/TaskOptions';
import Task from 'components/shared/tasks/Task';
import PropTypes from 'prop-types';

import TaskFolderInput, {
  getIdFromFolderUrl,
} from 'components/shared/tasks/TaskFolderInput';

const RenderTask = ({ floor, idx, floors, tasks }) => {
  const task = tasks.find(task => {
    return task.tag.title == `warranty-item-floor-${floor}`;
  });

  const getSecondaryText = task => {
    if (task && task.started_on) {
      const d = new Date(task.started_on);
      // eslint-disable-next-line
      const date = format(d, "LLL io, uuuu 'at' h:mm aaaa");
      return `Last updated on ${date}`;
    }
    return '';
  };

  return (
    <div>
      <Task
        task={task}
        floor={floor}
        secondaryText={getSecondaryText(task)}
        title={`Floor ${floor}`}
      />
      {idx < floors.length - 1 && <Divider component="li" />}
    </div>
  );
};

RenderTask.propTypes = {
  floor: PropTypes.number.isRequired,
  idx: PropTypes.number.isRequired,
  floors: PropTypes.array.isRequired,
  tasks: PropTypes.array,
};

const SupplyOrder = () => {
  const storage_folder_key = 'warritem_google_drive_folder_id';
  const { projId } = useParams();
  const [tasks, setTasks] = useState(null);
  const [initialTasksCall, setInitialTasksCall] = useState(true);
  const [hasPendingTasks, setHasPendingTasks] = useState(false);

  const dispatch = useDispatch();
  const [floors, setFloors] = useState([]);

  const [driveFolderId, setDriveFolderId] = useState('');

  // see: https://blog.bitsrc.io/polling-in-react-using-the-useinterval-custom-hook-e2bcefda4197?gi=f4e2114d8252
  // poll for pending tasks
  useInterval(async () => {
    const getFloorQuery = floors => {
      let floorQuery = '';
      for (let i = 0; i < floors.length; i++) {
        floorQuery += `warranty-item-floor-${floors[i]},`;
      }
      return floorQuery;
    };

    const checkPendingTasks = _tasks => {
      let pending = false;
      for (let i = 0; i < _tasks.length; i++) {
        if (_tasks[i].status === 'pending') {
          pending = true;
        }
      }
      setHasPendingTasks(pending);
    };

    const fetchTasks = async floors => {
      try {
        if (initialTasksCall) setInitialTasksCall(false);

        // filter the tasks by craft name
        let floorQuery = getFloorQuery(floors);
        const res = await axios.get(
          `/api/v2/warr-item-report?project=${projId}&tags[]=${floorQuery}`
        );

        const _tasks = res.data.tasks;
        setTasks(_tasks);
        checkPendingTasks(_tasks);
      } catch (error) {
        setHasPendingTasks(false);
        setTasks([]);
      }
    };

    if (
      floors.length !== 0 &&
      (hasPendingTasks === true || initialTasksCall === true)
    ) {
      fetchTasks(floors);
    }
  }, 3000);

  useEffect(() => {
    const fetchFloors = async projId => {
      try {
        const res = await axios.get(`/api/v2/floors?project_id=${projId}`);
        setFloors(res.data.floors);
      } catch (error) {
        alert('Something went wrong');
      }
    };

    fetchFloors(projId);
  }, [projId]);

  const handleTaskStart = async ({ value: floor }) => {
    if (!driveFolderId) {
      alert('Please enter a google folder id to generate a report');
      return;
    }

    const id = getIdFromFolderUrl(driveFolderId);
    if (!id) {
      alert('The URL you entered is not valid');
      return;
    }

    try {
      const body = {
        floor: floor,
        project: projId,
        drive_folder_id: id,
      };
      const res = await axios.post('/api/v2/warr-item-report', body);
      const newTask = res.data.task;
      // if old task exists then replace it with new task
      let tmpTasks = tasks.filter(task => task.tag.title !== newTask.tag.title);
      tmpTasks = [...tmpTasks, newTask];

      setTasks(tmpTasks);
      setHasPendingTasks(true);
      dispatch(setNotification('success', 'Your task is being generated'));
    } catch (error) {
      alert('Something went wrong');
    }
  };

  const options = floors.map(id => {
    return {
      label: id,
      value: id,
    };
  });

  return (
    <div>
      <Typography variant="h4">Pre-delivery Inspection Reports</Typography>
      <Box style={{ marginTop: 20 }} display="flex" flexDirection="column">
        <TaskFolderInput
          folderKey={storage_folder_key}
          callback={setDriveFolderId}
        />
        <Divider component="span" />
      </Box>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <TaskOptions
          options={options}
          onStart={handleTaskStart}
          disabled={floors.length === 0 || tasks === null}
        />
      </div>

      <List>
        {tasks !== null && floors.length ? (
          floors.map((floor, idx) => (
            <RenderTask
              key={floor}
              floor={floor}
              idx={idx}
              tasks={tasks}
              floors={floors}
            />
          ))
        ) : (
          <CircularProgress style={{ marginLeft: '50%' }} />
        )}
      </List>
    </div>
  );
};
export default function Reports() {
  return (
    <PageContainer>
      <SupplyOrder />
    </PageContainer>
  );
}
