import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import Menu from 'components/shared/Menu';
import { Switch } from 'react-router-dom';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { Route } from 'react-router-dom';
import ProductStats from 'components/statistics/ProductStats';
import SaleStats from 'components/statistics/SaleStats';

const Statistics = ({ match }) => {
  return (
    <Box p={2}>
      <Menu
        buttonText="Stats Menu"
        menuItems={[
          {
            name: 'Sales',
            path: match.url,
            icon: function renderIcon(props) {
              return <DraftsIcon fontSize="small" {...props} />;
            },
          },
          {
            name: 'Products',
            path: `${match.url}/selections`,
            icon: function renderIcon(props) {
              return <SendIcon fontSize="small" {...props} />;
            },
          },
        ]}
      />
      <Box mt={2}>
        <Switch>
          <Route exact path="/rd/:projId/stats">
            <SaleStats />
          </Route>
          <Route path="/rd/:projId/stats/selections">
            <ProductStats />
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

Statistics.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Statistics;
