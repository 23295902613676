import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fafafa',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const CurrentSelections = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { saleId } = useParams();
  const [selectionsSummary, setSelectionsSummary] = useState([]);
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSelectionsSummary = async () => {
      try {
        const res = await Axios.get(`/api/v2/sale/${saleId}/selectionsSummary`);
        setSelectionsSummary(res.data.summary);
        setOrder(res.data.order);
      } catch (error) {
        alert('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) fetchSelectionsSummary();
  }, [isOpen]);

  if (isLoading) {
    return null;
  }
  return (
    <div>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen && !isLoading}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            {selectionsSummary.length === 0 ? (
              'There are no selections.'
            ) : (
              <>
                <h2 id="transition-modal-title">Selections</h2>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Room</TableCell>
                        <TableCell>Product</TableCell>
                        <TableCell>Style</TableCell>
                        <TableCell align="right">Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectionsSummary.map(
                        ({ selection, product, prodstyle, room }) => (
                          <TableRow key={selection.id}>
                            <TableCell component="th" scope="row">
                              {room.name}
                            </TableCell>

                            <TableCell>{product.name}</TableCell>
                            <TableCell>
                              {prodstyle ? prodstyle.style : ''}
                            </TableCell>
                            <TableCell align="right">
                              {selection.price.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer
                  component={Paper}
                  style={{ width: 400, marginTop: 20, marginLeft: 'auto' }}
                >
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={2}>Subtotal</TableCell>
                        <TableCell align="right">
                          {order.subtotal.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      {order.credits !== 0 && (
                        <>
                          <TableRow>
                            <TableCell colSpan={2}>Less Credits</TableCell>
                            <TableCell align="right">
                              {order.credits.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={2}></TableCell>
                            <TableCell align="right">
                              {(order.subtotal - order.credits).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      <TableRow>
                        <TableCell>Tax ({order.tax_type})</TableCell>
                        <TableCell align="right">{order.tax}%</TableCell>
                        <TableCell colSpan={1} align="right">
                          {order.tax_amount.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <b>Total</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>{order.total.toFixed(2)}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
        </Fade>
      </Dialog>
    </div>
  );
};

CurrentSelections.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CurrentSelections;
