import React from 'react';
import { addProduct } from 'state/ducks/residential/products';
import { useDispatch } from 'react-redux';
import ProductForm from './ProductForm';
import { useParams, useHistory } from 'react-router-dom';

const AddProductForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projId } = useParams();

  const handleAdd = async values => {
    const success = await dispatch(addProduct(values));
    if (success) {
      let path = history.location.pathname.split('/');
      path = path.slice(0, path.length - 1).join('/');
      history.push(path);
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      <ProductForm
        initialValues={{}}
        onSubmit={handleAdd}
        title="Add Product"
        projId={projId}
      />
    </div>
  );
};

export default AddProductForm;
