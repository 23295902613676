import { createSelector } from 'reselect';

export const getActiveProject = createSelector(
  state => state.rd.projects,
  projects => projects.byId[projects.selected]
);

export const getActiveSale = createSelector(
  state => state.rd.activeSale,
  activeSale => activeSale.sale
);
