import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DynamicFeed from '@material-ui/icons/DynamicFeed';
import DeveloperBoard from '@material-ui/icons/DeveloperBoard';
import Policy from '@material-ui/icons/Policy';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Create from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import FileCopy from '@material-ui/icons/FileCopy';
import Home from '@material-ui/icons/Home';
import Settings from '@material-ui/icons/Settings';
import ShowChart from '@material-ui/icons/ShowChart';
import Assessment from '@material-ui/icons/Assessment';
import Description from '@material-ui/icons/Description';

import classnames from 'classnames';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  disabledLink: {
    color: 'grey',
    cursor: 'not-allowed',
  },
  active: {
    color: '#304ffe',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listItemText: {
    paddingLeft: theme.spacing(2),
  },
}));

const ListItemWrapper = ({
  text,
  onClick,
  Icon,
  isDisabled = false,
  isActive = false,
  nested = false,
  children,
}) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      className={classnames(
        isDisabled ? classes.disabledLink : '',
        nested ? classes.nested : ''
      )}
      onClick={isDisabled ? null : onClick}
    >
      {Icon && (
        <ListItemIcon>
          <Icon color={isActive ? 'primary' : 'action'} />
        </ListItemIcon>
      )}

      <ListItemText
        primary={text}
        primaryTypographyProps={{
          className: classnames(
            isActive ? classes.active : 'test-class',
            !Icon ? classes.listItemText : ''
          ),
        }}
      />
      {children}
    </ListItem>
  );
};

ListItemWrapper.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.object,
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
  nested: PropTypes.bool,
  children: PropTypes.object,
};

const DrawerMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const match = matchPath(location.pathname, '/rd/:projId?/:sectionId?');
  const [setupExpanded, setSetupExpanded] = useState(false);
  const projId = match?.params?.projId;
  const sectionId = match?.params?.sectionId;

  // if the user is one of the tabs in set up then expand setup
  useEffect(() => {
    if (sectionId && sectionId.includes('setup')) {
      setSetupExpanded(true);
    }
  }, [sectionId]);

  const onTabClick = link => {
    history.push(link);
  };

  const drawer = (
    <>
      <List component="div">
        <ListItemWrapper
          onClick={() => onTabClick(`/rd/${projId}`)}
          Icon={Home}
          text="Dashboard"
          match={match}
          isActive={sectionId === undefined}
        />
        <ListItemWrapper
          onClick={() => onTabClick(`/rd/${projId}/sales`)}
          Icon={ShowChart}
          match={match}
          isActive={sectionId === 'sales'}
          text="Sales"
        />
        <ListItemWrapper
          onClick={() => onTabClick(`/rd/${projId}/stats`)}
          Icon={Assessment}
          text="Stats"
          match={match}
          isActive={sectionId === 'stats'}
        />
        <ListItemWrapper
          onClick={() => onTabClick(`/rd/${projId}/tarion`)}
          Icon={Policy}
          match={match}
          isActive={sectionId === 'tarion'}
          text="Tarion"
        />
        {/* <ListItemWrapper
          onClick={() => onTabClick(`/rd/${projId}/reports`)}
          Icon={Description}
          match={match}
          isActive={sectionId === 'reports'}
          text="Reports"
        /> */}
        <ListItemWrapper
          onClick={() => onTabClick(`/rd/${projId}/warranty-item-reports`)}
          Icon={Description}
          match={match}
          isActive={sectionId === 'warranty-item-reports'}
          text="Warranty Item Reports"
        />
        <ListItemWrapper
          onClick={() => onTabClick(`/rd/${projId}/reports-by-craft`)}
          Icon={Description}
          match={match}
          isActive={sectionId === 'reports-by-craft'}
          text="Supply Order Reports"
        />

        <ListItemWrapper
          onClick={() => setSetupExpanded(!setupExpanded)}
          Icon={Settings}
          match={match}
          isActive={sectionId === 'setup'}
          text="Setup"
        >
          {setupExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemWrapper>

        <Collapse in={setupExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemWrapper
              onClick={() => onTabClick(`/rd/${projId}/setup-un`)}
              Icon={FileCopy}
              match={match}
              isActive={sectionId === 'setup-un'}
              text="Units"
              nested
            />
            <ListItemWrapper
              onClick={() => onTabClick(`/rd/${projId}/setup-rooms`)}
              Icon={DynamicFeed}
              match={match}
              isActive={sectionId === 'setup-rooms'}
              text="Rooms"
              nested
            />
            <ListItemWrapper
              onClick={() => onTabClick(`/rd/${projId}/setup-md`)}
              Icon={DeveloperBoard}
              match={match}
              isActive={sectionId === 'setup-md'}
              text="Models"
              nested
            />
            <ListItemWrapper
              onClick={() => onTabClick(`/rd/${projId}/setup-crafts`)}
              Icon={Create}
              match={match}
              isActive={sectionId === 'setup-crafts'}
              text="Crafts"
              nested
            />
            <ListItemWrapper
              onClick={() => onTabClick(`/rd/${projId}/setup-products`)}
              Icon={ShoppingCart}
              match={match}
              isActive={sectionId === 'setup-products'}
              text="Products"
              nested
            />
          </List>
        </Collapse>
        <ListItemWrapper
          onClick={() => {}}
          match={match}
          text="Galleries"
          isDisabled={true}
        />
        <ListItemWrapper
          onClick={() => {}}
          match={match}
          text="Documents"
          isDisabled={true}
        />
      </List>
    </>
  );

  return drawer;
};

DrawerMenu.propTypes = {};

export default DrawerMenu;
