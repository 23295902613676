import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import SalesTable from 'components/sales/SalesTable';
import AddSale from 'components/sales/AddSale';
import Sale from 'views/pages/Sale';

class Sales extends Component {
  render() {
    return (
      <Switch>
        <Route path="/rd/:projId/sales/create" component={AddSale} />
        <Route path="/rd/:projId/sales/edit/:saleId" component={Sale} />
        <Route component={SalesTable} />
      </Switch>
    );
  }
}

Sales.propTypes = {};

export default Sales;
