import React from 'react';
import { addRoom } from 'state/ducks/residential/rooms';
import { useDispatch } from 'react-redux';
import RoomForm from './RoomForm';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';

const AddRoomForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAdd = async values => {
    const success = await dispatch(addRoom(values));
    if (success) {
      let path = history.location.pathname.split('/');
      path = path.slice(0, path.length - 1).join('/');
      history.push(path);
    }
  };

  return (
    <Box p={4}>
      <RoomForm onSubmit={handleAdd} title="Add Room" />
    </Box>
  );
};

export default AddRoomForm;
