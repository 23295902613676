import React from 'react';
import { Redirect, Route, useLocation, Switch } from 'react-router-dom';
import TarionTable from 'components/tarion/PdisTable';
import BreadCrumb, { getCrumbsFromRoutes } from 'components/shared/BreadCrumb';
import AddEditWarrItem from 'components/tarion/AddEditPdi';
import { Box } from '@material-ui/core';
import PdiUnit from './PDI';

export const routes = [
  {
    path: '/rd/:projId/tarion/pdi/:saleId/unit/:unitId/edit/:itemId',
    name: 'Edit Issue',
    Component: AddEditWarrItem,
  },
  {
    path: '/rd/:projId/tarion/pdi/:saleId/unit/:unitId/add',
    name: 'Add Issue',
    Component: AddEditWarrItem,
  },
  {
    path: '/rd/:projId/tarion/pdi/:saleId',
    name: 'Issues',
    Component: PdiUnit,
  },
  {
    path: '/rd/:projId/tarion',
    name: 'Pre-Delivery Inspection',
    Component: TarionTable,
    exact: true,
  },
];
const Tarion = () => {
  const location = useLocation();

  // routes need to be reversed in order for bread crumbs to work
  // the first route should be the shallowest and the last should be the deepest
  const tmp = [...routes];
  tmp.reverse();

  return (
    <div>
      <Switch>
        {/* Fixes redirecting backwards bug. Sometimes, when we go back (to the previous url), an extra slash is appended.
                This Redirect fixes that, by removing it
            */}
        <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />
        {routes.map(({ path, Component, exact = false }, key) => (
          <Route
            exact={exact}
            path={path}
            key={key}
            render={props => {
              const crumbs = getCrumbsFromRoutes(props, tmp);
              return (
                <>
                  <Box pl={2} pt={2}>
                    <BreadCrumb crumbs={crumbs} />
                  </Box>

                  <Component {...props} />
                </>
              );
            }}
          />
        ))}
      </Switch>
    </div>
  );
};

Tarion.propTypes = {};

export default Tarion;
