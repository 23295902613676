import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { handleLocalLogin, handleOAuth } from 'state/ducks/session';
import { TextField as TextFieldFmk } from 'formik-material-ui';
import { useQueryParam, StringParam } from 'use-query-params';
import Alert from '@material-ui/lab/Alert';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Copyright &copy; {new Date().getFullYear()}&nbsp; DiscerniCa Systems Corp.{' '}
      <br />
      All Rights Reserved
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  googleBtn: {
    backgroundColor: '#4285F4',
    display: 'inline-flex',
    alignItems: 'center',
    color: 'white',
    boxShadow:
      'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
    padding: '0px',
    borderRadius: 2,
    border: '1px solid transparent',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Roboto, sans-serif',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const authentication = useSelector(state => state.authentication);
  const [unauthorized, setUnauthorized] = useQueryParam(
    'unauthorized',
    StringParam
  );

  const { from } = location.state || { from: { pathname: '/' } };

  // redirect to homepage if user is logged in
  if (authentication.isAuthenticated) {
    return <Redirect to={from} />;
  }
  const validateForm = values => {
    const errors = {};
    if (!values.userId) {
      errors.userId = 'Required';
    }

    if (!values.password) {
      errors.password = 'Required';
    }
    return errors;
  };

  // eslint-disable-next-line
  const handleGoogleLogin = () => dispatch(handleOAuth());
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {unauthorized && <Alert severity="error">Unauthorized Email</Alert>}
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={{
            userId: '',
            password: '',
          }}
          validate={validateForm}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(handleLocalLogin(values.userId, values.password));
            setSubmitting(false);
          }}
        >
          {() => (
            <Form>
              <Field
                component={TextFieldFmk}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Username"
                name="userId"
                autoFocus
              />
              <Field
                component={TextFieldFmk}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid> */}
                {/* <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid> */}
              </Grid>
            </Form>
          )}
        </Formik>
        <Button
          onClick={handleGoogleLogin}
          type="button"
          size="small"
          color="primary"
          variant="contained"
          style={{
            paddingLeft: 1,
            paddingTop: 1,
            paddingBottom: 1,
            marginTop: 60,
          }}
        >
          <div
            style={{
              marginRight: 10,
              background: 'rgb(255, 255, 255)',
              padding: 10,
              borderRadius: 2,
              display: 'flex',
            }}
          >
            <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
              <g fill="#000">
                <path
                  d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                  fill="#EA4335"
                ></path>
                <path
                  d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                  fill="#4285F4"
                ></path>
                <path
                  d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                  fill="#FBBC05"
                ></path>
                <path
                  d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                  fill="#34A853"
                ></path>
                <path fill="none" d="M0 0h18v18H0z"></path>
              </g>
            </svg>
          </div>
          Sign in with Google
        </Button>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
