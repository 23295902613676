import MuiAppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';

export default function AppBar({
  title,
  activeTabIndex,
  onChange,
  tabs,
  ...rest
}) {
  return (
    <div>
      <MuiAppBar
        position="static"
        color="default"
        style={{ zIndex: 'auto' }}
        {...rest}
      >
        {title ? (
          <Typography
            variant="h6"
            noWrap
            style={{
              marginTop: 20,
              marginLeft: 20,
            }}
          >
            {title}
          </Typography>
        ) : null}
        <Tabs
          value={activeTabIndex}
          onChange={onChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          variant="scrollable"
        >
          {tabs.map(tab => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </MuiAppBar>
    </div>
  );
}

AppBar.propTypes = {
  title: PropTypes.string,
  activeTabIndex: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
};
