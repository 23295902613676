import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

export default function withAuthentication(WrappedComponent) {
  const WithAuthentication = ({ isAuthenticated, ...props }) => {
    if (!isAuthenticated) {
      return (
        <Redirect to={{ pathname: '/login', search: props.location.search }} />
      );
    }
    return <WrappedComponent {...props} />;
  };
  WithAuthentication.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
  };

  const mapStateToProps = state => ({
    isAuthenticated: state.authentication.isAuthenticated,
  });

  return connect(mapStateToProps)(WithAuthentication);
}
