import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import isEmpty from 'lodash/isEmpty';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useHistory, Prompt } from 'react-router-dom';

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  form: {
    display: 'flex',
    width: 550,
    alignItems: 'center',
  },
  input: {
    width: 250,
  },
  formSelect: {
    width: '250px',
  },
  radio: {
    width: '100%',
    marginTop: 30,
  },
});

const CraftForm = ({
  initialValues = {},
  onSubmit,
  title,
  buttonText = 'Add',
}) => {
  const classes = useStyles();
  const [formModel, setFormModel] = useState(null);
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const defaultValues = {
      name: '',
      short: '',
    };

    if (initialValues && !isEmpty(initialValues)) {
      setFormModel({
        ...defaultValues,
        ...initialValues,
      });
    } else {
      setFormModel(defaultValues);
    }
  }, [initialValues]);

  const validateForm = values => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.short) {
      errors.short = 'Required';
    }

    return errors;
  };

  if (!formModel) {
    return null;
  }

  return (
    <Box p={4} display="flex" justifyContent="center" flexDirection="column">
      <Prompt when={showPrompt} message="Are you sure you want to leave?" />
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <Formik
        initialValues={{
          ...formModel,
        }}
        validate={validateForm}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setShowPrompt(false);
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({ values, isSubmitting }) => (
          <Form className={classes.formContainer}>
            <FormControl className={classes.form}>
              <Field
                component={TextField}
                label="Name"
                name="name"
                value={values.name}
                margin="dense"
                className={classes.input}
                variant="outlined"
                inputProps={{
                  onFocus: () => setShowPrompt(true),
                }}
              />
              <Field
                component={TextField}
                label="Short"
                name="short"
                inputProps={{
                  maxLength: 4,
                  onFocus: () => setShowPrompt(true),
                }}
                value={values.short}
                margin="dense"
                className={classes.input}
                variant="outlined"
              />

              <Box display="flex" justifyContent="center" mt={3} width="100%">
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: 10 }}
                  className={classes.submitButton}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {buttonText}
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ marginRight: 10 }}
                  className={classes.submitButton}
                  onClick={() => history.goBack()}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Box>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

CraftForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  buttonText: PropTypes.string,
};

CraftForm.defaultProps = {
  initialValues: {},
  title: '',
};

export default CraftForm;
