import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import { Chip } from '@material-ui/core';

const Task = ({ task, title, secondaryText = '' }) => {
  const getTaskStatus = task => {
    if (!task) return <Chip label="Not started" color="default" />;
    else if (task.status === 'completed') return null;
    return (
      <>
        <span style={{ marginRight: 10 }}>Pending</span>
        <CircularProgress
          style={{
            width: 20,
            height: 20,
          }}
        />
      </>
    );
  };

  return (
    <ListItem>
      <ListItemText primary={title} secondary={secondaryText} />
      <ListItemSecondaryAction>
        <Box display="flex">
          {task && task.errors && (
            <Alert severity="error" style={{ marginRight: 10, marginLeft: 5 }}>
              Error! This task did not generate properly.
            </Alert>
          )}
          {getTaskStatus(task)}
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

Task.propTypes = {
  title: PropTypes.string.isRequired,
  task: PropTypes.object,
  hasDate: PropTypes.bool,
  secondaryText: PropTypes.string,
};

export default Task;
