import React, { Component } from 'react';
import ModelsTable from 'components/setup/models/ModelsTable';
import EditModelForm from 'components/setup/models/EditModelForm';
import AddModelForm from 'components/setup/models/AddModelForm';
import { Switch, Route } from 'react-router-dom';

class Models extends Component {
  render() {
    return (
      <Switch>
        <Route path="/rd/:projId/setup-md/create" component={AddModelForm} />
        <Route
          path="/rd/:projId/setup-md/edit/:modelId"
          component={EditModelForm}
        />
        <Route component={ModelsTable} />
      </Switch>
    );
  }
}

Models.propTypes = {};

export default Models;
