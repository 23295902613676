import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import isEmpty from 'lodash/isEmpty';
import { fetchRooms, deleteRoom } from 'state/ducks/residential/rooms';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiTable from 'components/shared/MuiTable';

const RoomsTable = () => {
  const { projId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const rooms = useSelector(state => state.rd.rooms);

  useEffect(() => {
    dispatch(fetchRooms(projId));
  }, [projId, dispatch]);

  const showEditForm = rowData => {
    history.push(`${history.location.pathname}/edit/${rowData.id}`);
  };

  const showAddForm = () => {
    history.push(`${history.location.pathname}/create`);
  };

  const handleDelete = values => {
    if (window.confirm('Are you sure you want to delete the room?'))
      dispatch(deleteRoom({ id: values.id }));
  };

  if (isEmpty(rooms)) {
    return null;
  }

  const tableData = rooms.allIds.map(id => {
    const roomData = rooms.byId[id];
    return {
      id: roomData.id,
      name: roomData.name,
      short: roomData.short,
      category: roomData.category,
      countsas: roomData.countsas,
    };
  });

  return (
    <Box p={2}>
      {rooms.isLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!rooms.isLoading && (
        <MuiTable
          title="Rooms"
          rowName="Room"
          columns={[
            {
              title: 'Name',
              field: 'name',
            },
            {
              title: 'Short',
              field: 'short',
            },
            {
              title: 'Category',
              field: 'category',
            },
            {
              title: 'Counts As',
              field: 'countsas',
            },
          ]}
          data={tableData}
          onEdit={showEditForm}
          onDelete={handleDelete}
          onAdd={showAddForm}
        />
      )}
    </Box>
  );
};

export default RoomsTable;
