import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const getIdFromFolderUrl = folderId => {
  const url = folderId.split(/\/|\?/);
  for (let i = 0; i < url.length; i++) {
    if (url[i] == 'folders') {
      return url[i + 1];
    }
  }
  return undefined;
};

const TaskFolderInput = ({ folderKey, callback }) => {
  const [driveFolderId, setDriveFolderId] = useState('');
  const [saveIdForLater, setSaveIdForLater] = useState(false);
  // handle saving drive folder id in local storage
  // check local storage for drive folder id
  useEffect(() => {
    const item = localStorage.getItem(folderKey);
    if (item) {
      setDriveFolderId(item);
      setSaveIdForLater(true);
    }
  }, []);

  useEffect(() => {
    if (saveIdForLater) {
      localStorage.setItem(folderKey, driveFolderId);
    } else {
      localStorage.removeItem(folderKey, driveFolderId);
    }
  }, [driveFolderId, saveIdForLater]);

  useEffect(() => callback(driveFolderId), [driveFolderId]);

  return (
    <>
      {' '}
      <div>
        <TextField
          id="outlined-helperText"
          label="Google Drive Folder Id"
          variant="outlined"
          size="small"
          value={driveFolderId}
          fullWidth
          onChange={e => setDriveFolderId(e.target.value)}
        />
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={saveIdForLater}
            onChange={() => setSaveIdForLater(val => !val)}
            name="checkedB"
            color="primary"
          />
        }
        label="Remember Id"
      />
    </>
  );
};

TaskFolderInput.propTypes = {
  folderKey: PropTypes.string.isRequired,
  callback: PropTypes.func,
};

export default TaskFolderInput;
