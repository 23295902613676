import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MprForm from './MprForm';
import { CircularProgress } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const ProductPriceByRoomForm = ({
  model,
  onAdd,
  onDelete,
  onSave,
  handleChange,
  expanded,
  roomPrices,
  modelRooms,
  isBase,
}) => {
  const classes = useStyles();
  const onChange = panel => (event, isExpanded) => {
    handleChange(event, panel, isExpanded);
  };

  return (
    <Accordion
      key={model.id}
      expanded={expanded === model.id}
      onChange={onChange(model.id)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>Model {model.model}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {!modelRooms.allIds && <CircularProgress />}
        {modelRooms.allIds &&
          modelRooms.allIds
            .filter(key => {
              const modelRoom = modelRooms.byId[key];
              return modelRoom.room.id !== 1;
            })
            .map(key => {
              const modelRoom = modelRooms.byId[key];
              const price = roomPrices.byId ? roomPrices.byId[key] : undefined;
              return (
                <MprForm
                  key={key}
                  item={{
                    ...modelRoom,
                    itemName: modelRoom.room.name,
                  }}
                  initialValues={{
                    price: isBase ? 0 : price ? price.price : 0,
                  }}
                  hasPrice={price !== undefined}
                  onAdd={onAdd}
                  onSave={onSave}
                  onDelete={onDelete}
                  isBase={isBase}
                />
              );
            })}
      </AccordionDetails>
    </Accordion>
  );
};

ProductPriceByRoomForm.propTypes = {
  model: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  hasPrice: PropTypes.bool,
  modelRooms: PropTypes.object,
  roomPrices: PropTypes.object,
  expanded: PropTypes.number,
  handleChange: PropTypes.func,
  isBase: PropTypes.bool,
};

export default ProductPriceByRoomForm;
