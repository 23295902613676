import React, { useState, useEffect } from 'react';
import { editCraft, fetchCraft } from 'state/ducks/residential/crafts';
import { useDispatch, useSelector } from 'react-redux';
import CraftForm from './CraftForm';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';

const EditCraftForm = () => {
  const dispatch = useDispatch();
  const { projId, craftId } = useParams();
  const craft = useSelector(state => state.rd.crafts.byId[craftId]);

  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    if (craft) {
      setFormValues({
        name: craft.name,
        id: craft.id,
        short: craft.short,
      });
    } else {
      dispatch(fetchCraft(projId, craftId));
    }
  }, [craft, projId, craftId, dispatch]);

  const handleEdit = values => {
    dispatch(editCraft(values));
  };

  if (!formValues) {
    return null;
  }

  return (
    <Box p={4}>
      <CraftForm
        initialValues={formValues}
        onSubmit={handleEdit}
        title="Edit Craft"
        buttonText="Save"
      />
    </Box>
  );
};

export default EditCraftForm;
